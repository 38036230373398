import { useFormField } from "@/components/elements/FormField/FormField";
import { background, blue, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { Box, FormHelperText, TextareaAutosize, TextareaAutosizeProps, css, styled } from "@mui/material";
import { FC, useMemo } from "react";

type TInputDateProps = {
  readonly?: boolean;
  previousValue?: string;
  error?: boolean;
  helperText?: string;
  nonControl?: boolean;
} & Omit<TextareaAutosizeProps, "size">;

export const Textarea: FC<TInputDateProps> = ({
  readonly = false,
  previousValue,
  minRows = 5,
  maxRows = 5,
  error = false,
  placeholder = "入力してください",
  nonControl = false,
  helperText,
  ...rest
}) => {
  const { field, fieldState } = useFormField(nonControl);

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(previousValue) && (field?.value ?? rest.value) == previousValue;
  }, [fieldState?.isDirty, previousValue, field?.value, rest.value]);

  return (
    <TextareaWrap>
      <StyledTextarea
        readonly={readonly}
        previous={isPrevious}
        minRows={minRows}
        placeholder={placeholder}
        maxRows={readonly ? undefined : maxRows}
        error={error || Boolean(fieldState?.error)}
        {...rest}
        {...field}
      />
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </TextareaWrap>
  );
};

const options = { shouldForwardProp: (propName: string) => !["textareaSize", "previous", "readonly", "error"].includes(propName) };

const TextareaWrap = styled(Box)`
  .MuiFormHelperText-root {
    margin-top: -4px;
  }
`;

const StyledTextarea = styled(TextareaAutosize, options)<{ readonly: boolean; previous: boolean; error: boolean }>`
  width: 100%;
  resize: none;
  border: 1px solid ${divider.middle};
  border-radius: ${rounded.xs};
  padding: 8px;
  color: ${text.primary};
  caret-color: ${blue[70]};
  ${css(typography.body14)};
  &::placeholder {
    color: ${text.tertiary};
  }

  &::placeholder {
    color: ${text.tertiary};
  }
  fieldset {
    border-color: ${divider.middle};
    border-radius: ${rounded.xs};
    border-width: 1px !important;
  }
  &:hover,
  &:focus {
    border-color: ${blue[70]};
    outline: none;
  }
  &.Mui-error {
    background: ${background.error};
    border-color: ${state.error_1}!important;
  }
  &:disabled {
    background: ${background.disable};
    border-color: ${divider.middle}!important;
  }

  ${({ previous }) => previous && PreviousTextarea};
  ${({ readonly }) => readonly && readonlyTextarea};
  ${({ error }) => error && ErrorTextarea}
`;

const readonlyTextarea = css`
  background: ${background.disable};
  color: ${text.primary};
  pointer-events: none;
  fieldset {
    border-color: ${divider.middle}!important;
  }
`;

const PreviousTextarea = css`
  color: ${lightBlue[60]};
`;

const ErrorTextarea = css`
  background: ${background.error};
  border-color: ${state.error_1}!important;
`;
