import { deleteCompanyApi, updateCompanyApi, useCompany } from "@/api/services/admin/company";
import { Button } from "@/components/elements/Button";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CompanyPaymentTable } from "@/features/company/components/CompanyPaymentTable";
import { CompanyStatusTag } from "@/features/company/components/CompanyStatusTag";
import { CompanySummary } from "@/features/company/components/CompanySummary";
import { CompanyUserTable } from "@/features/company/components/CompanyUserTable";
import { useDialog } from "@/hooks/useDialog";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showError } from "@/utils/error";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";

export const CompanyDetailPage: FC = () => {
  const confirmDelete = useDialog();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const { data: companyResult, isLoading } = useCompany();
  const companyData = companyResult?.data;

  const handleDelete = async () => {
    try {
      if (!companyResult?.data.id) return;
      const agreeToDelete = await confirmDelete.show();
      if (!agreeToDelete) return;
      const rs = await deleteCompanyApi(String(companyResult.data.id));
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      navigate(AppRoutes.company);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST] });
    } catch (error) {
      showError(error);
    }
  };

  if (isLoading || !companyData) return <LoadingPage />;

  return (
    <Stack gap={3}>
      <TopPage
        title={
          <Typography variant="head32Bold">
            {companyData.companyName}{" "}
            <Typography component="span" variant="sub16Semi">
              ID: {companyData.companyCode ?? "----"}
            </Typography>
          </Typography>
        }
        backUrl={AppRoutes.company}
        rightContent={
          <Stack direction="row" alignItems="center" gap={3}>
            <CompanyStatusTag status={companyData.contractStatus} />
            <Button component={Link} variant="primary" to={getDynamicRoute(AppRoutes.companyEdit, { id: companyData.id })}>
              編集
            </Button>
            <Button variant="outline" onClick={handleDelete}>
              削除
            </Button>
          </Stack>
        }
      />
      <CompanySummary />
      <CompanyUserTable />
      <CompanyPaymentTable />
      <ConfirmDeleteModal
        open={confirmDelete.open}
        onClose={confirmDelete.cancel}
        onOk={confirmDelete.confirm}
        message="企業を削除してもよろしいですか？"
      />
    </Stack>
  );
};
