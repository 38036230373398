import { Button } from "@/components/elements/Button";
import { Modal } from "@/components/elements/Modal";
import { TUseTableProps, useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { makeUuid } from "@/utils/pieces";
import { Typography } from "@mui/material";
import { MRT_RowData } from "material-react-table";
import { useMemo, useState } from "react";
import { ArrayPath, Control, FieldArray, FieldValues, useFieldArray, useFormContext } from "react-hook-form";

type TUseFormTableProps<T extends MRT_RowData, K extends FieldValues> = {
  control: Control<K>;
  name: ArrayPath<K>;
  initFieldData?: FieldArray<K, ArrayPath<K>>;
  primaryKey?: string;
  disableDeleteRow?: (index: number) => boolean;
  readonly?: boolean;
} & Omit<TUseTableProps<T>, "rows">;

export const useFormTable = <T extends MRT_RowData, K extends FieldValues>({
  control,
  name,
  initFieldData,
  columns,
  primaryKey,
  disableDeleteRow,
  readonly,
  ...rest
}: TUseFormTableProps<T, K>) => {
  const deleteRecordFormTableModal = useVisible();
  const [deletedRowIndex, setDeletedRowIndex] = useState<number>();

  const { clearErrors } = useFormContext();
  const { append, remove, fields } = useFieldArray({ control, name });

  const removeRow = () => {
    remove(deletedRowIndex);
    clearErrors(`${name}.${deletedRowIndex}`);
  };

  const tableColumns = useMemo(() => {
    if (readonly) return columns;
    return [
      ...columns,
      {
        header: "削除",
        id: "action",
        Cell: ({ row }) => {
          const isDisabledDeleteRow = disableDeleteRow?.(row.index);
          if (isDisabledDeleteRow) return null;
          return (
            <>
              <Button
                onClick={() => {
                  setDeletedRowIndex(row.index);
                  deleteRecordFormTableModal.open();
                }}
                size="sm"
              >
                削除
              </Button>
              <Modal
                isOpen={row.index === deletedRowIndex && deleteRecordFormTableModal.visible}
                onClose={deleteRecordFormTableModal.close}
                onOk={() => {
                  removeRow();
                  deleteRecordFormTableModal.close();
                }}
                labelClose="編集に戻る"
                labelOk="削除する"
                width={417}
                enableCloseIcon={false}
                title="行を削除しますか？"
              >
                <Typography component="p" sx={{ mt: "8px", mb: "24px" }} variant="cap12">
                  行を削除すると入力中の情報が破棄されます。
                </Typography>
              </Modal>
            </>
          );
        },
        size: 80,
      },
    ];
  }, [columns, readonly, removeRow]);

  const { table } = useTable({
    rows: (fields as T[]) ?? [],
    columns: tableColumns,
    getRowId: (row) => {
      // getRowId by primaryKey of the table
      if (primaryKey && primaryKey in row) return JSON.stringify(row[primaryKey]);
      return makeUuid();
    },
    pinningColumns: ["action"],
    ...rest,
  });

  const addNewRow = () => {
    append(initFieldData ?? ({} as FieldArray<K, ArrayPath<K>>));
  };

  return { table: { ...table, addNewRow: readonly ? undefined : addNewRow } };
};
