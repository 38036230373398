import { useMe } from "@/api/services/admin/auth";
import { IconChevronDown, IconHamburger, IconLogo } from "@/assets/icons";
import { Popover } from "@/components/elements/Popover";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { AppRoutes } from "@/routes/config";
import { black, divider, text } from "@/theme/colors";
import { elevation } from "@/theme/variables";
import { getDynamicRoute } from "@/utils/url";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

type TAppHeaderProps = {
  handleOpenDrawer: () => void;
};

export const AppHeader: FC<TAppHeaderProps> = ({ handleOpenDrawer }) => {
  const { isLaptop } = useMediaQuery();
  const navigate = useNavigate();
  const { data: meResult } = useMe();
  const meData = meResult?.data;

  return (
    <BoxWrapper>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        {isLaptop ? (
          <Button onClick={handleOpenDrawer} size="small">
            <IconHamburger color={text.white} fontSize="20" />
          </Button>
        ) : (
          <StyledLink to={AppRoutes.company} className={isLaptop ? "centered" : ""}>
            <IconLogo />
          </StyledLink>
        )}
        <Stack direction="row" mt={1} justifyContent="center" alignItems="center">
          <Typography variant="body14" mr={1} mb={1} fontWeight={500} color={text.white}>
            {meData?.fullName}
          </Typography>
          <Popover
            sx={{
              marginTop: "8px",
              borderRadius: 0,
              ".MuiPopover-paper": {
                borderRadius: "0 0 8px 8px",
                boxShadow: "0px 4px 4px 0px #00000040",
              },
            }}
            anchor={<IconChevronDown cursor="pointer" fontSize="24" color={text.white} />}
          >
            <Stack>
              <LogoutOption
                onClick={() => {
                  navigate(AppRoutes.logout);
                }}
              >
                ログアウト
              </LogoutOption>
              {meData?.type === "admin_member" && (
                <LogoutOption
                  onClick={() => {
                    navigate(getDynamicRoute(AppRoutes.adminUserEdit, { id: meData?.id }));
                  }}
                >
                  ユーザー設定
                </LogoutOption>
              )}
            </Stack>
          </Popover>
        </Stack>
      </Stack>
    </BoxWrapper>
  );
};

const BoxWrapper = styled(Box)`
  padding: 9px 16px;
  border-bottom: 1px solid #7f7f7f;
  background-color: ${black[30]};
  position: sticky;
  box-shadow: ${elevation.lv1};
  top: 0;
  z-index: ${zIndex.appBar};
`;

const LogoutOption = styled(Box)`
  font-size: 14px;
  padding: 4px 28px;
  cursor: pointer;
  :hover {
    background: ${divider.low};
    color: ${text.primary};
  }
  :focus {
    background: ${divider.middle};
    color: ${text.primary};
  }
`;

const StyledLink = styled(Link)`
  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
