import { fetchSignedUrlApi } from "@/api/services/global/file";
import { API_UNKNOWN_ERROR_MESSAGE } from "@/constants/common";
import { getFileNameFromPath } from "@/utils/file";
import { toast } from "@/utils/toast";
import axios from "axios";

/**
 * Downloads a file from a given URL and saves it with a specified file name.
 * @param fileName - The name to save the file as.
 * @param downloadUrl - The URL from which to fetch the file.
 */
export const downloadFileByUrl = async (fileName: string, downloadUrl: string) => {
  try {
    const res = await axios.get(downloadUrl, { responseType: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(res.data);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    toast.error("Failed to download file.");
  }
};

/**
 * Fetches a signed URL for a file and downloads it.
 * @param filePath - The path to the file for which to fetch the signed URL.
 */
export const downloadFileFromPath = async (filePath: string) => {
  try {
    const rs = await fetchSignedUrlApi(filePath);
    const url = rs.data.signedUrl;

    const fileName = getFileNameFromPath(filePath);
    if (!fileName) {
      toast.error(API_UNKNOWN_ERROR_MESSAGE);
      return;
    }

    await downloadFileByUrl(fileName, url);
  } catch (error) {
    toast.error("Failed to fetch signed URL.");
  }
};
