import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { CompanyUserForm } from "@/features/company/components/CompanyUserForm";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";

type TCompanyUserCreatePageProps = {};

export const CompanyUserCreatePage: FC<TCompanyUserCreatePageProps> = () => {
  const { id } = useParams();

  return (
    <Stack gap={4}>
      <TopPage
        backUrl={getDynamicRoute(AppRoutes.companyDetail, { id })}
        title="企業ユーザーの追加"
        description="企業ユーザーに招待メールを送り、メールのURLから登録してもらう"
      />
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyUserForm />
      </InnerBox>
    </Stack>
  );
};
