import { TGetContactListDto, TGetContactListResDto } from "@/api/services/admin/contact/dtos/get-contact-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { IconChevronRight } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { Linkable } from "@/components/elements/Linkable";
import { StatusTag } from "@/components/elements/StatusTag";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { CONTACT_STATUS, CONTACT_STATUS_LABEL } from "@/constants/enum";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

type ContactTableProps = {
  isResolved: boolean;
  isLoading: boolean;
  data: TGetContactListResDto;
};

export const ContactTable: FC<ContactTableProps> = ({ isResolved, isLoading, data }) => {
  const { data: contactTypeOptions } = useEnum("EContactType");
  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<TGetContactListDto>[]>(
    () => [
      {
        header: "お問い合わせの種類",
        Cell: ({ row }) => {
          return row.original.contactType.name || EMPTY_STR.TEXT;
        },
        size: 120,
      },
      {
        header: "お客様の企業名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.company.companyName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "担当者名",
        size: 120,
        Cell: ({ row }) => {
          return row.original.user.fullName || EMPTY_STR.TEXT;
        },
      },
      {
        header: "メールアドレス",
        size: 120,
        Cell: ({ row }) => {
          return row.original.email || EMPTY_STR.TEXT;
        },
      },
      {
        header: "電話番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.telNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "受信日時",
        Cell: ({ row }) => {
          return dayjs(row.original.createdAt).format(DATE_FORMAT) ?? EMPTY_STR.DATE;
        },
        size: 120,
      },
      {
        header: "ステータス",
        Cell: ({ row }) => {
          return (
            <StatusTag
              title={row.original.isResolved ? CONTACT_STATUS_LABEL[CONTACT_STATUS.RESOLVED] : CONTACT_STATUS_LABEL[CONTACT_STATUS.UNRESOLVED]}
              variant="bold"
              status={row.original.isResolved ? CONTACT_STATUS.RESOLVED : CONTACT_STATUS.UNRESOLVED}
            />
          );
        },

        size: 120,
      },
      {
        header: "詳細",
        id: "action",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable
              href={
                isResolved ? getDynamicRoute(AppRoutes.resolvedContactDetail, { id }) : getDynamicRoute(AppRoutes.unresolvedContactDetail, { id })
              }
            >
              <IconBox>
                <IconChevronRight />
              </IconBox>
            </Linkable>
          );
        },
        size: 20,
      },
    ],
    [contactTypeOptions],
  );

  const { table } = useTable({
    columns,
    rows: data?.data ?? [],
    pinningColumns: ["action"],
    onClickRow: (row) =>
      navigate(
        isResolved
          ? getDynamicRoute(AppRoutes.resolvedContactDetail, { id: row.id })
          : getDynamicRoute(AppRoutes.unresolvedContactDetail, { id: row.id }),
      ),
  });

  return <Table table={table} isLoading={isLoading} />;
};
