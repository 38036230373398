import { TMasterResidencePeriod } from "@/api/entities/master-residence-period.entity";
import { setHiddenResidencePeriodApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenResidencePeriodModalProps = {
  hiddenResidencePeriod?: TMasterResidencePeriod;
  setHiddenResidencePeriod: Dispatch<SetStateAction<TMasterResidencePeriod | undefined>>;
  setSelectedResidencePeriodRows: React.Dispatch<React.SetStateAction<TMasterResidencePeriod[]>>;
};

export const HiddenResidencePeriodModal: FC<THiddenResidencePeriodModalProps> = ({
  hiddenResidencePeriod,
  setHiddenResidencePeriod,
  setSelectedResidencePeriodRows,
}) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenResidencePeriod) return;
    try {
      const rs = await setHiddenResidencePeriodApi(toNumber(hiddenResidencePeriod.residenceStatusId), toNumber(hiddenResidencePeriod.id), {
        isHidden: !hiddenResidencePeriod.isHidden,
      });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenResidencePeriod(undefined);
      setSelectedResidencePeriodRows((prev) => {
        const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
        return arr;
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenResidencePeriod?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenResidencePeriod}
      onClose={() => setHiddenResidencePeriod(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenResidencePeriod?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
