import {
  changeCurrentPaymentStatusApi,
  changeFirstDayPaymentStatusApi,
  useCompanyEmployeeBillingList,
} from "@/api/services/admin/company/company-employee-billings";
import { TGetEmployeeBillingListOutputDto } from "@/api/services/admin/company/company-employee-billings/dtos/get-company-employee-billing-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { useGetResidenceStatusList } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { Switch } from "@/components/elements/Switch";
import { Table, TableSorting } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useDialog } from "@/hooks/useDialog";
import { useTable } from "@/hooks/useTable";
import { useVisible } from "@/hooks/useVisible";
import { RETIREMENT_STATUS } from "@/types/enum";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { findLabelByValue, pick } from "@/utils/object";
import { getFullName, truncateWithEllipsis } from "@/utils/string";
import { Box, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const CompanyPaymentTable = () => {
  const { dataFormatter: data, isLoading } = useCompanyEmployeeBillingList({ fetchAll: true });
  const { residenceStatusOptions } = useGetResidenceStatusList();
  const { data: retirementStatusOptions } = useEnum("ERetirementStatus");
  const { data: employmentStatusOptions } = useEnum("EEmploymentStatus");

  const columns = useMemo<MRT_ColumnDef<TGetEmployeeBillingListOutputDto>[]>(
    () => [
      {
        header: "No.",
        size: 30,
        Cell: ({ row }) => row.index + 1,
      },
      {
        header: "外国人名",
        Cell: ({ row }) =>
          truncateWithEllipsis(getFullName(pick(row.original?.employee, "firstName", "lastName", "middleName")), 25) || EMPTY_STR.TEXT,
      },
      {
        header: "国籍",
        Header: <TableSorting name="nationality.nationalityName">国籍</TableSorting>,
        Cell: ({ row }) => row.original?.employee.nationality?.nationalityName || EMPTY_STR.TEXT,
      },
      {
        header: "入社日",
        size: 120,
        Header: <TableSorting name="employee.hiringDate">入社日</TableSorting>,
        Cell: ({ row }) => (row.original.employee?.hiringDate ? dayjs(row.original.employee?.hiringDate).format(DATE_FORMAT) : EMPTY_STR.DATE),
      },
      {
        header: "課金(1日)",
        size: 100,
        Cell: ({ row }) => {
          const billing = row.original;
          if (!billing) return "なし (対象外資格)";
          return <ToggleEnableBilling isEnabled={billing.isFirstDayPayment} employeeId={row.original?.employee.id} type="firstDay" />;
        },
      },
      {
        header: "課金(現在)",
        size: 120,
        Cell: ({ row }) => {
          if (row.original.employee?.retirementStatus === "retired") return "なし (退職済み)";
          if (!["1", "2", "3"].includes(row.original.employee?.residenceStatusId?.toString() ?? "")) return "なし (対象外資格)";
          const billing = row.original;
          return <ToggleEnableBilling isEnabled={billing.isCurrentPayment} employeeId={row.original?.employee.id} type="current" />;
        },
      },
      {
        header: "ステータス",
        size: 120,
        Header: <TableSorting name="employee.retirementStatus">ステータス</TableSorting>,
        Cell: ({ row }) => {
          if (
            row.original?.employee.retirementStatus &&
            [RETIREMENT_STATUS.PROCESSING, RETIREMENT_STATUS.RETIRED].includes(row.original?.employee.retirementStatus)
          ) {
            return findLabelByValue(retirementStatusOptions, row.original.employee?.retirementStatus) || EMPTY_STR.TEXT;
          } else {
            return findLabelByValue(employmentStatusOptions, row.original.employee?.employmentStatus) || EMPTY_STR.TEXT;
          }
        },
      },
      {
        header: "在留資格",
        size: 120,
        Cell: ({ row }) => {
          return findLabelByValue(residenceStatusOptions, row.original.employee?.residenceStatusId) || EMPTY_STR.TEXT;
        },
      },
    ],
    [residenceStatusOptions, retirementStatusOptions, employmentStatusOptions],
  );

  const { table } = useTable({ columns, rows: data });

  return (
    <Stack gap={1}>
      <Typography variant="sub16Bold">課金状況</Typography>
      <Table table={table} isLoading={isLoading} maxHeight={415} />
    </Stack>
  );
};

type TToggleEnableBillingProps = {
  employeeId: string | number;
  isEnabled: boolean;
  type: "firstDay" | "current";
};

const ToggleEnableBilling: FC<TToggleEnableBillingProps> = ({ employeeId, isEnabled, type }) => {
  const { id } = useParams();
  const { set, visible } = useVisible(isEnabled);
  const queryClient = useQueryClient();
  const confirmChange = useDialog();
  useEffect(() => {
    set(isEnabled);
  }, [isEnabled]);

  const handleToggle = async (_: unknown, checked: boolean) => {
    try {
      if (!(!visible && type === "current")) {
        const agree = await confirmChange.show();
        if (!agree) return;
      }
      set(checked);
      if (type === "firstDay") {
        await changeFirstDayPaymentStatusApi(id as string, employeeId, { isEnabled: checked });
      }
      if (type === "current") {
        await changeCurrentPaymentStatusApi(id as string, employeeId, { isEnabled: checked });
      }
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL, id] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_BILLING_LIST, id] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST] });
    } catch (error) {
      showError(error);
      set(!checked);
    }
  };

  return (
    <Box px={1}>
      <Switch checked={visible} onChange={handleToggle} />
      <Modal isOpen={confirmChange.open} onClose={confirmChange.cancel} onOk={confirmChange.confirm} labelClose="いいえ" labelOk="はい">
        <Stack mb={2}>
          {!visible && type === "firstDay" && <Typography>本当に今月の課金対象に切り替えてもよろしいですか？</Typography>}
          {visible && type === "firstDay" && <Typography>本当に今月の課金対象外に切り替えてもよろしいですか？</Typography>}
          {visible && type === "current" && <Typography>本当に課金対象外に切り替えてもよろしいですか？</Typography>}
        </Stack>
      </Modal>
    </Box>
  );
};
