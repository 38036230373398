import { useGetProvincesAndMunicipalities } from "@/api/services/global/constant";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingArea } from "@/components/elements/Loading/Loading";
import { EMPTY_STR } from "@/constants/string";
import { useChat } from "@/features/chat/providers/ChatProvider";
import { primary } from "@/theme/colors";
import { findLabelByValue } from "@/utils/object";
import { Stack, styled, Typography } from "@mui/material";

export const RoomHead = () => {
  const { currentRoom } = useChat();
  const { provinceOptions, getMunicipalityOptionsByProvince, isLoading } = useGetProvincesAndMunicipalities();
  const province = currentRoom.roomData?.company?.province;
  const address = currentRoom.roomData?.company?.address;
  const municipality = currentRoom.roomData?.company?.municipality;
  const provinceLabel = findLabelByValue(provinceOptions, province) || "";
  const municipalityLabel = findLabelByValue(getMunicipalityOptionsByProvince(province ?? ""), municipality) || "";

  if (!currentRoom.roomData || isLoading)
    return (
      <InnerBox>
        <LoadingArea />
      </InnerBox>
    );
  return (
    <StyledRoomHead>
      <Typography sx={{ marginBottom: "4px" }} variant="sub16Bold">
        {currentRoom.roomData.creator.fullName}
      </Typography>
      <Typography variant="body14">
        {currentRoom.roomData.company?.companyName || EMPTY_STR.TEXT}・ID: {currentRoom.roomData.company?.companyCode}
      </Typography>
      <Typography variant="body14">{currentRoom.roomData.company?.specificIndustry.companySpecificIndustry || EMPTY_STR.TEXT}</Typography>
      <Typography variant="body14">{`${provinceLabel}${municipalityLabel}${address ?? ""}` || EMPTY_STR.TEXT}</Typography>
    </StyledRoomHead>
  );
};

const StyledRoomHead = styled(Stack)`
  padding: 16px;
  border-radius: 8px;
  background-color: ${primary.main};
  color: white;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
