import { TLoginRequest } from "@/features/auth/types/loginRequest";
import { TLoginResponse } from "@/features/auth/types/loginResponse";
import { clientApi, logout } from "@/utils/api";
import { setToken } from "@/utils/token";
import { TApiRes } from "@/types/api";

import Cookies from "js-cookie";

export const loginApi = async (payload: TLoginRequest): Promise<TLoginResponse> => {
  try {
    const {
      data: { data },
    } = await clientApi.post<TApiRes<TLoginResponse>>("/login", payload);

    logout();
    setToken(data.accessToken, data.refreshToken);
    Cookies.set("admin-email", data.email);
    Cookies.set("admin-user-id", data.email);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
