import { useContactTypeList } from "@/api/services/admin/contact-type";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ContactTypeListTop } from "@/features/contact-type/components/ContactTypeListTop";
import { ContactTypeTable } from "@/features/contact-type/components/ContactTypeTable";

import { Stack } from "@mui/material";
import { FC } from "react";

type TContactTypePageProps = {};

export const ContactTypePage: FC<TContactTypePageProps> = () => {
  const { data: contactTypeListResult, isLoading } = useContactTypeList();

  if (isLoading) return <LoadingPage />;
  return (
    <Stack gap={3}>
      <ContactTypeListTop totalPage={contactTypeListResult?.pagination.totalPage} />
      {contactTypeListResult && <ContactTypeTable isLoading={isLoading} data={contactTypeListResult ?? []} />}
    </Stack>
  );
};
