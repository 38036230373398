import { Box, BoxProps, Typography, css, styled } from "@mui/material";
import { FC } from "react";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { background, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { elevation } from "@/theme/variables";

type TCopyableProps = BoxProps & {
  text: string;
};

export const Copyable: FC<TCopyableProps> = ({ children, text, ...props }) => {
  const [isCopied, copy] = useCopyToClipboard();

  return (
    <CopyArea {...props} onClick={() => copy(text)}>
      <CopiedMessage className={isCopied ? "show" : "hide"}>コピーしました</CopiedMessage>
      {children}
    </CopyArea>
  );
};

const CopyArea = styled(Box)`
  position: relative;
  width: fit-content;
  cursor: pointer;
`;

const CopiedMessage = styled(Typography)`
  position: absolute;
  left: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${text.white};
  pointer-events: none;
  visibility: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: ${background.dark};
  opacity: 0;
  transition: all 0.1s ease-out;
  transform: translateX(-50%);
  top: -48px;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  box-shadow: ${elevation.lv1};
  ${css(typography.cap12)}
  &::after {
    content: "";
    bottom: -8px;
    width: 8px;
    border: calc(8px / 2) solid transparent;
    border-top: 8px solid ${background.dark};
    border-bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &.show {
    opacity: 1;
    top: -44px;
  }
`;
