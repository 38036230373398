import { TMasterResidencePeriod } from "@/api/entities/master-residence-period.entity";
import { updateResidenceStatusApi } from "@/api/services/global/master";
import { TGetResidenceStatusListOutputDto } from "@/api/services/global/master/dtos/residencePeriod/get-master-residence-status-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { UpdateResidenceStatusFormTable } from "@/features/master-data/components/ResidencePeriod/UpdateResidenceStatusFormTable";
import { masterDataUpdateResidenceStatusSchema } from "@/features/master-data/schema/masterDataResidencePeriodSchema";
import { TMasterResidenceStatusUpdateForm } from "@/features/master-data/types/masterDataResidencePeriodForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

type TUpdateResidenceStatusFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedResidenceStatus?: TGetResidenceStatusListOutputDto;
};

export const UpdateResidenceStatusFormModal: FC<TUpdateResidenceStatusFormModalProps> = ({ onClose, isOpen, selectedResidenceStatus }) => {
  const queryClient = useQueryClient();

  const [selectedResidencePeriodRows, setSelectedResidencePeriodRows] = useState<TMasterResidencePeriod[]>(
    selectedResidenceStatus?.residencePeriods ?? [],
  );

  const methods = useForm<TMasterResidenceStatusUpdateForm>({
    resolver: yupResolver(masterDataUpdateResidenceStatusSchema),
    mode: "onBlur",
    defaultValues: {
      residenceStatus: selectedResidenceStatus?.residenceStatus,
      residenceStatusRenewVisaCsvCode: selectedResidenceStatus?.residenceStatusRenewVisaCsvCode,
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterResidenceStatusUpdateForm) => {
    if (!selectedResidenceStatus) return;
    try {
      const rs = await updateResidenceStatusApi({
        residenceStatuses: [
          {
            id: toNumber(selectedResidenceStatus?.id),
            residenceStatus: data.residenceStatus,
            residenceStatusRenewVisaCsvCode: data.residenceStatusRenewVisaCsvCode,
            residencePeriods: selectedResidencePeriodRows.map((item) => ({
              id: toNumber(item.id),
              residencePeriod: item.residencePeriod,
              residencePeriodRenewVisaCsvCode: item.residencePeriodRenewVisaCsvCode,
              desiredResidencePeriod: item.desiredResidencePeriod,
              desiredResidencePeriodRenewVisaCsvCode: item.desiredResidencePeriodRenewVisaCsvCode,
            })),
          },
        ],
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataUpdateResidenceStatusSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatus">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatusRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">在留期間</Typography>
              <UpdateResidenceStatusFormTable
                selectedResidencePeriodRows={selectedResidencePeriodRows}
                setSelectedResidencePeriodRows={setSelectedResidencePeriodRows}
                existingResidenceStatusId={toNumber(selectedResidenceStatus?.id)}
                existingResidenceStatus={selectedResidenceStatus?.residenceStatus ?? EMPTY_STR.TEXT}
                existingResidenceStatusRenewVisaCsvCode={selectedResidenceStatus?.residenceStatusRenewVisaCsvCode ?? EMPTY_STR.TEXT}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
