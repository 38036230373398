import { valueOf } from "@/types/common";

export const INFORMATION_TYPE = {
  IMPORTANT: "important",
  SYSTEM: "system",
  TENANT: "tenant",
};

export const CONVERT_STATUS = {
  COMPLETE: "complete",
  IN_PROGRESS: "in_progress",
  ERROR: "error",
  CANCEL: "cancel",
};

export const DOCUMENT_KEYS = {
  ACCEPTANCE_ACTIVITY: "acceptance_activity",
  LIFE_SUPPORT: "life_support",
  ANY_TIME: "any_time",
  RETIREMENT: "retirement",
};

export const DOCUMENT_STEP_KEYS = {
  ACCEPTANCE_ACTIVITY_CREATE_DOCUMENT: "acceptance_activity_create_document",
  ACCEPTANCE_ACTIVITY_UPLOAD_DOCUMENT: "acceptance_activity_upload_document",
  ACCEPTANCE_ACTIVITY_SUBMIT: "acceptance_activity_submit",
  LIFE_SUPPORT_CREATE_DOCUMENT: "life_support_create_document",
  LIFE_SUPPORT_CONFIRM_DISCUSSION_RECORD: "life_support_confirm_discussion_record",
  LIFE_SUPPORT_SUBMIT: "life_support_activity_submit",
};

export const CONTACT_STATUS = {
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved",
};

export type TContactStatus = valueOf<typeof CONTACT_STATUS>;

export const CONTACT_STATUS_LABEL = {
  [CONTACT_STATUS.RESOLVED]: "返信済み",
  [CONTACT_STATUS.UNRESOLVED]: "未返信",
};
