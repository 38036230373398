import { useGetProvincesAndMunicipalities } from "@/api/services/global/constant";
import { useEnum } from "@/api/services/global/enum";
import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TAnytimeSupportPlanData } from "@/features/convert/types/documents/anytime/supportPlan";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertAnytimeSupportPlanInfoProps = {
  data: TAnytimeSupportPlanData;
};

export const ConvertAnytimeSupportPlanInfo = ({ data }: TConvertAnytimeSupportPlanInfoProps) => {
  const { companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();
  const { provinceOptions } = useGetProvincesAndMunicipalities();
  const { data: supportRegisteredOptions } = useEnum("ESupportRegistered");
  const { data: supportRecipientOptions } = useEnum("ESupportRecipient");
  const { data: supportSpecifiedSkillOptions } = useEnum("ESupportSpecifiedSkill");

  const { documentInfo } = data;
  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">支援計画変更に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 契約の変更 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約の変更</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">変更発生日</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">{dayjs(documentInfo.modifyContractDate).format(COMPACT_DATE_FORMAT)}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {/* 変更後の内容 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">変更後の内容</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">--</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">{documentInfo.contractTermOther}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {/* 契約事項 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約事項</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">支援対象者</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14" whiteSpace="pre-line">
                    {findLabelByValue(supportRecipientOptions, documentInfo.contractTermTarget) || EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約事項</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14" whiteSpace="pre-line">
                    {findLabelByValue(supportSpecifiedSkillOptions, documentInfo.contractSpecifiedSkill) || EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約事項</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">その他の内容</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14" whiteSpace="pre-line">
                    {documentInfo.contractTermOtherContent}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約事項</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">登録支援機関</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14" whiteSpace="pre-line">
                    {findLabelByValue(supportRegisteredOptions, documentInfo.contractTermRegisteredSupportOrganization) || EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約事項</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">その他</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14" whiteSpace="pre-line">
                    {documentInfo.contentAfterChange}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">氏名又は名称</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">フリガナ</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyFuriganaName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">法人番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyIdentificationNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定産業分野</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {findLabelByValue(companySpecificIndustryOptions, documentInfo.companySpecificIndustryId) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">郵便番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {documentInfo.companyZipCode ? convertNumberToZipCode(documentInfo.companyZipCode) : EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">都道府県</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  <Typography variant="body14">{documentInfo.companyProvince || EMPTY_STR.TEXT}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">住所</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyAddress || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">電話番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyTelNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </InnerBox>
  );
};
