import { createCompanyApi, updateCompanyApi, useCheckToUpdateYearAndQuarter } from "@/api/services/admin/company";
import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Select } from "@/components/elements/Select";
import { Textarea } from "@/components/elements/Textarea";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { ConfirmResetData } from "@/features/company/components/ConfirmRestData";
import { companySchema } from "@/features/company/schema/companySchema";
import { TCompanyForm } from "@/features/company/types/companyForm";
import { convertCompanyFormToCreateReq, convertCompanyFormToUpdateReq } from "@/features/company/utils/converts";
import { useDialog } from "@/hooks/useDialog";
import { useToast } from "@/hooks/useToast";
import { useTriggerValidate } from "@/hooks/useTriggerValidate";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { setErrorForm, showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const initCreateForm = {
  companyDescription: "",
  companyName: "",
  contractStatus: "",
  note: "",
  specificIndustry: "",
  contractStartDate: dayjs(),
  pricePerEmployee: undefined,
};

type TCompanyFormProps = {
  previousData?: Partial<TCompanyForm>;
};

export const CompanyForm: FC<TCompanyFormProps> = ({ previousData }) => {
  const { id } = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirmReset = useDialog();
  const methods = useForm<TCompanyForm>({
    defaultValues: previousData ?? initCreateForm,
    resolver: yupResolver(companySchema),
    mode: "onBlur",
  });
  const { control } = methods;
  useTriggerValidate({ methods, name: "contractStatus", triggerFields: ["contractStartDate"] });
  useTriggerValidate({ methods, name: "contractStartDate", triggerFields: ["contractEndDate"] });

  const { data: checkResult } = useCheckToUpdateYearAndQuarter();

  const onSubmit = async (data: TCompanyForm) => {
    try {
      if (id) {
        if (
          data.startReportYear.get("year") !== previousData?.startReportYear?.get("year") ||
          data.startReportQuarter !== previousData.startReportQuarter
        ) {
          const result = await confirmReset.show();
          if (!result) return;
        }
        const payload = convertCompanyFormToUpdateReq(data);
        const rs = await updateCompanyApi(id, payload);
        navigate(getDynamicRoute(AppRoutes.companyDetail, { id }));
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL, id] });
      } else {
        const payload = convertCompanyFormToCreateReq(data);
        const rs = await createCompanyApi(payload);
        navigate(AppRoutes.company);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const { isLoading: isLoadingCompanySpecificIndustry, companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={companySchema}>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={6}>
          <FormField control={control} name="companyName">
            <Input />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="specificIndustryId">
            <Select
              loading={isLoadingCompanySpecificIndustry}
              disabled={previousData?.specificBusinessClassificationIds && previousData.specificBusinessClassificationIds.length > 0}
              options={companySpecificIndustryOptions}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="contractStatus">
            <Select enumName="ECompanyContractStatus" />
          </FormField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormField control={control} name="contractStartDate">
            <InputDatePicker />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="contractEndDate">
            <InputDatePicker />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="startReportYear">
            <InputDatePicker format="YYYY" views={["year"]} readonly={checkResult?.data ? false : !!previousData} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="startReportQuarter">
            <Select enumName="EQuarter" readonly={checkResult?.data ? false : !!previousData} />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="pricePerEmployee">
            <Input endAdornment="円/人" />
          </FormField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormField control={control} name="companyDescription">
            <Textarea />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="note">
            <Textarea />
          </FormField>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={5}>
        <Button type="submit">{id ? "保存" : "追加"}</Button>
      </Box>
      <ConfirmResetData open={confirmReset.open} onClose={confirmReset.cancel} onOk={confirmReset.confirm} />
    </Form>
  );
};
