import { TMasterMainIndustry } from "@/api/entities/master-main-industry.entity";
import { setHiddenMainIndustryApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenMainIndustryModalProps = {
  hiddenMainIndustry?: TMasterMainIndustry;
  setHiddenMainIndustry: Dispatch<SetStateAction<TMasterMainIndustry | undefined>>;
};

export const HiddenMainIndustryModal: FC<THiddenMainIndustryModalProps> = ({ hiddenMainIndustry, setHiddenMainIndustry }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenMainIndustry) return;
    try {
      await setHiddenMainIndustryApi(toNumber(hiddenMainIndustry.id), { isHidden: !hiddenMainIndustry.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenMainIndustry(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_INDUSTRY] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenMainIndustry?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenMainIndustry}
      onClose={() => setHiddenMainIndustry(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenMainIndustry?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
