import { Button } from "@/components/elements/Button";
import { AppRoutes } from "@/routes/config";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledContainer = styled(Stack)({
  textAlign: "center",
  padding: "32px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  alignItems: "center",
  gap: 8,
});

const ServerErrorPage: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: "#f2f2f2", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <StyledContainer>
        <Typography variant="head32Bold">500</Typography>
        <Typography variant="head20Bold">サーバーエラーが発生しました。</Typography>
        <Typography variant="body14">サーバーに問題が発生しています。しばらくしてから再度お試しください。</Typography>
        <Button href={AppRoutes.company}>ホームページに戻る</Button>
      </StyledContainer>
    </Box>
  );
};

export default ServerErrorPage;
