import { background, divider, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, CircularProgress, Stack, StackProps, Typography, styled } from "@mui/material";
import { ReactNode, useMemo } from "react";

export type TInnerBoxProps = {
  title?: ReactNode;
  bordered?: boolean;
  required?: boolean;
  loading?: boolean;
  heightContent?: number;
} & Omit<StackProps, "title">;

export const InnerBox: FCC<TInnerBoxProps> = ({
  title,
  children,
  bordered = true,
  padding = "16px",
  required = false,
  loading,
  heightContent,
  ...rest
}) => {
  const renderTitle = useMemo(() => {
    if (!title) return null;
    if (typeof title === "string") {
      return (
        <Typography variant="head20Bold" color={text.primary}>
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  const hasTitle = useMemo(() => renderTitle || required, []);

  return (
    <InnerBoxWrap border={bordered ? "1px solid" : "none"} {...rest} maxHeight={heightContent}>
      {hasTitle && (
        <Header p={padding} pb={0}>
          {renderTitle}
          {required && <RequiredTag variant="cap12">必須</RequiredTag>}
        </Header>
      )}
      <Box position="relative" p={padding} pt={hasTitle ? 0 : padding}>
        {loading ? (
          <CenterBox>
            <CircularProgress sx={{ color: text.tertiary }} size="24px" />
          </CenterBox>
        ) : (
          children
        )}
      </Box>
    </InnerBoxWrap>
  );
};

const InnerBoxWrap = styled(Stack)`
  border-color: ${divider.middle};
  border-radius: ${rounded.sm};
  gap: ${spacing.sm};
  background: ${background.white};
  overflow-y: auto;
  position: relative;
`;

const RequiredTag = styled(Typography)`
  height: 20px;
  width: 40px;
  text-align: center;
  border: 1px solid ${text.error};
  color: ${text.error};
  border-radius: ${rounded.xs};
`;

const CenterBox = styled(Box)`
  background-color: ${background.white};
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${background.white};
`;
