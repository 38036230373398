import { useContactList } from "@/api/services/admin/contact";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ContactListTop } from "@/features/contact/components/ContactListTop";
import { ContactTable } from "@/features/contact/components/ContactTable";

import { Stack } from "@mui/material";
import { FC } from "react";

type TUnresolvedContactListPageProps = {};

export const UnresolvedContactListPage: FC<TUnresolvedContactListPageProps> = () => {
  const { data: contactListResult, isLoading } = useContactList([false]);

  if (isLoading) return <LoadingPage />;
  return (
    <Stack gap={3}>
      <ContactListTop totalPage={contactListResult?.pagination.totalPage} />
      {contactListResult && <ContactTable isResolved={false} isLoading={isLoading} data={contactListResult ?? []} />}
    </Stack>
  );
};
