import { TMasterLanguageTest } from "@/api/entities/master-language-test.entity";
import { setHiddenLanguageTestApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenLanguageTestModalProps = {
  hiddenLanguageTest?: TMasterLanguageTest;
  setHiddenLanguageTest: Dispatch<SetStateAction<TMasterLanguageTest | undefined>>;
};

export const HiddenLanguageTestModal: FC<THiddenLanguageTestModalProps> = ({ hiddenLanguageTest, setHiddenLanguageTest }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenLanguageTest) return;
    try {
      await setHiddenLanguageTestApi(toNumber(hiddenLanguageTest.id), { isHidden: !hiddenLanguageTest.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenLanguageTest(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LANGUAGE_TEST] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenLanguageTest?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenLanguageTest}
      onClose={() => setHiddenLanguageTest(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenLanguageTest?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
