/**
 * Adds zero padding to a number or string to achieve a desired length.
 *
 * @param {number | string} num - The number or string to be padded. For example, 5 or "5".
 * @param {number} length - The desired length of the output string. For example, 3.
 * @returns {string} - The zero-padded number as a string. For example, "005".
 *
 * @example
 * zeroPad(5, 3); // Returns "005"
 * zeroPad("5", 3); // Returns "005"
 */
export const zeroPad = (num: number | string, length: number) => {
  const numStr = num.toString(); // Convert the number or string to a string
  const zerosNeeded = length - numStr.length; // Calculate how many zeros are needed
  const zeros = "0".repeat(zerosNeeded); // Create a string of the necessary number of zeros

  return zeros + numStr; // Prepend zeros to the original number string
};

/**
 * Removes leading zeros from a string.
 *
 * @param {string} value - The string from which leading zeros should be removed.
 * @returns {string} - The string with leading zeros removed.
 *
 * @example
 * removeLeadingZero("000123"); // Returns "123"
 */
export const removeLeadingZero = (value: string) => {
  return value.replace(/^0+/, ""); // Use regex to replace leading zeros with an empty string
};

/**
 * Checks if a value is a number.
 *
 * @param {unknown} value - The value to be checked.
 * @returns {boolean} - True if the value is a number, otherwise false.
 *
 * @example
 * isNumber(123); // Returns true
 * isNumber("123"); // Returns false
 */
export const isNumber = (value: unknown): value is number => {
  const valueNumber = Number(value); // Convert the value to a number
  return !isNaN(valueNumber); // Check if the result is not NaN
};

/**
 * Converts a value to a number.
 *
 * @param {unknown} value - The value to be converted.
 * @returns {number} - The converted number, or 0 if the value is not a number.
 *
 * @example
 * toNumber(123); // Returns 123
 * toNumber("123"); // Returns 123
 * toNumber("abc"); // Returns 0
 */
export const toNumber = (value: unknown) => {
  if (isNumber(value)) return Number(value); // Return the value if it's a number
  return 0; // Return 0 if the value is not a number
};

export const formatNumber = (value: number | string): string => {
  try {
    if (typeof value === "string") {
      const sValue = Number(value);
      return `${sValue.toLocaleString("en-GB")}`;
    }
    return `${value.toLocaleString("en-GB")}`;
  } catch {
    return value.toString();
  }
};
