import { TMasterMainOccupation } from "@/api/entities/master-main-occupation.entity";
import { setHiddenMainOccupationApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenMainOccupationModalProps = {
  hiddenMainOccupation?: TMasterMainOccupation;
  setHiddenMainOccupation: Dispatch<SetStateAction<TMasterMainOccupation | undefined>>;
};

export const HiddenMainOccupationModal: FC<THiddenMainOccupationModalProps> = ({ hiddenMainOccupation, setHiddenMainOccupation }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenMainOccupation) return;
    try {
      await setHiddenMainOccupationApi(toNumber(hiddenMainOccupation.id), { isHidden: !hiddenMainOccupation.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenMainOccupation(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_OCCUPATION] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenMainOccupation?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenMainOccupation}
      onClose={() => setHiddenMainOccupation(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenMainOccupation?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
