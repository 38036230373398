import { background, text } from "@/theme/colors";
import { Box, CircularProgress, css, styled } from "@mui/material";
import { FC } from "react";

export const LoadingScreen: FC = () => {
  return (
    <LoadingScreenWrap>
      <CircularProgress />
    </LoadingScreenWrap>
  );
};

type TLoadingAreaProps = {
  width?: number;
  height?: number;
};

export const LoadingArea: FC<TLoadingAreaProps> = ({ width, height }) => {
  return (
    <LoadingAreaWrap width={width} height={height}>
      <CircularProgress size={28} />
    </LoadingAreaWrap>
  );
};

export const LoadingPage: FC = () => {
  return (
    <LoadingPageWrap>
      <CircularProgress />
    </LoadingPageWrap>
  );
};

const LoadingCSS = css`
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${text.secondary};
    font-size: 16px;
  }
`;

const LoadingScreenWrap = styled(Box)`
  position: fixed;
  z-index: 999;
  background-color: ${background.primary};
  width: 100vw;
  height: 100vh;
  ${LoadingCSS}
`;

const LoadingAreaWrap = styled(Box)`
  width: 100%;
  height: 100%;
  ${LoadingCSS}
`;

const LoadingPageWrap = styled(Box)`
  position: absolute;
  z-index: 999;
  background-color: ${background.primary};
  width: 100%;
  height: 100%;
  ${LoadingCSS}
`;
