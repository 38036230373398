export const fonts = `"Noto Sans JP", sans-serif`;

export const typography = {
  allVariants: {
    fontFamily: fonts,
    fontStyle: "normal",
  },
  // headline
  head32Bold: {
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: 700,
  },
  head24Semi: {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 500,
  },
  head24Bold: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 700,
  },
  head20Semi: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 500,
  },
  head20Bold: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 700,
  },
  // subtitle
  sub16Bold: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  sub16Semi: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  // body
  body14Bold: {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
  },
  body14: {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
  },
  // caption
  cap12Bold: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 700,
  },
  cap12: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  // link
  link14Semi: {
    fontSize: "14px",
    lineHeight: "22.4px",
    fontWeight: 500,
    textDecoration: "underline",
  },
} as const;

declare module "@mui/material/styles" {
  interface TypographyVariants {
    // headline
    head32Bold: React.CSSProperties;
    head24Semi: React.CSSProperties;
    head24Bold: React.CSSProperties;
    head20Semi: React.CSSProperties;
    head20Bold: React.CSSProperties;
    // subtitle
    sub16Bold: React.CSSProperties;
    sub16Semi: React.CSSProperties;
    // body
    body14Bold: React.CSSProperties;
    body14: React.CSSProperties;
    // caption
    cap12Bold: React.CSSProperties;
    cap12: React.CSSProperties;
    // link
    link14Semi: React.CSSProperties;
  }

  // Update the Typography's variant prop options
  interface TypographyVariantsOptions {
    // headline
    head32Bold: React.CSSProperties;
    head24Semi: React.CSSProperties;
    head24Bold: React.CSSProperties;
    head20Semi: React.CSSProperties;
    head20Bold: React.CSSProperties;
    // subtitle
    sub16Bold: React.CSSProperties;
    sub16Semi: React.CSSProperties;
    // body
    body14Bold: React.CSSProperties;
    body14: React.CSSProperties;
    // caption
    cap12Bold: React.CSSProperties;
    cap12: React.CSSProperties;
    // link
    link14Semi: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    // headline
    head32Bold: true;
    head24Semi: true;
    head24Bold: true;
    head20Semi: true;
    head20Bold: true;
    // subtitle
    sub16Bold: true;
    sub16Semi: true;
    // body
    body14Bold: true;
    body14: true;
    // caption
    cap12Bold: true;
    cap12: true;
    // link
    link14Semi: true;
  }
}
