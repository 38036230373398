import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Table } from "@/components/elements/Table";
import { TJobTypeForm, TMasterMajorCreateForm } from "@/features/master-data/types/masterDataJobTypeForm";
import { useFormTable } from "@/hooks/useFormTable";
import { MRT_ColumnDef } from "material-react-table";
import { useFormContext } from "react-hook-form";

export const CreateMajorFormTable = () => {
  const { control } = useFormContext<TMasterMajorCreateForm>();

  const formColumns: MRT_ColumnDef<TJobTypeForm>[] = [
    {
      header: "作業",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`jobTypes.${row.index}.jobName`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "期間更新CSVのコード（列CP）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`jobTypes.${row.index}.cpJobRenewVisaCsvCode`}>
            <Input />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({
    columns: formColumns,
    control,
    name: "jobTypes",
    initFieldData: {
      cpJobRenewVisaCsvCode: "",
      jobName: "",
    },
    primaryKey: "id",
  });

  return <Table table={table} maxHeight={400} />;
};
