import { DEFAULT_TIMER } from "@/constants/common";
import { useEffect } from "react";

export const useDebounce = (callback: () => void, deps: unknown[] = [], delay = DEFAULT_TIMER): void => {
  useEffect(() => {
    const handler = setTimeout(callback, delay);

    return () => clearTimeout(handler);
  }, [...deps, delay]);
};
