import { TRegisterReq } from "@/features/auth/types/registerReq";
import { clientApi } from "@/utils/api";
import { TApiRes } from "@/types/api";

export const registerApi = async (payload: TRegisterReq): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await clientApi.post<TApiRes<boolean>>("/register", payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
