import { useVisible } from "@/hooks/useVisible";
import { useRef } from "react";

export const useDialog = () => {
  const dialog = useVisible();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const resolver = useRef<Function>();

  const show = (): Promise<boolean> => {
    dialog.open();
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const confirm = () => {
    resolver.current?.(true);
    dialog.close();
  };

  const cancel = () => {
    resolver.current?.(false);
    dialog.close();
  };

  return { open: dialog.visible, show, confirm, cancel };
};
