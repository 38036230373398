import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { adminUserSchema } from "@/features/admin-user/schema/adminUserSchema";
import { createAdminUserApi } from "@/api/services/admin/user";
import { updateAdminUserApi } from "@/api/services/admin/user";
import { TAdminUserForm } from "@/features/admin-user/types/adminUserForm";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { setErrorForm } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const initCreateForm: TAdminUserForm = {
  fullName: "",
  email: "",
  adminRole: "",
};

type TAdminUserFormProps = {
  readonly?: boolean;
  previousData?: Partial<TAdminUserForm>;
};

export const AdminUserForm: FC<TAdminUserFormProps> = ({ readonly, previousData }) => {
  const { id } = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TAdminUserForm>({
    defaultValues: previousData ?? initCreateForm,
    resolver: yupResolver(adminUserSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  useEffect(() => {
    methods.reset(previousData ?? initCreateForm);
  }, [previousData]);

  const onSubmit = async (data: TAdminUserForm) => {
    try {
      if (id) {
        const rs = await updateAdminUserApi(id, data);
        methods.reset();
        navigate(getDynamicRoute(AppRoutes.adminUserDetail, { id: id }));
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_DETAIL, id] });
      } else {
        const rs = await createAdminUserApi(data);
        navigate(AppRoutes.adminUser);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_LIST] });
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={adminUserSchema}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Stack gap={3}>
            <FormField control={control} name="fullName">
              <Input readonly={readonly} />
            </FormField>
            <FormField control={control} name="adminRole">
              <Select readonly={readonly} enumName="EAdminRole" />
            </FormField>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={3}>
            <FormField control={control} name="email">
              <Input readonly={readonly} />
            </FormField>
          </Stack>
        </Grid>
      </Grid>
      {!readonly && (
        <Box textAlign="center" mt={5}>
          <Button type="submit">{id ? "保存" : "メールを送信"}</Button>
        </Box>
      )}
    </Form>
  );
};
