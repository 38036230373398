import { INVALID_PASSWORD_CONFIRM } from "@/constants/invalids";
import { validator } from "@/utils/validator";

export const registerSchema = validator.object().shape({
  fullName: validator.string().emptyToNull().label("氏名").required(),
  email: validator.string().emptyToNull().email().max(60).label("メールアドレス").required(),
  adminRole: validator.string().isSelection().emptyToNull().label("担当").required(),
  password: validator.string().emptyToNull().strongPassword().label("パスワード").required(),
  confirmPassword: validator
    .string()
    .emptyToNull()
    .trim()
    .label("パスワードの認証")
    .oneOf([validator.ref("password")], INVALID_PASSWORD_CONFIRM)
    .required(),
});
