import { TContactMenu } from "@/features/contact-type/types/contactMenu";
import { AppRoutes } from "@/routes/config";

export const contactMenu: TContactMenu[] = [
  {
    title: "新着のお問い合わせ一覧",
    url: AppRoutes.unresolvedContact,
    extraUrls: [AppRoutes.unresolvedContactDetail],
  },
  {
    title: "過去のお問い合わせ一覧",
    url: AppRoutes.resolvedContact,
    extraUrls: [AppRoutes.resolvedContactDetail],
  },
  {
    title: "お問い合わせの種類の管理",
    url: AppRoutes.contactType,
    extraUrls: [AppRoutes.contactTypeCreate, AppRoutes.contactTypeEdit],
  },
];
