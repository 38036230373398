import { useConversationList } from "@/api/services/admin/conversation";
import { CHANNELS, EVENTS } from "@/constants/socket";
import { TMessage } from "@/features/chat/types/message";
import { TRoom } from "@/features/chat/types/room";
import { formatConversationListDto } from "@/features/chat/utils/room";
import { useSocket } from "@/providers/SocketProvider";
import { isFireEvent } from "@/utils/pieces";
import { replaceStr } from "@/utils/string";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useRoomList = () => {
  const { socket } = useSocket();
  const { id } = useParams();
  const { dataFormatter, hasNextPage, isFetching, fetchNextPage } = useConversationList();
  const [oldRooms, setOldRooms] = useState<TRoom[]>([]);
  const [newRooms, setNewRooms] = useState<TRoom[]>([]);

  const changeLastMessage = (roomId: number, msg: string) => {
    setOldRooms((prev) => {
      const found = prev.find((room) => room.id.toString() === roomId.toString());
      if (!found) return prev;
      if (found.messages && found.messages?.length > 0) {
        found.messages[0].text = msg;
        if (roomId.toString() !== id) found.messages[0].readUserIds = [];
      }
      return prev;
    });
  };

  useEffect(() => {
    if (!isFetching) {
      const rooms = formatConversationListDto(dataFormatter);
      setOldRooms(rooms);
    }
  }, [isFetching]);

  useEffect(() => {
    if (socket) {
      socket.emit(EVENTS.CONVERSATION.JOIN_ROOM_LIST, replaceStr(CHANNELS.CONVERSATION.ROOM_LIST, { tenantId: 1 }));
      socket.on(EVENTS.CONVERSATION.NEW_ROOM, (room) => {
        console.log(room);

        setNewRooms((cur) => [...cur, room]);
      });
      return () => {
        socket.emit(EVENTS.CONVERSATION.LEAVE, replaceStr(CHANNELS.CONVERSATION.ROOM_LIST, { tenantId: 1 }));
        socket.off(EVENTS.CONVERSATION.NEW_ROOM);
      };
    }
  }, [socket]);

  useEffect(() => {
    const callback = (e: Event) => {
      if (isFireEvent<TMessage>(e)) {
        const { detail: receivedMessage } = e;
        changeLastMessage(receivedMessage.conversationId, receivedMessage.text);
        setOldRooms((prevRooms) => {
          const roomIndex = prevRooms.findIndex((room) => receivedMessage.conversationId.toString() === room.id.toString());
          if (roomIndex === -1) return prevRooms;
          const updatedRoom = { ...prevRooms[roomIndex], subtitle: receivedMessage.text };
          const updatedRooms = [updatedRoom, ...prevRooms.filter((_, index) => index !== roomIndex)];
          return updatedRooms;
        });
      }
    };
    window.addEventListener(EVENTS.CONVERSATION.NEW_MESSAGE, callback);
    return () => {
      window.removeEventListener(EVENTS.CONVERSATION.NEW_MESSAGE, callback);
    };
  }, []);

  return {
    oldRooms: oldRooms,
    newRooms,
    hasNextPage,
    isFetching,
    fetchNextPage,
  };
};
