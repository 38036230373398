import {
  INVALID_BOOLEAN,
  INVALID_DATE,
  INVALID_DATE_MUST_AFTER,
  INVALID_DATE_MUST_BEFORE,
  INVALID_DATE_MUST_PAST,
  INVALID_DATE_MUST_PAST_OR_TODAY,
  INVALID_DATE_MUST_SAME_OR_AFTER,
  INVALID_DATE_MUST_SAME_OR_BEFORE,
  INVALID_EMAIL,
  INVALID_LIMIT,
  INVALID_NOT_EMPTY,
  INVALID_NUMBER,
  INVALID_NUMBER_INTEGER,
  INVALID_NUMBER_LESS_THAN,
  INVALID_NUMBER_MAX,
  INVALID_NUMBER_MIN,
  INVALID_NUMBER_MORE_THAN,
  INVALID_NUMBER_NEGATIVE,
  INVALID_NUMBER_POSITIVE,
  INVALID_PASSWORD_STRONG,
  INVALID_SELECT_NOT_EMPTY,
  INVALID_STRING,
  INVALID_STRING_HALF_WIDTH,
  INVALID_STRING_KANJI,
  INVALID_STRING_KATAKANA,
  INVALID_STRING_LATIN,
  INVALID_STRING_LENGTH,
  INVALID_STRING_MATCHES,
  INVALID_STRING_MAX,
  INVALID_STRING_MIN,
  INVALID_STRING_UPPERCASE_AND_NUMBER,
  INVALID_URL,
  INVALID_VALUE,
} from "@/constants/invalids";
import { PATTERN } from "@/constants/pattern";
import { TExtendFile } from "@/types/file";
import { today } from "@/utils/dayjs";
import { convertDecimalToNumber } from "@/utils/input";
import { replaceStr } from "@/utils/string";
import { Dayjs, isDayjs } from "dayjs";
import { dayjs } from "@/utils/dayjs";
import * as yup from "yup";

declare module "yup" {
  interface MixedSchema {
    isDayjs(message?: string): MixedSchema<Dayjs>;
    isAfterDate(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isAfterDateTime(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isSameOrAfterDate(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isBeforeDate(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isBeforeDateTime(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isSameOrBeforeDate(refKey: string, message: { startLabel: string; endLabel: string } | string): MixedSchema<Dayjs>;
    isPastOrToday(message?: string): MixedSchema<Dayjs>;
    isPast(message?: string): MixedSchema<Dayjs>;
    isExtendFile(message?: string): MixedSchema<TExtendFile>;
    isSelection(): this;
  }
  interface StringSchema {
    strongPassword(message?: string): this;
    limit(length: number, message?: string): this;
    lettersOnly(message?: string): this;
    katakanaOnly(message?: string): this;
    latinsOnly(message?: string): this;
    notKanji(message?: string): this;
    notHalfWidthCharacter(message?: string): this;
    uppercaseAlphanumericCharactersOnly(message?: string): this;
    numbersOnly(message?: string): this;
    emptyToNull(): this;
    isSelection(): this;
    email(): this;
  }
  interface NumberSchema {
    limit(length: number, message?: string): this;
    emptyToNull(): this;
    decimal(): this;
    isSelection(): this;
  }
}

yup.setLocale({
  mixed: {
    notType: (ctx) => {
      const { type, path } = ctx;
      switch (type) {
        case "number":
          return replaceStr(INVALID_NUMBER, { path });
        case "string":
          return replaceStr(INVALID_STRING, { path });
        case "boolean":
          return replaceStr(INVALID_BOOLEAN, { path });
        default:
          break;
      }
      return replaceStr(INVALID_VALUE, { path });
    },
    required: ({ path, spec }) => {
      const isSelection = spec?.meta?.type === "selection";
      if (isSelection) return replaceStr(INVALID_SELECT_NOT_EMPTY, { path });
      return replaceStr(INVALID_NOT_EMPTY, { path });
    },
  },
  string: {
    email: ({ path }) => replaceStr(INVALID_EMAIL, { path }),
    length: ({ path, length }) => replaceStr(INVALID_STRING_LENGTH, { path, length }),
    max: ({ path, max }) => replaceStr(INVALID_STRING_MAX, { path, max }),
    min: ({ path, min }) => replaceStr(INVALID_STRING_MIN, { path, min }),
    url: ({ path }) => replaceStr(INVALID_URL, { path }),
    matches: ({ path }) => replaceStr(INVALID_STRING_MATCHES, { path }),
  },
  number: {
    integer: ({ path }) => replaceStr(INVALID_NUMBER_INTEGER, { path }),
    positive: ({ path }) => replaceStr(INVALID_NUMBER_POSITIVE, { path }),
    negative: ({ path }) => replaceStr(INVALID_NUMBER_NEGATIVE, { path }),
    min: ({ path, min }) => replaceStr(INVALID_NUMBER_MIN, { path, min }),
    max: ({ path, max }) => replaceStr(INVALID_NUMBER_MAX, { path, max }),
    lessThan: ({ path, less }) => replaceStr(INVALID_NUMBER_LESS_THAN, { path, less }),
    moreThan: ({ path, more }) => replaceStr(INVALID_NUMBER_MORE_THAN, { path, more }),
  },
});

yup.addMethod(yup.mixed, "isDayjs", function (message) {
  return this.test("isDayjs", message || INVALID_DATE, function (value, ctx) {
    if (!value) return true;
    if (isDayjs(value)) return true;
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_DATE, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isAfterDate", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_AFTER, { start, end });
  }
  return this.test("isAfterDate", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return dayjs(value.format("YYYY/MM/DD")).isAfter(dayjs(refValue.format("YYYY/MM/DD")));
  });
});

yup.addMethod(yup.mixed, "isAfterDateTime", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_AFTER, { start, end });
  }
  return this.test("isAfterDateTime", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return value.isAfter(refValue);
  });
});

yup.addMethod(yup.mixed, "isSameOrAfterDate", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_SAME_OR_AFTER, { start, end });
  }
  return this.test("isSameOrAfterDate", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return dayjs(value.format("YYYY/MM/DD")).isSameOrAfter(dayjs(refValue.format("YYYY/MM/DD")));
  });
});

yup.addMethod(yup.mixed, "isBeforeDate", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_BEFORE, { start, end });
  }
  return this.test("isBeforeDate", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return dayjs(value.format("YYYY/MM/DD")).isBefore(dayjs(refValue.format("YYYY/MM/DD")));
  });
});

yup.addMethod(yup.mixed, "isBeforeDateTime", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_BEFORE, { start, end });
  }
  return this.test("isBeforeDateTime", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return value.isBefore(refValue);
  });
});

yup.addMethod(yup.mixed, "isSameOrBeforeDate", function (refKey, message) {
  let errorMsg = INVALID_VALUE;
  if (typeof message === "string") {
    errorMsg = message;
  }
  if (typeof message === "object" && "startLabel" in message && "endLabel" in message) {
    const start = message.startLabel;
    const end = message.endLabel;
    errorMsg = replaceStr(INVALID_DATE_MUST_SAME_OR_BEFORE, { start, end });
  }
  return this.test("isSameOrBeforeDate", errorMsg, function (value, context) {
    const refValue = context.parent[refKey];
    if (!isDayjs(value) || !isDayjs(refValue)) return true;
    return dayjs(value.format("YYYY/MM/DD")).isSameOrBefore(dayjs(refValue.format("YYYY/MM/DD")));
  });
});

yup.addMethod(yup.mixed, "isPastOrToday", function () {
  return this.test("isPastOrToday", INVALID_DATE_MUST_PAST_OR_TODAY, function (value, ctx) {
    if (!value) return true;
    if (!isDayjs(value)) return true;
    if (value.isAfter(today().add(1, "day"))) {
      return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_DATE_MUST_PAST_OR_TODAY, { path: label }) });
    }
    return true;
  });
});

yup.addMethod(yup.mixed, "isPast", function () {
  return this.test("isPast", INVALID_DATE_MUST_PAST, function (value, ctx) {
    if (!value) return true;
    if (!isDayjs(value)) return true;
    if (value.isBefore(today())) {
      return true;
    }
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_DATE_MUST_PAST, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isExtendFile", function (message) {
  return this.test("isExtendFile", message || INVALID_VALUE, function (value, ctx) {
    if (!value) return true;
    if (typeof value === "object" && "status" in value && "id" in value) return true;
    return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_VALUE, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isSelection", function () {
  return this.meta({ type: "selection" });
});

// Add custom method to Yup for strong password validation
yup.addMethod(yup.string, "strongPassword", function (message) {
  const rules = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSpecial: 1,
  };
  return this.test("strongPassword", message || INVALID_PASSWORD_STRONG, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: ({ label }) => replaceStr(INVALID_NOT_EMPTY, { path: label }) });
    }

    const { minLength, minLowercase, minUppercase, minNumbers, minSpecial } = rules;

    const lowercaseCount = (value.match(/[a-z]/g) || []).length;
    const uppercaseCount = (value.match(/[A-Z]/g) || []).length;
    const numberCount = (value.match(/\d/g) || []).length;
    const specialCount = (value.match(/[`~!@#$%^&*()\[\]{}|:;'"<,>.?/\\_\-+=]/g) || []).length;
    const isValid = /^[a-zA-Z\d`~!@#$%^&*()\[\]{}|:;'"<,>.?/\\_\-+=]+$/.test(value);

    if (!isValid) {
      return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_LATIN, { path: label }) });
    }

    if (
      value.length < minLength ||
      lowercaseCount < minLowercase ||
      uppercaseCount < minUppercase ||
      numberCount < minNumbers ||
      specialCount < minSpecial
    ) {
      return createError({ path, message: INVALID_PASSWORD_STRONG });
    }
    return true;
  });
});

yup.addMethod(yup.string, "limit", function (length, message) {
  return this.test("stringLimit", message || INVALID_LIMIT, function (value, ctx) {
    if (!value) return true;
    if (value.length >= length)
      return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_LIMIT, { path: label, max: length }) });
    return true;
  });
});

yup.addMethod(yup.number, "limit", function (length, message) {
  return this.test("numberLimit", message || INVALID_LIMIT, function (value, ctx) {
    if (!value) return true;
    if (value >= length) return ctx.createError({ path: ctx.path, message: ({ label }) => replaceStr(INVALID_LIMIT, { path: label, max: length }) });
    return true;
  });
});

yup.addMethod(yup.string, "lettersOnly", function (message) {
  return this.test("lettersOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;

    if (!value) return true; // Allow empty strings
    if (PATTERN.LETTER.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_MATCHES, { path: label }) });
  });
});

yup.addMethod(yup.string, "numbersOnly", function (message) {
  return this.test("numbersOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.NUMBER_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_NUMBER, { path: label }) });
  });
});

yup.addMethod(yup.string, "latinsOnly", function (message) {
  return this.test("latinsOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.LATIN_ONLY.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_LATIN, { path: label }) });
  });
});

yup.addMethod(yup.string, "uppercaseAlphanumericCharactersOnly", function (message) {
  return this.test("uppercaseAlphanumericCharactersOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.UPPERCASE_ALPHANUMERIC_CHARACTERS_ONLY.test(value)) return true;
    return createError({
      path,
      message: ({ label }) => message || replaceStr(INVALID_STRING_UPPERCASE_AND_NUMBER, { path: label }),
    });
  });
});

yup.addMethod(yup.string, "katakanaOnly", function (message) {
  return this.test("katakanaOnly", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (PATTERN.KATAKANA_AND_SPACE.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_KATAKANA, { path: label }) });
  });
});

yup.addMethod(yup.string, "notKanji", function (message) {
  return this.test("notKanji", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (!PATTERN.KANJI.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_KANJI, { path: label }) });
  });
});

yup.addMethod(yup.string, "notHalfWidthCharacter", function (message) {
  return this.test("notHalfWidthCharacter", message || INVALID_STRING_MATCHES, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Allow empty strings

    if (!PATTERN.HALF_WIDTH_CHARACTER.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_STRING_HALF_WIDTH, { path: label }) });
  });
});

yup.addMethod(yup.string, "emptyToNull", function () {
  return this.transform(function (value, originalValue) {
    if (!value) return null;
    if (typeof originalValue === "string" && originalValue.trim() === "") return null;
    return value;
  });
});

yup.addMethod(yup.number, "emptyToNull", function () {
  return this.transform(function (value, originalValue) {
    if (typeof originalValue === "string" && originalValue.trim() === "") return null;
    if (value === "" || value === null || value === undefined) {
      return null;
    }
    return value;
  });
});

yup.addMethod(yup.number, "decimal", function () {
  return this.transform(function (value, originalValue) {
    if (!originalValue?.toString()) return NaN;
    if (typeof originalValue === "string") {
      originalValue = convertDecimalToNumber(originalValue);
    }
    return !isNaN(Number(originalValue)) ? Number(originalValue) : NaN;
  });
});

yup.addMethod(yup.number, "isSelection", function () {
  return this.meta({ type: "selection" });
});

yup.addMethod(yup.string, "isSelection", function () {
  return this.meta({ type: "selection" });
});

yup.addMethod(yup.string, "email", function (message) {
  return this.test("email", message || INVALID_EMAIL, function (value) {
    const { path, createError } = this;
    if (!value) return true;

    if (PATTERN.EMAIL.test(value)) return true;
    return createError({ path, message: ({ label }) => replaceStr(INVALID_EMAIL, { path: label }) });
  });
});

yup.addMethod(yup.mixed, "isSelection", function () {
  return this.meta({ type: "selection" });
});

export const validator = yup;
