import { TCreateInformationReqDto } from "@/api/services/admin/information/dtos/create-information.dto";
import { TGetInformationDto } from "@/api/services/admin/information/dtos/get-infomation.dto";
import { TUpdateInformationReqDto } from "@/api/services/admin/information/dtos/update-information.dto";
import { EMPTY_STR } from "@/constants/string";
import { TInformationForm } from "@/features/information/types/informationForm";
import { dayjs } from "@/utils/dayjs";

export const convertInformationFormToCreateReq = (data: TInformationForm, isDraft: boolean): TCreateInformationReqDto => {
  return {
    type: data.type || null,
    startDate: data.enableStartDate ? dayjs(data.startDate).format("YYYY-MM-DD") : null,
    endDate: data.enableEndDate ? dayjs(data.endDate).format("YYYY-MM-DD") : null,
    title: data.title ?? null,
    content: data.content ?? null,
    isDraft,
    filePath: data.filePath,
  };
};

export const convertInformationFormToUpdateReq = (data: TInformationForm, isDraft: boolean): TUpdateInformationReqDto => {
  return {
    type: data.type || null,
    startDate: data.enableStartDate ? dayjs(data.startDate).format("YYYY-MM-DD") : null,
    endDate: data.enableEndDate ? dayjs(data.endDate).format("YYYY-MM-DD") : null,
    title: data.title ?? null,
    content: data.content ?? null,
    isDraft,
    filePath: data.filePath,
  };
};

export const convertInformationDetailToForm = (data: TGetInformationDto): TInformationForm => {
  return {
    content: data.content || EMPTY_STR.TEXT,
    enableStartDate: !!data.startDate,
    startDate: data.startDate ? dayjs(data.startDate) : null,
    enableEndDate: !!data.endDate,
    endDate: data.endDate ? dayjs(data.endDate) : null,
    title: data.title || EMPTY_STR.TEXT,
    type: data.type || EMPTY_STR.TEXT,
    filePath: data.filePath ?? null,
  };
};
