import { TMasterNationality } from "@/api/entities/master-nationality.entity";
import { setHiddenNationalityApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenNationalityModalProps = {
  hiddenNationality?: TMasterNationality;
  setHiddenNationality: Dispatch<SetStateAction<TMasterNationality | undefined>>;
};

export const HiddenNationalityModal: FC<THiddenNationalityModalProps> = ({ hiddenNationality, setHiddenNationality }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenNationality) return;
    try {
      await setHiddenNationalityApi(toNumber(hiddenNationality.regionId), toNumber(hiddenNationality.id), {
        isHidden: !hiddenNationality.isHidden,
      });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenNationality(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_REGION] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenNationality?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenNationality}
      onClose={() => setHiddenNationality(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenNationality?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
