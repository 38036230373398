import { useGetMasterDataList } from "@/api/services/global/master";
import { TGetMasterListOutputDto } from "@/api/services/global/master/dtos/get-master-list.dto";
import { IconChevronRight } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { Linkable } from "@/components/elements/Linkable";
import { Table } from "@/components/elements/Table";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const MasterDataTable = () => {
  const navigate = useNavigate();
  const columns = useMemo<MRT_ColumnDef<TGetMasterListOutputDto>[]>(
    () => [
      {
        header: "データ名",
        Cell: ({ row }) => row.original.name || EMPTY_STR.TEXT,
        muiTableBodyCellProps: {
          sx: { width: "68%" },
        },
      },
      {
        header: "項目件数",
        Cell: ({ row }) => row.original.count,
        muiTableBodyCellProps: {
          sx: { width: "15%" },
        },
      },
      {
        header: "最終更新日",
        Cell: ({ row }) => (row.original.lastUpdated ? dayjs(row.original.lastUpdated).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE_TIME),
        muiTableBodyCellProps: {
          sx: { width: "15" },
        },
      },

      {
        header: "詳細",
        id: "action",
        Cell: ({ row }) => {
          const { type } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.masterDataDetail, { type })}>
              <IconBox>
                <IconChevronRight />
              </IconBox>
            </Linkable>
          );
        },
        size: 20,
      },
    ],
    [],
  );

  const { data, isLoading } = useGetMasterDataList();
  const { table } = useTable({
    columns,
    rows: data ?? [],
    onClickRow: (row) => navigate(getDynamicRoute(AppRoutes.masterDataDetail, { type: row.type })),
    pinningColumns: ["action"],
  });

  return <Table table={table} isLoading={isLoading} />;
};
