import { IconClose } from "@/assets/icons";
import { Button } from "@/components/elements/Button";
import { IconBox } from "@/components/elements/IconBox";
import { background } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Box, Modal as MuiModal, Stack, styled, Typography } from "@mui/material";
import { ReactNode } from "react";

export type TModalProps = {
  isOpen: boolean;
  title?: ReactNode;
  labelClose?: string;
  labelOk?: string;
  enableFooter?: boolean;
  enableCloseIcon?: boolean;
  hideBackdrop?: boolean;
  width?: number | string;
  keepMounted?: boolean;
  onOk?: () => void;
  onClose: () => void;
};

export const Modal: FCC<TModalProps> = ({
  children,
  isOpen,
  title,
  labelClose,
  labelOk,
  enableFooter = true,
  enableCloseIcon = true,
  hideBackdrop = false,
  keepMounted = false,
  width = 417,
  onOk,
  onClose,
}) => {
  return (
    <MuiModal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={isOpen}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      keepMounted={keepMounted}
    >
      <ModalContainer width={width}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title ? <Typography variant="head20Bold">{title}</Typography> : <span />}
          {enableCloseIcon && (
            <IconBox onClick={onClose}>
              <IconClose fontSize={24} />
            </IconBox>
          )}
        </Stack>
        {children}
        {enableFooter && (
          <Stack flexDirection="row" justifyContent="center" gap="24px">
            <Button variant="outline" onClick={onClose}>
              {labelClose ?? "キャンセル"}
            </Button>
            <Button onClick={onOk}>{labelOk ?? "わかった"}</Button>
          </Stack>
        )}
      </ModalContainer>
    </MuiModal>
  );
};

const ModalContainer = styled(Box)`
  min-width: 417px;
  background-color: ${background.white};
  box-shadow: 0px 8px 24px 0px #00000026;
  border-radius: 8px;
  padding: 24px;
  max-height: 90vh;
`;
