import { TMasterResidenceStatus } from "@/api/entities/master-residence-status.entity";
import { setHiddenResidenceStatusApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenResidenceStatusModalProps = {
  hiddenResidenceStatus?: TMasterResidenceStatus;
  setHiddenResidenceStatus: Dispatch<SetStateAction<TMasterResidenceStatus | undefined>>;
};

export const HiddenResidenceStatusModal: FC<THiddenResidenceStatusModalProps> = ({ hiddenResidenceStatus, setHiddenResidenceStatus }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenResidenceStatus) return;
    try {
      await setHiddenResidenceStatusApi(toNumber(hiddenResidenceStatus.id), { isHidden: !hiddenResidenceStatus.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenResidenceStatus(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenResidenceStatus?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenResidenceStatus}
      onClose={() => setHiddenResidenceStatus(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenResidenceStatus?.isHidden ? "この項目が有効になれますが、よろしいですか。" : "この項目が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
