import { Button } from "@/components/elements/Button";
import { Pagination } from "@/components/elements/Pagination";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

type TContactTypeListTopProps = {
  totalPage?: number;
};

export const ContactTypeListTop: FC<TContactTypeListTopProps> = ({ totalPage }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={totalPage} />
      <Button component={Link} size="sm" variant="outline" to={AppRoutes.contactTypeCreate}>
        新規作成
      </Button>
    </Stack>
  );
};
