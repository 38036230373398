import djs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import timezone from "dayjs/plugin/timezone";

djs.extend(isBetween);
djs.extend(customParseFormat);
djs.extend(isSameOrBefore);
djs.extend(isSameOrAfter);
djs.extend(utc);
djs.extend(duration);
djs.extend(weekOfYear);
djs.extend(timezone);
djs.tz.setDefault("Asia/Tokyo");

export const today = () => djs().set("h", 0).set("m", 0).set("s", 0).set("ms", 0);

export const dayjs = djs;
