import { Select } from "@/components/elements/Select";
import { PATTERN } from "@/constants/pattern";
import { convertToCompactDate, findLabelByValue } from "@/utils/object";
import { useEnum } from "@/api/services/global/enum";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { PLAIN_TEXT } from "@/features/convert/constants/convertPlainText";
import { TPayrollTableRow } from "@/features/convert/types/payrollTableRow";
import { PAYROLL_STATUS } from "@/features/convert/constants/payrollStatus";
import { DebounceInput } from "@/components/elements/Input/Input";
import { EMPTY_STR } from "@/constants/string";
import { useGetRegionList } from "@/api/services/global/master";
import { convertNumberToZipCode } from "@/utils/input";

export const useMakeConvertColumn = () => {
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TPayrollTableRow>[]>(
    () =>
      [
        {
          header: PLAIN_TEXT.numberOrder,
          accessorKey: "no",
          size: 120,
        },
        {
          header: PLAIN_TEXT.employeeFullName,
          accessorKey: "employeeFullName",
          size: 120,
          grow: true,
        },
        {
          header: PLAIN_TEXT.employeeNationality,
          size: 120,
          Cell: ({ row }) => {
            const { employeeNationalityId } = row.original;
            return <Typography variant="body14">{findLabelByValue(nationalityOptions, employeeNationalityId) || EMPTY_STR.TEXT}</Typography>;
          },
        },
        {
          header: PLAIN_TEXT.employeeBirthday,
          accessorKey: "employeeBirthday",
          Cell: ({ row }) => (row.original?.employeeBirthday ? convertToCompactDate(row.original?.employeeBirthday) : EMPTY_STR.TEXT),
          size: 120,
        },
        {
          header: PLAIN_TEXT.employeeGender,
          Cell: ({ row }) => findLabelByValue(genderOptions, row.original?.employeeGender) || EMPTY_STR.TEXT,
          size: 120,
        },
        {
          header: PLAIN_TEXT.employeeResidenceCardNumber,
          accessorKey: "employeeResidenceCardNumber",
          Cell: ({ row }) => row.original?.employeeResidenceCardNumber || EMPTY_STR.TEXT,
          size: 120,
        },
        {
          header: PLAIN_TEXT.employeeZipCode,
          Cell: ({ row }) => {
            const { employeeZipCode } = row.original;
            return employeeZipCode ? (
              <Typography variant="body14">〒{employeeZipCode ? convertNumberToZipCode(employeeZipCode) : ""}</Typography>
            ) : (
              EMPTY_STR.TEXT
            );
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.employeeAddress,
          accessorKey: "employeeAddress",
          Cell: ({ row }) => row.original?.employeeAddress || EMPTY_STR.TEXT,
          size: 120,
        },
        {
          header: PLAIN_TEXT.isChangeWorkingLocation,
          Cell: ({ row }) => {
            const { isChangeWorkingLocation } = row.original;
            const options = [
              { value: 1, label: PLAIN_TEXT.noChange },
              { value: 2, label: PLAIN_TEXT.change },
            ];
            return <Select value={isChangeWorkingLocation ? 2 : 1} options={options} readonly={true} />;
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.isChangeWorkingDetail,
          Cell: ({ row }) => {
            const { isChangeWorkingDetail } = row.original;
            const options = [
              { value: 1, label: PLAIN_TEXT.noChange },
              { value: 2, label: PLAIN_TEXT.change },
            ];
            return <Select value={isChangeWorkingDetail ? 2 : 1} options={options} readonly={true} />;
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.isChangeDispatchPlace,
          Cell: ({ row }) => {
            const { isChangeDispatchPlace } = row.original;
            const options = [
              { value: 1, label: PLAIN_TEXT.noChange },
              { value: 2, label: PLAIN_TEXT.change },
            ];
            return <Select value={isChangeDispatchPlace ? 2 : 1} options={options} readonly={true} />;
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.month,
          Cell: ({ row }) => {
            const { children } = row.original;
            const data = children ? children : [row.original];
            return (
              <Stack gap={0.5}>
                {data.map(({ month }) => (
                  <DebounceInput readonly value={`${month}月`} />
                ))}
              </Stack>
            );
          },
          size: 10,
        },
        {
          header: PLAIN_TEXT.workingDay,
          Cell: ({ row }) => {
            const { children } = row.original;
            const data = children ? children : [row.original];
            return (
              <Stack gap={0.5}>
                {data.map((item) => {
                  const { workingDayCount, status } = item;
                  const isDisabled = status === PAYROLL_STATUS.ERROR;
                  return <DebounceInput disabled={isDisabled} pattern={PATTERN.NUMBER} value={workingDayCount} readonly={true} />;
                })}
              </Stack>
            );
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.totalAmountPaid,
          Cell: ({ row }) => {
            const { children } = row.original;
            const data = children ? children : [row.original];
            return (
              <Stack gap={0.5}>
                {data.map((item) => {
                  const { totalAmountPaid, status } = item;
                  const isDisabled = status === PAYROLL_STATUS.ERROR;
                  return <DebounceInput disabled={isDisabled} pattern={PATTERN.NUMBER} value={totalAmountPaid} readonly={true} />;
                })}
              </Stack>
            );
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.netAmountPaid,
          Cell: ({ row }) => {
            const { children } = row.original;
            const data = children ? children : [row.original];
            return (
              <Stack gap={0.5}>
                {data.map((item) => {
                  const { netAmountPaid, status } = item;
                  const isDisabled = status === PAYROLL_STATUS.ERROR;
                  return <DebounceInput disabled={isDisabled} pattern={PATTERN.NUMBER} value={netAmountPaid} readonly={true} />;
                })}
              </Stack>
            );
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.legalDeductionAmount,
          Cell: ({ row }) => {
            const { children } = row.original;
            const data = children ? children : [row.original];
            return (
              <Stack gap={0.5}>
                {data.map((item) => {
                  const { legalDeductionAmount, status } = item;
                  const isDisabled = status === PAYROLL_STATUS.ERROR;

                  return <DebounceInput disabled={isDisabled} pattern={PATTERN.NUMBER} value={legalDeductionAmount} readonly={true} />;
                })}
              </Stack>
            );
          },
          size: 120,
        },
        {
          header: PLAIN_TEXT.hasComparisonEmployee,
          Cell: ({ row }) => {
            const { hasComparisonEmployee } = row.original;
            const options = [
              { value: 1, label: PLAIN_TEXT.yes },
              { value: 2, label: PLAIN_TEXT.no },
            ];

            return <Select options={options} value={hasComparisonEmployee ? 1 : 2} readonly={true} />;
          },
          size: 120,
        },
      ] as MRT_ColumnDef<TPayrollTableRow>[],
    [genderOptions, nationalityOptions],
  );

  return columns;
};
