import { AppRoutes } from "@/routes/config";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    location.href = AppRoutes.company;
  }, []);
  return <></>;
};

export default Home;
