import { text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Box, CircularProgress } from "@mui/material";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useState } from "react";
import Scroller from "react-infinite-scroller";

export type TInfiniteScrollProps = {
  loadMore: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult | void>;
  hasMore: boolean;
};

export const InfiniteScroll: FCC<TInfiniteScrollProps> = ({ children, hasMore, loadMore }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadMore = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await loadMore();
      setIsLoading(false);
    }
  };

  return (
    <Scroller
      loadMore={handleLoadMore}
      hasMore={hasMore}
      loader={
        <Box textAlign="center">
          <CircularProgress size={20} sx={{ color: text.disable }} />
        </Box>
      }
    >
      {children}
    </Scroller>
  );
};
