import { createInformationApi, updateInformationApi } from "@/api/services/admin/information";
import { useEnum } from "@/api/services/global/enum";
import { Button } from "@/components/elements/Button";
import { Editor } from "@/components/elements/Editor";
import { FileItem } from "@/components/elements/FileItem";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { RadioGroup } from "@/components/elements/RadioGroup";
import { Switch } from "@/components/elements/Switch";
import { TopPage } from "@/components/elements/TopPage";
import { UploadPopup } from "@/components/elements/UploadPopup";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { FILE_STATUS, FILE_UPLOAD_FOR } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { PreviewInformationModal } from "@/features/information/components/PreviewInformationModal";
import { informationSchema } from "@/features/information/schema/informationSchema";
import { TInformationForm } from "@/features/information/types/informationForm";
import { convertInformationFormToCreateReq, convertInformationFormToUpdateReq } from "@/features/information/utils/convert";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { TExtendFile } from "@/types/file";
import { dayjs } from "@/utils/dayjs";
import { setErrorForm, showError } from "@/utils/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type TInformationFormProps = {
  previousData?: Partial<TInformationForm>;
};

const initForm: TInformationForm = {
  title: "",
  content: "",
  enableEndDate: false,
  enableStartDate: false,
  endDate: null,
  filePath: null,
  startDate: null,
  type: "",
};

export const InformationForm: FC<TInformationFormProps> = ({ previousData }) => {
  const { id } = useParams();
  const { data: informationTypes } = useEnum("EInformationType");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();
  const methods = useForm<TInformationForm>({
    defaultValues: previousData ?? initForm,
    resolver: yupResolver(informationSchema),
    mode: "onBlur",
  });
  const { control, clearErrors, getValues, setValue } = methods;
  const { enableStartDate, enableEndDate } = useWatch({ control });
  const [openPreview, setOpenPreview] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploading, setUploading] = useState(false);

  const filePath = useWatch({ control, name: "filePath" });

  useEffect(() => {
    if (!enableStartDate) {
      clearErrors("startDate");
      setValue("startDate", dayjs());
    }
    if (!enableEndDate) clearErrors("endDate");
  }, [enableStartDate, enableEndDate]);

  const onSubmit = async (data: TInformationForm) => {
    await handleSaveData(data, false);
  };

  const handleSaveDraft = async () => {
    try {
      const payload = getValues();
      await handleSaveData(payload, true);
      methods.reset();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const handleSaveData = async (data: TInformationForm, isDraft: boolean) => {
    try {
      if (id) {
        const payload = convertInformationFormToUpdateReq(data, isDraft);
        const rs = await updateInformationApi(id, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INFORMATION.FETCH_DETAIL, id.toString()] });
      } else {
        const payload = convertInformationFormToCreateReq(data, isDraft);
        const rs = await createInformationApi(payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      navigate(AppRoutes.information);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INFORMATION.FETCH_LIST] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const onDropFiles = () => {
    setUploading(true);
  };

  const onFinishUpload = ([file]: TExtendFile[]) => {
    if (file?.filePath && file.status === FILE_STATUS.OK) {
      setValue("filePath", file.filePath ?? null, { shouldDirty: true });
      setUploading(false);
    }
  };

  const removeFile = () => {
    methods.setValue("filePath", null, { shouldDirty: true });
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} shouldBlock={uploading} schema={informationSchema}>
      <TopPage
        title={previousData ? "お知らせ編集" : "新しいお知らせを作成する"}
        description="ステータス：未公開"
        backUrl={AppRoutes.information}
        rightContent={
          <Stack direction="row" gap={3}>
            <Button variant="outline" size="lg" onClick={() => setOpenPreview(true)}>
              プレビュー
            </Button>
            <Button disabled={uploading} variant="outline" size="lg" onClick={handleSaveDraft}>
              下書き保存
            </Button>
            <Button disabled={uploading} size="lg" type="submit">
              更新
            </Button>
          </Stack>
        }
      />
      <Stack width="100%" maxWidth={1045} mx="auto">
        <Stack gap={2}>
          <Typography variant="sub16Bold">公開編集</Typography>
          <FormField control={control} name="type">
            <RadioGroup
              options={informationTypes.map((option, index) => {
                if (index === 0) return { ...option, label: `${option.label}（トップページ上部）` };
                return option;
              })}
              direction="column"
            />
          </FormField>
          <Stack gap={0.5}>
            <Typography variant="cap12Bold">公開開始日時</Typography>
            <Typography variant="cap12">メンテナンス時間に合わせて表示する場合などに設定</Typography>
            <Stack direction="row" alignItems="center" gap={3}>
              <Typography variant="cap12" whiteSpace="nowrap">
                設定
              </Typography>
              <FormField control={control} name="enableStartDate">
                <Switch />
              </FormField>
            </Stack>
            <FormField control={control} name="startDate" sx={{ maxWidth: 250 }} label="">
              <InputDatePicker format="YYYY/MM/DD" disabled={!enableStartDate} />
            </FormField>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="cap12Bold">公開終了日時</Typography>
            <Stack direction="row" alignItems="center" gap={3}>
              <Typography variant="cap12" whiteSpace="nowrap">
                設定
              </Typography>
              <FormField control={control} name="enableEndDate">
                <Switch />
              </FormField>
            </Stack>
            <FormField control={control} name="endDate" sx={{ maxWidth: 250 }} label="">
              <InputDatePicker format="YYYY/MM/DD" disabled={!enableEndDate} minDate={!previousData ? dayjs() : undefined} />
            </FormField>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography variant="sub16Bold">お知らせ内容</Typography>
          <FormField control={control} name="title">
            <Input size="lg" />
          </FormField>
          <FormField control={control} name="content">
            <Editor />
          </FormField>
          <Stack gap={1}>
            <Typography variant="sub16Bold">ファイル添付</Typography>
            <Typography variant="cap12">1ファイルのみ添付可能です</Typography>
            <Button size="lg" variant="outline" disabled={uploading || !!filePath} onClick={() => setOpenUpload(true)}>
              ファイルを添付
            </Button>
            {filePath && <FileItem filePath={filePath} onRemove={removeFile} downloadable width={350} enableFileSize />}
          </Stack>
        </Stack>
      </Stack>
      <UploadPopup
        isOpen={openUpload}
        onClose={() => setOpenUpload(false)}
        onCancel={() => setUploading(false)}
        uploadFor={FILE_UPLOAD_FOR.INFORMATION_FILE}
        onDropFiles={onDropFiles}
        onFinishUpload={onFinishUpload}
        options={{ multiple: false }}
        title={<Typography variant="body14Bold">ファイルをアップロードしてください。</Typography>}
      >
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Typography variant="body14" whiteSpace="pre-line" textAlign="center">{`ファイルを\nドラッグ&ドロップ`}</Typography>
          <Typography variant="body14">１ファイルのみ添付可能です</Typography>
        </Stack>
      </UploadPopup>
      {openPreview && <PreviewInformationModal open={openPreview} onClose={() => setOpenPreview(false)} />}
    </Form>
  );
};
