import { useConvertDetail } from "@/api/services/admin/convert-request";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { CONVERT_STATUS } from "@/constants/enum";
import { ConvertAcceptanceActivityInfo } from "@/features/convert/component/ConvertAcceptanceActivityInfo";
import { ConvertAcceptanceActivityTable } from "@/features/convert/component/ConvertAcceptanceActivityTable";
import { ConvertAction } from "@/features/convert/component/ConvertAction";
import { ConvertAnytimeEmploymentContractInfo } from "@/features/convert/component/ConvertAnytimeEmploymentContractInfo";
import { ConvertAnytimeSupportContractInfo } from "@/features/convert/component/ConvertAnytimeSupportContractInfo";
import { ConvertAnytimeSupportPlanInfo } from "@/features/convert/component/ConvertAnytimeSupportPlanInfo";
import { ConvertEmployeeTable } from "@/features/convert/component/ConvertEmployeeTable";
import { ConvertJSON } from "@/features/convert/component/ConvertJSON";
import { ConvertLifeSupportInfo } from "@/features/convert/component/ConvertLifeSupportInfo";
import { ConvertRetirementAcceptingDocumentInfo } from "@/features/convert/component/ConvertRetirementAcceptingDocumentInfo";
import { ConvertRetirementContractDocumentInfo } from "@/features/convert/component/ConvertRetirementContractDocumentInfo";
import { ConvertRetirementEmployeeTable } from "@/features/convert/component/ConvertRetirementEmployeeTable";
import { ConvertSummary } from "@/features/convert/component/ConvertSummary";
import {
  isAnytimeEmploymentContractData,
  isAnytimeSupportContractData,
  isAnytimeSupportPlanData,
  isRegularAcceptanceActivityData,
  isRegularLifeSupportData,
  isRetirementAcceptingWorkerData,
  isRetirementContractData,
} from "@/features/convert/utils/checkTypes";
import { AppRoutes } from "@/routes/config";
import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConvertDetailPageProps = {};

export const ConvertDetailPage: FC<TConvertDetailPageProps> = () => {
  const { data: convertResult, isLoading } = useConvertDetail();
  const convertRequestData = convertResult?.data;

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <TopPage
        title="届出の提出形式への変換依頼"
        backUrl={convertResult?.data.status !== CONVERT_STATUS.IN_PROGRESS ? AppRoutes.convertHistory : AppRoutes.convert}
      />
      <Stack gap={3}>
        <Stack gap={1} direction="row">
          <Box flex={1}>
            <ConvertSummary />
          </Box>
          <Box flex={1}>
            <ConvertAction />
          </Box>
        </Stack>
        <Typography variant="sub16Bold">データ</Typography>
        {convertRequestData && (
          <>
            {isRegularAcceptanceActivityData(convertRequestData.documentName, convertRequestData.json) && (
              <>
                <ConvertAcceptanceActivityInfo data={convertRequestData.json} />
                <ConvertAcceptanceActivityTable data={convertRequestData.json} />
              </>
            )}
            {isRegularLifeSupportData(convertRequestData.documentName, convertRequestData.json) && (
              <>
                <ConvertLifeSupportInfo data={convertRequestData.json} />
                <ConvertEmployeeTable employees={convertRequestData.json.employeesInfo} />
              </>
            )}
            {isAnytimeEmploymentContractData(convertRequestData.json) && (
              <>
                <ConvertAnytimeEmploymentContractInfo data={convertRequestData.json} />
                <ConvertEmployeeTable employees={convertRequestData.json.employeesInfo} showSpecific />
              </>
            )}
            {isAnytimeSupportContractData(convertRequestData.json) && (
              <>
                <ConvertAnytimeSupportContractInfo data={convertRequestData.json} />
                <ConvertEmployeeTable employees={convertRequestData.json.employeesInfo} showSpecific />
              </>
            )}
            {isAnytimeSupportPlanData(convertRequestData.json) && (
              <>
                <ConvertAnytimeSupportPlanInfo data={convertRequestData.json} />
                <ConvertEmployeeTable employees={convertRequestData.json.employeesInfo} showSpecific />
              </>
            )}
            {isRetirementAcceptingWorkerData(convertRequestData.documentName, convertRequestData.documentType, convertRequestData.json) && (
              <>
                <ConvertRetirementAcceptingDocumentInfo data={convertRequestData.json} />
                <ConvertRetirementEmployeeTable employee={convertRequestData.json.employeesInfo} />
              </>
            )}
            {isRetirementContractData(convertRequestData.documentName, convertRequestData.documentType, convertRequestData.json) && (
              <>
                <ConvertRetirementContractDocumentInfo data={convertRequestData.json} />
                <ConvertRetirementEmployeeTable employee={convertRequestData.json.employeesInfo} />
              </>
            )}
          </>
        )}
        {convertResult && <ConvertJSON convertRequestData={convertResult.data} />}
      </Stack>
    </>
  );
};
