import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";

import { CompanyForm } from "@/features/company/components/CompanyForm";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { FC } from "react";

type TCompanyCreatePageProps = {};

export const CompanyCreatePage: FC<TCompanyCreatePageProps> = () => {
  return (
    <Stack gap={4}>
      <TopPage title="受け入れ企業を追加" description="企業の基本情報を入力して追加しましょう" backUrl={AppRoutes.company} />
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyForm />
      </InnerBox>
    </Stack>
  );
};
