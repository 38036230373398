import { useEnum } from "@/api/services/global/enum";
import { Tag } from "@/components/elements/Tag";
import { EMPTY_STR } from "@/constants/string";
import { background, state } from "@/theme/colors";
import { ECompanyContractStatus } from "@/types/enum";
import { findLabelByValue } from "@/utils/object";
import { FC, useMemo } from "react";

export type TCompanyStatusTagProps = {
  status: string;
};

export const CompanyStatusTag: FC<TCompanyStatusTagProps> = ({ status }) => {
  const { data: contractStatusOptions } = useEnum("ECompanyContractStatus");

  const colorTagProps = useMemo(() => {
    if (status === ECompanyContractStatus.TRIAL) {
      return { color: state.warning_2, pointColor: state.warning_1 };
    }
    if (status === ECompanyContractStatus.DURING_CONTRACT) {
      return { color: state.success_2, pointColor: state.success_1 };
    }
    if (status === ECompanyContractStatus.EXPIRED_CONTRACT) {
      return { color: state.error_2, pointColor: state.error_1 };
    }

    return { color: background.disable };
  }, [status]);

  return <Tag enablePoint variant="bold" title={findLabelByValue(contractStatusOptions, status) || EMPTY_STR.TEXT} {...colorTagProps} />;
};
