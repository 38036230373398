import { useGetProvincesAndMunicipalities } from "@/api/services/global/constant";
import { createLocalGovernmentCounterApi, updateLocalGovernmentCounterApi } from "@/api/services/global/master";
import { TGetLocalGovernmentCounterListOutputDto } from "@/api/services/global/master/dtos/get-master-local-government-counter-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { SelectMulti } from "@/components/elements/SelectMulti";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { masterDataLocalGovernmentCounterSchema } from "@/features/master-data/schema/masterDataLocalGovernmentCounterSchema";
import { TMasterDataLocalGovernmentCounterForm } from "@/features/master-data/types/masterDataLocalGovernmentCounterForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";

type TLocalGovernmentCounterFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLocalGovernmentCounter?: TGetLocalGovernmentCounterListOutputDto;
};

export const LocalGovernmentCounterFormModal: FC<TLocalGovernmentCounterFormModalProps> = ({ onClose, isOpen, selectedLocalGovernmentCounter }) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      officeName: selectedLocalGovernmentCounter ? selectedLocalGovernmentCounter.officeName : undefined,
      bmOfficeRenewVisaCsvCode: selectedLocalGovernmentCounter ? selectedLocalGovernmentCounter.bmOfficeRenewVisaCsvCode : undefined,
      targetMunicipalities: selectedLocalGovernmentCounter?.targetMunicipalities
        ? selectedLocalGovernmentCounter.targetMunicipalities.map((item) => item.municipalityCode.toString())
        : [],
      targetProvinces: selectedLocalGovernmentCounter
        ? selectedLocalGovernmentCounter.targetProvinces.map((item) => item.prefectureCode.toString())
        : [],
    }),
    [selectedLocalGovernmentCounter],
  );

  const methods = useForm<TMasterDataLocalGovernmentCounterForm>({
    resolver: yupResolver(masterDataLocalGovernmentCounterSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const { targetProvinces: targetProvincesWatchField } = useWatch({ control });

  const {
    isLoading: isLoadingProvincesAndMunicipalities,
    provinceOptions,
    getMunicipalityOptionsByMultiProvince,
  } = useGetProvincesAndMunicipalities();

  const municipalityOptions = useMemo(
    () => getMunicipalityOptionsByMultiProvince(targetProvincesWatchField ?? []),
    [targetProvincesWatchField, isLoadingProvincesAndMunicipalities],
  );

  const onSubmit = async (data: TMasterDataLocalGovernmentCounterForm) => {
    try {
      if (selectedLocalGovernmentCounter) {
        const rs = await updateLocalGovernmentCounterApi({
          localGovernmentCounters: [
            {
              id: toNumber(selectedLocalGovernmentCounter.id),
              officeName: data.officeName,
              bmOfficeRenewVisaCsvCode: data.bmOfficeRenewVisaCsvCode,
              targetProvinces:
                provinceOptions.length > 0
                  ? provinceOptions
                      .map((item) => (data.targetProvinces.includes(item.value.toString()) ? item : undefined))
                      .filter((item) => !!item)
                      .map((option) => ({
                        prefectureCode: toNumber(option?.value),
                        prefectureName: option?.label ?? "",
                      }))
                  : [],
              targetMunicipalities:
                municipalityOptions.length > 0
                  ? municipalityOptions
                      .map((item) => (data.targetMunicipalities && data.targetMunicipalities.includes(item.value.toString()) ? item : undefined))
                      .filter((item) => !!item)
                      .map((option) => ({
                        municipalityCode: toNumber(option?.value),
                        municipalityName: option?.label ?? "",
                      }))
                  : [],
            },
          ],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createLocalGovernmentCounterApi({
          officeName: data.officeName,
          bmOfficeRenewVisaCsvCode: data.bmOfficeRenewVisaCsvCode,
          targetProvinces:
            provinceOptions.length > 0
              ? provinceOptions
                  .map((item) => (data.targetProvinces.includes(item.value.toString()) ? item : undefined))
                  .filter((item) => !!item)
                  .map((option) => ({
                    prefectureCode: toNumber(option?.value),
                    prefectureName: option?.label ?? "",
                  }))
              : [],
          targetMunicipalities:
            municipalityOptions.length > 0
              ? municipalityOptions
                  .map((item) => (data.targetMunicipalities && data.targetMunicipalities.includes(item.value.toString()) ? item : undefined))
                  .filter((item) => !!item)
                  .map((option) => ({
                    municipalityCode: toNumber(option?.value),
                    municipalityName: option?.label ?? "",
                  }))
              : [],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LOCAL_GOVERNMENT_COUNTER] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedLocalGovernmentCounter ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataLocalGovernmentCounterSchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={6}>
            <FormField control={control} name="officeName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="bmOfficeRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="targetProvinces">
              <SelectMulti options={provinceOptions} loading={isLoadingProvincesAndMunicipalities} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="targetMunicipalities">
              <SelectMulti options={municipalityOptions} loading={isLoadingProvincesAndMunicipalities} />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
