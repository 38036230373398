import { useEffect } from "react";
import { FieldValues, Path, UseFormReturn, useWatch } from "react-hook-form";

type TUseTriggerValidateProps<T extends FieldValues> = {
  methods: UseFormReturn<T, object, undefined>;
  name: Path<T>;
  triggerFields: Path<T>[];
};

export const useTriggerValidate = <T extends FieldValues>({ methods, name, triggerFields }: TUseTriggerValidateProps<T>) => {
  const { control, trigger } = methods;
  const value = useWatch({ control, name });

  useEffect(() => {
    triggerFields.forEach((field) => {
      trigger(field);
    });
  }, [value]);
};
