import { useGetProvincesAndMunicipalities } from "@/api/services/global/constant";
import { useEnum } from "@/api/services/global/enum";
import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import {
  EEmploymentContractModificationType,
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/convert/enums/anytime";
import { TAnytimeEmploymentContractData } from "@/features/convert/types/documents/anytime/employmentContract";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertAnytimeEmploymentContractInfoProps = {
  data: TAnytimeEmploymentContractData;
};

export const ConvertAnytimeEmploymentContractInfo = ({ data }: TConvertAnytimeEmploymentContractInfoProps) => {
  const { data: employmentContractModificationTypeOptions } = useEnum("EEmploymentContractModificationType");
  const { data: reasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: reasonForEmployeesOptions } = useEnum("EModifyContractTerminateReasonForEmployee");
  const { data: modifyContractTerminateReasonOptions } = useEnum("EModifyContractTerminateReason");
  const { data: modificationReasonOptions } = useEnum("EModifyContractModificationReason");
  const { data: locationReasonsOptions } = useEnum("EModifyContractChangeDispatchLocationReasons");
  const { companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();
  const { provinceOptions } = useGetProvincesAndMunicipalities();

  const { documentInfo } = data;

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">雇用契約の変更に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>
          {/* 届出の事由 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">届出の事由</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">--</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {findLabelByValue(employmentContractModificationTypeOptions, documentInfo.modificationType) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {/* 契約の変更 */}
          {documentInfo.modificationType === EEmploymentContractModificationType.MODIFY_CONTRACT && (
            <>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の変更</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">変更発生日</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{dayjs(documentInfo.modifyContractDate).format(COMPACT_DATE_FORMAT)}</Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の変更</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">変更事項</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {documentInfo.modifyContractModificationReasons?.map((value) => findLabelByValue(modificationReasonOptions, value)).join(", ")}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の変更</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">派遣先の変更の場合に選択</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      <Typography variant="body14">
                        {documentInfo.modifyContractChangeDispatchLocationReasons
                          ?.map((value) => findLabelByValue(locationReasonsOptions, value))
                          .join(", ")}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の変更</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">変更後の内容</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14" whiteSpace="pre-line">
                      {documentInfo.modifyContractContent}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
          {/* 契約の終了 */}
          {documentInfo.modificationType === EEmploymentContractModificationType.TERMINATE_CONTRACT && (
            <>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の終了</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">終了年月日</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{dayjs(documentInfo.terminateContractDate).format(COMPACT_DATE_FORMAT)}</Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">契約の終了</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">終了の事由</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {findLabelByValue(modifyContractTerminateReasonOptions, documentInfo.terminateContractReason)}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              {documentInfo.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY && (
                <>
                  <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="body14">契約の変更</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body14">所属機関都合の詳細</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body14">
                          {findLabelByValue(reasonForCompanyOptions, documentInfo.terminateContractReasonByCompany)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {documentInfo.terminateContractReasonByCompany === EModifyContractTerminateReasonForCompany.OTHER && (
                    <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Typography variant="body14">契約の変更</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body14">その他</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body14">
                            <Typography variant="body14">{documentInfo.terminateContractOtherByCompany}</Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                </>
              )}
              {documentInfo.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_EMPLOYEE && (
                <>
                  <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="body14">契約の変更</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body14">外国人都合の詳細</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body14">
                          {findLabelByValue(reasonForEmployeesOptions, documentInfo.terminateContractReasonByEmployee)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {documentInfo.terminateContractReasonByEmployee === EModifyContractTerminateReasonForEmployee.OTHER && (
                    <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Typography variant="body14">契約の変更</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body14">その他</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body14">
                            <Typography variant="body14">{documentInfo.terminateContractOtherReasonByEmployee}</Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
          {/* 新たな契約の締結 */}
          {documentInfo.modificationType === EEmploymentContractModificationType.RENEW_CONTRACT && (
            <>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">新たな契約の締結</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">締結年月日</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14" whiteSpace="pre-line">
                      {dayjs(documentInfo.renewContractDate).format(COMPACT_DATE_FORMAT)}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">新たな契約の締結</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">契約の内容</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14" whiteSpace="pre-line">
                      {documentInfo.renewContractContent}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">氏名又は名称</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">フリガナ</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyFuriganaName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">法人番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyIdentificationNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定産業分野</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {findLabelByValue(companySpecificIndustryOptions, documentInfo.companySpecificIndustryId) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">郵便番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {documentInfo.companyZipCode ? convertNumberToZipCode(documentInfo.companyZipCode) : EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">都道府県</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  <Typography variant="body14">{documentInfo.companyProvince || EMPTY_STR.TEXT}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">住所</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyAddress || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">電話番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyTelNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </InnerBox>
  );
};
