import { IconOrderTable } from "@/assets/icons/IconOrderTable";
import { blue, divider } from "@/theme/colors";
import { IconButton, IconButtonProps } from "@mui/material";
import { MRT_RowData, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import { useCallback, useMemo } from "react";

export type TUseTableProps<T extends MRT_RowData> = {
  rows: T[];
  disableRow?: (row: T) => boolean;
  hightLightRow?: (row: T) => boolean;
  addedRow?: (row: T) => boolean;
  filterRow?: (row: T) => boolean;
  onClickRow?: (row: T) => void;
  infiniteScroll?: {
    fetchNextPage: () => Promise<unknown>;
    hasNextPage: boolean;
  };
  pinningColumns?: string[];
} & Omit<MRT_TableOptions<T>, "data">;

export const useTable = <T extends MRT_RowData>({
  rows,
  columns,
  disableRow,
  hightLightRow,
  addedRow,
  infiniteScroll,
  filterRow,
  onClickRow,
  pinningColumns,
  initialState,
  ...rest
}: TUseTableProps<T>) => {
  const fetchMoreOnBottomReached = useCallback(
    (target: HTMLDivElement) => {
      const { scrollHeight, scrollTop, clientHeight } = target;
      if (scrollHeight - scrollTop - clientHeight === 0) {
        infiniteScroll?.fetchNextPage();
      }
    },
    [infiniteScroll],
  );

  const mappingColumns = useMemo(() => {
    if (!pinningColumns) return columns;
    if (!pinningColumns.length) return columns;
    let setFirstPin = false;
    return columns.map((column) => {
      if (pinningColumns.find((colId) => column.id === colId)) {
        if (!setFirstPin) {
          setFirstPin = true;
          return {
            muiTableHeadCellProps: { sx: { borderLeft: `1px solid ${divider.middle}` } },
            muiTableBodyCellProps: {
              sx: { borderLeft: "1px solid", borderColor: divider.middle },
            },
            ...column,
          };
        }
      }
      return column;
    });
  }, [columns]);

  const table = useMaterialReactTable({
    columns: mappingColumns,
    data: filterRow ? rows.filter(filterRow) : rows,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableSorting: false,
    icons: {
      DragHandleIcon: (props: IconButtonProps) => (
        <IconButton {...props} size="small">
          <IconOrderTable color={blue[80]} />
        </IconButton>
      ),
    },
    muiTableBodyCellProps: ({ row: { original } }) => {
      const classNames = [];

      // add class disabled for row match condition
      const isDisabled = disableRow?.(original);
      if (isDisabled) classNames.push("disabled");

      // add class highlight for row match condition
      const isHighlighted = hightLightRow?.(original);
      if (isHighlighted) classNames.push("highlight");

      const isAdded = addedRow?.(original);
      if (isAdded) classNames.push("added");

      const isClickable = !!onClickRow;
      if (isClickable) classNames.push("clickable");

      if (!classNames.length) return {};

      return { className: classNames.join(" "), onClick: () => onClickRow?.(original) };
    },
    muiTableContainerProps: {
      ...rest.muiTableContainerProps,
      onScroll: (e) => {
        if (!infiniteScroll) return;
        if (!infiniteScroll.hasNextPage) return;
        fetchMoreOnBottomReached(e.target as HTMLDivElement);
      },
    },
    initialState: {
      columnPinning: {
        right: pinningColumns,
      },
      ...initialState,
    },
    ...rest,
  });

  return { table };
};
