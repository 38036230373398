import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { AdminUserForm } from "@/features/admin-user/components/AdminUserForm";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { FC } from "react";

export const AdminUserCreatePage: FC = () => {
  return (
    <Stack gap={4}>
      <TopPage title="irohana担当者の新規追加" backUrl={AppRoutes.adminUser} />
      <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
        <AdminUserForm />
      </InnerBox>
    </Stack>
  );
};
