import { TGetMessageOutputDto } from "@/api/services/admin/conversation/dtos/get-message-list-by-conversation.dto";
import { TMessage } from "@/features/chat/types/message";

export const formatMessageDto = (data: TGetMessageOutputDto): TMessage => {
  return {
    id: data.id,
    conversationId: data.conversationId,
    screenRouteName: data.screenRouteName,
    createdAt: data.createdAt,
    creator: data.creator,
    readUserIds: data.readUserIds,
    text: data.text,
  };
};

export const formatMessageListDto = (data: TGetMessageOutputDto[]): TMessage[] => {
  return data.map((item) => ({
    id: item.id,
    conversationId: item.conversationId,
    screenRouteName: item.screenRouteName,
    createdAt: item.createdAt,
    creator: item.creator,
    readUserIds: item.readUserIds,
    text: item.text,
  }));
};
