import { Pagination } from "@/components/elements/Pagination";
import { Stack } from "@mui/material";
import { FC } from "react";

type TContactListTopProps = {
  totalPage?: number;
};

export const ContactListTop: FC<TContactListTopProps> = ({ totalPage }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={totalPage} />
    </Stack>
  );
};
