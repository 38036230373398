import { TCreateContactTypeReqDto, TCreateContactTypeResDto } from "@/api/services/contact-type/dtos/create-contact-type.dto";
import { TDeleteContactTypeResDto } from "@/api/services/contact-type/dtos/delete-contact-type.dto";
import { TGetContactTypeListReqDto, TGetContactTypeListResDto } from "@/api/services/contact-type/dtos/get-contact-type-list.dto";
import { TGetContactTypeResDto } from "@/api/services/contact-type/dtos/get-contact-type.dto";
import { TUpdateContactTypeReqDto, TUpdateContactTypeResDto } from "@/api/services/contact-type/dtos/update-contact-type.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createContactTypeApi = async (payload: TCreateContactTypeReqDto): Promise<TCreateContactTypeResDto> => {
  const rs = await clientApi.post<TCreateContactTypeResDto>(`/contact-types`, payload);
  return rs.data;
};

export const fetchContactTypeApi = async (contactTypeId: string): Promise<TGetContactTypeResDto> => {
  const rs = await clientApi.get<TGetContactTypeResDto>(`/contact-types/${contactTypeId}`);
  return rs.data;
};

export const useContactType = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchContactTypeApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchContactTypeListApi = async (req: TGetContactTypeListReqDto): Promise<TGetContactTypeListResDto> => {
  const url = createUrlWithParams("/contact-types", req);
  const rs = await clientApi.get<TGetContactTypeListResDto>(url);

  return rs.data;
};

export const useContactTypeList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_LIST, pagReq],
    queryFn: () => fetchContactTypeListApi(pagReq),
  });
};

export const updateContactTypeApi = async (contactTypeId: string, payload: TUpdateContactTypeReqDto): Promise<TUpdateContactTypeResDto> => {
  const rs = await clientApi.put<TUpdateContactTypeResDto>(`/contact-types/${contactTypeId}`, payload);
  return rs.data;
};

export const deleteContactTypeApi = async (contactTypeId: number): Promise<TDeleteContactTypeResDto> => {
  const rs = await clientApi.delete<TDeleteContactTypeResDto>(`/contact-types/${contactTypeId}`);
  return rs.data;
};
