import { TCreateUserReqDto, TCreateUserResDto } from "@/api/services/admin/user/dtos/create-user.dto";
import { TDeleteUserResDto } from "@/api/services/admin/user/dtos/delete-user.dto";
import { TGetUserListReqDto, TGetUserListResDto } from "@/api/services/admin/user/dtos/get-user-list.dto";
import { TGetUserResDto } from "@/api/services/admin/user/dtos/get-user.dto";
import { TUpdateUserReqDto, TUpdateUserResDto } from "@/api/services/admin/user/dtos/update-user.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createAdminUserApi = async (payload: TCreateUserReqDto): Promise<TCreateUserResDto> => {
  const rs = await clientApi.post<TCreateUserResDto>("/users", payload);
  return rs.data;
};

export const deleteAdminUserApi = async (id: number): Promise<TDeleteUserResDto> => {
  const rs = await clientApi.delete<TDeleteUserResDto>(`/users/${id}`);
  return rs.data;
};

export const fetchAdminUserApi = async (id: string): Promise<TGetUserResDto> => {
  const rs = await clientApi.get<TGetUserResDto>(`/users/${id}`);
  return rs.data;
};

export const useAdminUser = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchAdminUserApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchAdminUserListApi = async (req: TGetUserListReqDto): Promise<TGetUserListResDto> => {
  const url = createUrlWithParams("/users", req);
  const rs = await clientApi.get<TGetUserListResDto>(url);
  return rs.data;
};

export const useAdminUserList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_LIST, pagReq],
    queryFn: () => fetchAdminUserListApi({ ...pagReq, perPage: 20 }),
    placeholderData: keepPreviousData,
  });
};

export const updateAdminUserApi = async (userId: string, payload: TUpdateUserReqDto): Promise<TUpdateUserResDto> => {
  const { fullName, email, adminRole } = payload;
  const rs = await clientApi.put<TUpdateUserResDto>(`/users/${userId}`, { fullName, email, adminRole });
  return rs.data;
};
