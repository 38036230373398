import { createResidenceStatusApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CreateResidenceStatusFormTable } from "@/features/master-data/components/ResidencePeriod/CreateResidenceStatusFormTable";
import { masterDataCreateResidenceStatusSchema } from "@/features/master-data/schema/masterDataResidencePeriodSchema";
import { TMasterResidenceStatusCreateForm } from "@/features/master-data/types/masterDataResidencePeriodForm";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";

type TCreateResidenceStatusFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateResidenceStatusFormModal: FC<TCreateResidenceStatusFormModalProps> = ({ onClose, isOpen }) => {
  const queryClient = useQueryClient();

  const methods = useForm<TMasterResidenceStatusCreateForm>({
    resolver: yupResolver(masterDataCreateResidenceStatusSchema),
    mode: "onBlur",
    defaultValues: {
      residenceStatus: "",
      residenceStatusRenewVisaCsvCode: "",
      residencePeriods: [
        {
          desiredResidencePeriod: "",
          desiredResidencePeriodRenewVisaCsvCode: "",
          residencePeriod: "",
          residencePeriodRenewVisaCsvCode: "",
        },
      ],
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterResidenceStatusCreateForm) => {
    try {
      const rs = await createResidenceStatusApi({
        residenceStatus: data.residenceStatus,
        residenceStatusRenewVisaCsvCode: data.residenceStatusRenewVisaCsvCode,
        residencePeriods: data.residencePeriods.map((item) => ({
          desiredResidencePeriod: item.desiredResidencePeriod,
          desiredResidencePeriodRenewVisaCsvCode: item.desiredResidencePeriodRenewVisaCsvCode,
          residencePeriod: item.residencePeriod,
          residencePeriodRenewVisaCsvCode: item.residencePeriodRenewVisaCsvCode,
        })),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataCreateResidenceStatusSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatus">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatusRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">在留期間</Typography>
              <CreateResidenceStatusFormTable />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
