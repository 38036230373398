import { validator } from "@/utils/validator";

export const nationalitySchema = validator.object().shape({
  regionId: validator.string().emptyToNull().label("地域").required(),
  nationalityName: validator.string().emptyToNull().label("名称").required(),
  engNationalityName: validator.string().emptyToNull().latinsOnly().label("名称（英語）").required(),
  nationalRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード").required(),
});

export const masterDataCreateRegionSchema = validator.object().shape({
  regionName: validator.string().emptyToNull().label("地域").required(),
  nationalities: validator.array().of(nationalitySchema).label("nationality").required(),
});

export const masterDataUpdateRegionSchema = validator.object().shape({
  regionName: validator.string().emptyToNull().label("地域").required(),
});
