import { TSettingMenu } from "@/features/system-setting/types/settingMenu";
import { AppRoutes } from "@/routes/config";

export const settingMenu: TSettingMenu[] = [
  {
    title: "通知設定",
    url: AppRoutes.emailSetting,
  },
  {
    title: "メンテナンスツール",
    url: AppRoutes.maintenanceSetting,
  },
];
