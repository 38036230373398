import { useToast } from "@/hooks/useToast";
import { FC, useEffect, useRef } from "react";
import { Id } from "react-toastify";

export const InternetStatus: FC = () => {
  const toastId = useRef<Id>();
  const toast = useToast();

  useEffect(() => {
    const handleOnline = () => {
      toastId.current && toast.close(toastId.current);
      toastId.current = toast.success("インターネット接続が回復しました。");
    };

    const handleOffline = () => {
      toastId.current && toast.close(toastId.current);
      toastId.current = toast.error("インターネット接続が失われました。");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return null;
};
