import { createLanguageTestApi, updateLanguageTestApi } from "@/api/services/global/master";
import { TGetLanguageTestListOutputDto } from "@/api/services/global/master/dtos/get-master-language-test-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { masterDataLanguageTestSchema } from "@/features/master-data/schema/masterDataLanguageTestSchema";
import { TMasterLanguageTestForm } from "@/features/master-data/types/masterDataLanguageTestForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TLanguageTestFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLanguageTest?: TGetLanguageTestListOutputDto;
};

export const LanguageTestFormModal: FC<TLanguageTestFormModalProps> = ({ onClose, isOpen, selectedLanguageTest }) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      languageTestName: selectedLanguageTest ? selectedLanguageTest.languageTestName : undefined,
      ckLanguageTestRenewVisaCsvCode: selectedLanguageTest ? selectedLanguageTest.ckLanguageTestRenewVisaCsvCode : undefined,
    }),
    [selectedLanguageTest],
  );

  const methods = useForm<TMasterLanguageTestForm>({
    resolver: yupResolver(masterDataLanguageTestSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterLanguageTestForm) => {
    try {
      if (selectedLanguageTest) {
        const rs = await updateLanguageTestApi({
          languageTests: [
            {
              id: toNumber(selectedLanguageTest.id),
              languageTestName: data.languageTestName,
              ckLanguageTestRenewVisaCsvCode: data.ckLanguageTestRenewVisaCsvCode,
            },
          ],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createLanguageTestApi({
          languageTestName: data.languageTestName,
          ckLanguageTestRenewVisaCsvCode: data.ckLanguageTestRenewVisaCsvCode,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LANGUAGE_TEST] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedLanguageTest ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataLanguageTestSchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={6}>
            <FormField control={control} name="languageTestName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="ckLanguageTestRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
