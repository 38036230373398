import { TMasterLivingRelationShip } from "@/api/entities/master-living-relationship.entity";
import { setHiddenLivingRelationshipApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenLivingRelationshipModalProps = {
  hiddenLivingRelationship?: TMasterLivingRelationShip;
  setHiddenLivingRelationship: Dispatch<SetStateAction<TMasterLivingRelationShip | undefined>>;
};

export const HiddenLivingRelationshipModal: FC<THiddenLivingRelationshipModalProps> = ({ hiddenLivingRelationship, setHiddenLivingRelationship }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenLivingRelationship) return;
    try {
      await setHiddenLivingRelationshipApi(toNumber(hiddenLivingRelationship.id), { isHidden: !hiddenLivingRelationship.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenLivingRelationship(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LIVING_RELATIONSHIP] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenLivingRelationship?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenLivingRelationship}
      onClose={() => setHiddenLivingRelationship(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenLivingRelationship?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
