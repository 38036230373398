import { removeAxiosAuth, setAxiosAuth } from "@/utils/api";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

/**
 * Sets the access and refresh tokens in cookies and updates the Axios authentication configuration.
 *
 * @param {string} accessToken - The access token to set.
 * @param {string} refreshToken - The refresh token to set.
 */
export const setToken = (accessToken: string, refreshToken: string) => {
  Cookies.set("admin-token", accessToken);
  Cookies.set("admin-refresh-token", refreshToken);
  setAxiosAuth();
};

/**
 * Removes the access and refresh tokens from cookies and updates the Axios authentication configuration.
 */
export const removeToken = () => {
  Cookies.remove("admin-token");
  Cookies.remove("admin-refresh-token");
  removeAxiosAuth();
};

/**
 * Verifies if a given token is still valid based on its expiration time.
 *
 * @param {string} token - The token to verify.
 * @returns {boolean} - `true` if the token is still valid, `false` otherwise.
 */
export function verifyToken(token: string) {
  try {
    const decoded = jwtDecode<{ exp: number }>(token);
    return decoded.exp > new Date().getTime() / 1000;
  } catch (e) {
    return false;
  }
}
