import { ContactTypeForm } from "@/features/contact-type/components/ContactTypeForm";
import { Stack } from "@mui/material";
import { FC } from "react";

type TContactTypeCreatePageProps = {};

export const ContactTypeCreatePage: FC<TContactTypeCreatePageProps> = () => {
  return (
    <Stack gap={4}>
      <ContactTypeForm />
    </Stack>
  );
};
