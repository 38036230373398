import { TGetMessageOutputDto } from "@/api/services/admin/conversation/dtos/get-message-list-by-conversation.dto";
import { EVENTS } from "@/constants/socket";
import { useRoom } from "@/features/chat/hooks/useRoom";
import { useRoomList } from "@/features/chat/hooks/useRoomList";
import { TRoom } from "@/features/chat/types/room";
import { formatMessageDto } from "@/features/chat/utils/message";
import { useSocket } from "@/providers/SocketProvider";
import { FCC } from "@/types/common";
import { createContext, useContext, useEffect } from "react";
import { MessageType } from "react-chat-elements";

type TChatContext = {
  roomList: {
    oldRooms: TRoom[];
    newRooms: TRoom[];
    hasNextPage: boolean;
    isFetching: boolean;
    fetchNextPage: () => Promise<void>;
  };
  currentRoom: {
    roomData: TRoom | null;
    oldMessages: MessageType[];
    newMessages: MessageType[];
    hasNextPage: boolean;
    isFetching: boolean;
    fetchNextPage: () => Promise<void>;
    sendMsg: (msg: string) => Promise<void>;
    readMsg: () => Promise<void>;
  };
};

const ChatContext = createContext<TChatContext>({} as TChatContext);

export const ChatProvider: FCC = ({ children }) => {
  const roomList = useRoomList();
  const currentRoom = useRoom();
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on(EVENTS.CONVERSATION.NEW_MESSAGE, (receivedMessage: TGetMessageOutputDto) => {
        window.dispatchEvent(new CustomEvent(EVENTS.CONVERSATION.NEW_MESSAGE, { detail: formatMessageDto(receivedMessage) }));
      });

      return () => {
        socket.off(EVENTS.CONVERSATION.NEW_MESSAGE);
      };
    }
  }, [socket]);

  const contextValue = {
    roomList,
    currentRoom,
  };

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};

export const ChatConsumer = ChatContext.Consumer;

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within ChatProvider");
  }
  return context;
};
