import { TGetConvertRequestListReqDto, TGetConvertRequestListResDto } from "@/api/services/admin/convert-request/dtos/get-convert-request-list.dto";
import { TGetConvertRequestResDto } from "@/api/services/admin/convert-request/dtos/get-convert-request.dto";
import {
  TUpdateConvertRequestErrorReqDto,
  TUpdateConvertRequestCompleteResDto,
  TUpdateConvertRequestErrorResDto,
} from "@/api/services/admin/convert-request/dtos/update-convert-request-error.dto";
import {
  TUploadConvertRequestXmlReqDto,
  TUploadConvertRequestXmlResDto,
} from "@/api/services/admin/convert-request/dtos/upload-convert-request-xml.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiSortsReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchConvertDetail = async (convertRequestId: string): Promise<TGetConvertRequestResDto> => {
  const rs = await clientApi.get<TGetConvertRequestResDto>(`/convert-requests/${convertRequestId}`);
  return rs.data;
};

export const useConvertDetail = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.CONVERT.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchConvertDetail(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchConvertList = async (req: TGetConvertRequestListReqDto): Promise<TGetConvertRequestListResDto> => {
  const url = createUrlWithParams("/convert-requests", req);
  const { data } = await clientApi.get<TGetConvertRequestListResDto>(url);
  return data;
};

export const useConvertList = (status: string | string[], sorts?: TApiSortsReq[]) => {
  const pagReq = usePagination();
  const convertReq = { ...pagReq, status, sorts };
  return useQuery({
    queryKey: [QUERY_KEYS.CONVERT.FETCH_LIST, convertReq],
    queryFn: () => fetchConvertList(convertReq),
    enabled: Boolean(status),
  });
};

export const updateConvertRequestFile = async (
  convertRequestId: number,
  payload: TUploadConvertRequestXmlReqDto,
): Promise<TUploadConvertRequestXmlResDto> => {
  const rs = await clientApi.put<TUploadConvertRequestXmlResDto>(`/convert-requests/${convertRequestId}/upload-file`, payload);
  return rs.data;
};

export const updateConvertRequestComplete = async (convertRequestId: number): Promise<TUpdateConvertRequestCompleteResDto> => {
  const rs = await clientApi.put<TUpdateConvertRequestCompleteResDto>(`/convert-requests/${convertRequestId}/update-complete`);
  return rs.data;
};

export const updateConvertRequestError = async (
  convertRequestId: number,
  payload: TUpdateConvertRequestErrorReqDto,
): Promise<TUpdateConvertRequestErrorResDto> => {
  const rs = await clientApi.put<TUpdateConvertRequestErrorResDto>(`/convert-requests/${convertRequestId}/update-error`, payload);
  return rs.data;
};
