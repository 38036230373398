export const PATTERN = {
  URL: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/,
  EMAIL: /^[a-zA-Z0-9._+-]+@([\w-]+\.)+[\w-]+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]+$/,
  NEW_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]+$/,
  NUMBER: /^\d+(\.\d+)?$/,
  LETTER: /^[a-zA-Z\s\u0370-\u03FF\u0400-\u04FF\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]+$/,
  LATIN_ONLY: /^[a-zA-Z\s]+$/,
  UPPERCASE_ALPHANUMERIC_CHARACTERS_ONLY: /^[A-Z0-9]+$/,
  NUMBER_ONLY: /^[0-9]+$/,
  KATAKANA_AND_SPACE: /^[\u30A0-\u30FF\s]+$/,
  KANJI: /[\u4e00-\u9faf\u3400-\u4dbf]/,
  HALF_WIDTH_CHARACTER: /[\u0020-\u007E\uFF61-\uFFDC\uFFE8-\uFFEE]/,
  IMMI_PASSWORD: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$%&=@_#*+\-?!)])[a-zA-Z\d$%&=@_#*+\-?!)]{8,32}$/,
  IMMI_ID: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$%&=@_#*+\-?!)])[a-zA-Z\d$%&=@_#*+\-?!)]{6,20}$/,
} as const;
