import { TMasterJobType } from "@/api/entities/master-job-type.entity";
import { updateMajorApi } from "@/api/services/global/master";
import { TGetMajorListOutputDto } from "@/api/services/global/master/dtos/jobType/get-master-major-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { UpdateMajorFormTable } from "@/features/master-data/components/JobType/UpdateMajorFormTable";
import { masterDataUpdateMajorSchema } from "@/features/master-data/schema/masterDataJobTypeSchema";
import { TMasterMajorUpdateForm } from "@/features/master-data/types/masterDataJobTypeForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

type TUpdateMajorFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedMajor?: TGetMajorListOutputDto;
};

export const UpdateMajorFormModal: FC<TUpdateMajorFormModalProps> = ({ onClose, isOpen, selectedMajor }) => {
  const queryClient = useQueryClient();

  const [selectedJobTypeRows, setSelectedJobTypeRows] = useState<TMasterJobType[]>(selectedMajor?.jobTypes ?? []);

  const methods = useForm<TMasterMajorUpdateForm>({
    resolver: yupResolver(masterDataUpdateMajorSchema),
    mode: "onBlur",
    defaultValues: {
      majorName: selectedMajor?.majorName,
      coMajorRenewVisaCsvCode: selectedMajor?.coMajorRenewVisaCsvCode,
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterMajorUpdateForm) => {
    try {
      const rs = await updateMajorApi({
        majors: [
          {
            id: toNumber(selectedMajor?.id),
            majorName: data.majorName,
            coMajorRenewVisaCsvCode: data.coMajorRenewVisaCsvCode,
            jobTypes: selectedJobTypeRows.map((item) => ({
              id: toNumber(item.id),
              cpJobRenewVisaCsvCode: item.cpJobRenewVisaCsvCode,
              jobName: item.jobName,
            })),
          },
        ],
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataUpdateMajorSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="majorName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="coMajorRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">国籍</Typography>
              <UpdateMajorFormTable
                selectedJobTypeRows={selectedJobTypeRows}
                setSelectedJobTypeRows={setSelectedJobTypeRows}
                existingMajorId={toNumber(selectedMajor?.id)}
                existingMajorName={selectedMajor?.majorName ?? EMPTY_STR.TEXT}
                existingCoMajorRenewVisaCsvCode={selectedMajor?.coMajorRenewVisaCsvCode ?? EMPTY_STR.TEXT}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
