import { TMasterSpecificBusinessClassification } from "@/api/entities/master-specific-business-classification.entity";
import { createBusinessClassificationApi, updateBusinessClassificationApi, useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { specificBusinessClassificationSchema } from "@/features/master-data/schema/masterDataSpecificIndustrySchema";
import { TBusinessClassificationForm } from "@/features/master-data/types/masterDataSpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";

type TBusinessClassificationFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedBusinessClassification?: TMasterSpecificBusinessClassification;
  setSelectedBusinessClassificationRows: React.Dispatch<React.SetStateAction<TMasterSpecificBusinessClassification[]>>;
  existingSpecificIndustryId: number;
  existingCompanySpecificIndustryId: number;
  existingRegularAnyTimeSpecificIndustry: string;
  existingRenewVisaSpecificIndustry: string;
  existingCompanySpecificIndustry: string;
  existingResidenceStatus: string;
  existingFgSpecifiedIndustryRenewVisaCsvCode: string;
};

export const BusinessClassificationFormModal: FC<TBusinessClassificationFormModalProps> = ({
  onClose,
  isOpen,
  setSelectedBusinessClassificationRows,
  selectedBusinessClassification,
  existingFgSpecifiedIndustryRenewVisaCsvCode,
  existingRenewVisaSpecificIndustry,
  existingCompanySpecificIndustryId,
  existingResidenceStatus,
  existingCompanySpecificIndustry,
  existingSpecificIndustryId,
  existingRegularAnyTimeSpecificIndustry,
}) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      specificBusinessClassificationName: selectedBusinessClassification
        ? selectedBusinessClassification.specificBusinessClassificationName
        : undefined,
      anyTimeSpecificBusinessClassificationName: selectedBusinessClassification
        ? selectedBusinessClassification.anyTimeSpecificBusinessClassificationName
        : undefined,
      companySpecificBusinessClassificationId: selectedBusinessClassification
        ? selectedBusinessClassification.companySpecificBusinessClassificationId.toString()
        : undefined,
      specificBusinessClassificationAnyTimeCsvCode: selectedBusinessClassification
        ? selectedBusinessClassification.specificBusinessClassificationAnyTimeCsvCode
        : undefined,
      fhSpecificBusinessClassificationRenewVisaCsvCode: selectedBusinessClassification
        ? selectedBusinessClassification.fhSpecificBusinessClassificationRenewVisaCsvCode
        : undefined,
    }),
    [selectedBusinessClassification],
  );

  const methods = useForm<TBusinessClassificationForm>({
    resolver: yupResolver(specificBusinessClassificationSchema),
    mode: "onBlur",
    defaultValues,
  });
  const { control } = methods;

  const {} = useWatch({
    control,
  });

  const onSubmit = async (data: TBusinessClassificationForm) => {
    if (
      !existingFgSpecifiedIndustryRenewVisaCsvCode ||
      !existingRenewVisaSpecificIndustry ||
      !existingSpecificIndustryId ||
      !existingRegularAnyTimeSpecificIndustry ||
      !existingCompanySpecificIndustry ||
      !existingResidenceStatus
    )
      return;
    try {
      if (selectedBusinessClassification) {
        const rs = await updateBusinessClassificationApi(selectedBusinessClassification.id, {
          specificIndustryId: toNumber(existingSpecificIndustryId),
          specificBusinessClassificationName: data.specificBusinessClassificationName,
          anyTimeSpecificBusinessClassificationName: data.anyTimeSpecificBusinessClassificationName,
          companySpecificBusinessClassificationId: toNumber(data.companySpecificBusinessClassificationId),
          specificBusinessClassificationAnyTimeCsvCode: data.specificBusinessClassificationAnyTimeCsvCode,
          fhSpecificBusinessClassificationRenewVisaCsvCode: data.fhSpecificBusinessClassificationRenewVisaCsvCode,
        });
        setSelectedBusinessClassificationRows((prev) => {
          const arr = prev.map((item) => (item.id === rs.data.id ? rs.data : item));
          return arr;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createBusinessClassificationApi({
          specificIndustryId: existingSpecificIndustryId,
          specificBusinessClassificationName: data.specificBusinessClassificationName,
          anyTimeSpecificBusinessClassificationName: data.anyTimeSpecificBusinessClassificationName,
          companySpecificBusinessClassificationId: toNumber(data.companySpecificBusinessClassificationId),
          specificBusinessClassificationAnyTimeCsvCode: data.specificBusinessClassificationAnyTimeCsvCode,
          fhSpecificBusinessClassificationRenewVisaCsvCode: data.fhSpecificBusinessClassificationRenewVisaCsvCode,
        });
        setSelectedBusinessClassificationRows((prev) => [...prev, rs.data]);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      onClose();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };
  const { isLoading: isLoadingCompanySpecificIndustry, getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry } =
    useGetCompanySpecificIndustryList();

  return (
    <Modal
      width={1080}
      title={selectedBusinessClassification ? "編集" : "追加"}
      isOpen={isOpen}
      enableFooter={false}
      onClose={onClose}
      enableCloseIcon={false}
    >
      <Form methods={methods} onSubmit={onSubmit} schema={specificBusinessClassificationSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField label="特定産業分野（会社情報用）">
              <Typography variant="body14">{existingCompanySpecificIndustry}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="在留資格（外国人）">
              <Typography variant="body14">{existingResidenceStatus}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="特定産業分野（定期届出&随時届出の表記）">
              <Typography variant="body14">{existingRegularAnyTimeSpecificIndustry}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="特定産業分野（期間更新の表記）">
              <Typography variant="body14">{existingRenewVisaSpecificIndustry}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="期間更新CSVのコード（列FG）">
              <Typography variant="body14">{existingFgSpecifiedIndustryRenewVisaCsvCode}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificBusinessClassificationId">
              <Select
                loading={isLoadingCompanySpecificIndustry}
                options={getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry(existingCompanySpecificIndustryId)}
              />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="specificBusinessClassificationName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="fhSpecificBusinessClassificationRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="anyTimeSpecificBusinessClassificationName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="specificBusinessClassificationAnyTimeCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
