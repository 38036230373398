export enum EAnyTimeDocumentType {
  EMPLOYMENT_CONTRACT_MODIFICATION = "employment_contract_modification",
  SUPPORT_PLAN_MODIFICATION = "support_plan_modification",
  SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION = "support_outsourcing_contract_modification",
}

export enum EModifyContractTerminateReason {
  EMPLOYMENT_CONTRACT_TERMINATION = "end_contract",
  CONVENIENT_FOR_COMPANY = "convenient_for_company",
  CONVENIENT_FOR_EMPLOYEE = "convenient_for_employee",
}

export enum EEmploymentContractModificationType {
  MODIFY_CONTRACT = "modify_contract",
  TERMINATE_CONTRACT = "terminate_contract",
  RENEW_CONTRACT = "renew_contract",
}

export enum EModifyContractTerminateReasonForCompany {
  BUSINESS_CONVENIENCE = "business_convenience",
  STANDARD_NON_COMPLIANCE = "standard_non_compliance",
  COMPANY_OWNER_DEATH = "company_owner_death",
  OTHER = "other",
}

export enum EModifyContractTerminateReasonForEmployee {
  EMPLOYEE_DEATH = "business_convenience",
  ILLNESS_INJURY = "illness_injury",
  MISSING_EMPLOYEE = "missing_employee",
  SERIOUS_DUTY_DISMISSAL = "serious_duty_dismissal",
  VOLUNTARY_RESIGNATION = "voluntary_resignation",
  OTHER = "other",
}
