import { uploadFileApi } from "@/api/services/global/file";
import { IconClose } from "@/assets/icons";
import { FileItem } from "@/components/elements/FileItem";
import { IconBox } from "@/components/elements/IconBox";
import { Modal } from "@/components/elements/Modal";
import { Progress } from "@/components/elements/Progress";
import { FILE_ERROR_MESSAGE, FILE_STATUS, TFileUploadFor } from "@/constants/file";
import { useDialog } from "@/hooks/useDialog";
import { divider, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { TExtendFile } from "@/types/file";
import { getExtensionsOfMimeTypes, isFileError, TFileRules } from "@/utils/file";
import { replaceStr } from "@/utils/string";
import { toast } from "@/utils/toast";
import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Id } from "react-toastify";

type TAutoUploadProps = {
  files: TExtendFile[];
  rules?: TFileRules;
  onFinishUpload?: (files: TExtendFile[], newFiles: TExtendFile[]) => void;
  onCancelUpload?: () => void;
  uploadFor: TFileUploadFor;
  multiple?: boolean;
};

export const AutoUpload: FC<TAutoUploadProps> = ({ files, rules = {}, uploadFor, multiple = true, onFinishUpload, onCancelUpload }) => {
  const toastId = useRef<Id>();
  const [uploadFiles, setUploadFiles] = useState<TExtendFile[]>([]);
  const finishedUploadFiles = useRef<TExtendFile[]>([]);
  const confirmClose = useDialog();

  const handleSetUploadFiles = async () => {
    const newFiles = files.map((file) => {
      if (file.status !== FILE_STATUS.OK) return file;
      try {
        return Object.assign(file, { status: FILE_STATUS.IN_PROGRESS });
      } catch (error) {
        return Object.assign(file, { status: FILE_STATUS.ERROR });
      }
    });
    if (!newFiles.length) return;
    if (multiple === false) {
      setUploadFiles([newFiles[0]]);
    } else {
      setUploadFiles([uploadFiles, newFiles.flat()].flat());
    }
  };

  useEffect(() => {
    handleSetUploadFiles();
  }, [files]);

  const updateFile = (file: TExtendFile) => {
    setUploadFiles((cur) => {
      const index = cur.findIndex(({ id }) => file.id === id);
      if (index === -1) return cur;
      cur[index] = file;
      return [...cur];
    });
  };

  const handleClose = () => {
    if (toastId.current) {
      toast.close(toastId.current);
      toastId.current = undefined;
      setUploadFiles([]);
      finishedUploadFiles.current = [];
    }
  };

  const handleCloseUploadPopup = async () => {
    if (uploadFiles.some(({ status }) => status === FILE_STATUS.IN_PROGRESS)) {
      const isConfirmed = await confirmClose.show();
      if (isConfirmed) {
        handleClose?.();
        onCancelUpload?.();
        return;
      }
      return;
    }
    handleClose?.();
    onCancelUpload?.();
  };

  const handleAutoUpload = () => {
    if (!uploadFiles.length) return;
    if (toastId.current) {
      toast.update(
        toastId.current,
        <UploadPopup files={uploadFiles} rules={rules} updateFile={updateFile} onClose={handleCloseUploadPopup} uploadFor={uploadFor} />,
        {
          autoClose: false,
          position: "bottom-right",
          closeButton: () => null,
        },
      );
    } else {
      toastId.current = toast.show(
        "default",
        <UploadPopup files={uploadFiles} rules={rules} updateFile={updateFile} onClose={handleCloseUploadPopup} uploadFor={uploadFor} />,
        { autoClose: false, position: "bottom-right", closeButton: () => null },
      );
    }
  };

  useEffect(() => {
    handleAutoUpload();
  }, [uploadFiles]);

  useEffect(() => {
    return () => {
      if (toastId.current) {
        toast.close(toastId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!uploadFiles.length) return;
    checkUploadProcess();
  }, [uploadFiles]);

  const checkUploadProcess = () => {
    const isFinished = !uploadFiles.some((file) => file.status === FILE_STATUS.IN_PROGRESS);
    if (isFinished) {
      const newFinishedFiles = uploadFiles.filter(({ id }) => !finishedUploadFiles.current.some((file) => file.id === id));
      onFinishUpload?.(uploadFiles, newFinishedFiles);
      finishedUploadFiles.current = uploadFiles;
    }
  };

  return <ConfirmCloseUploadPopup open={confirmClose.open} onClose={confirmClose.cancel} onOk={confirmClose.confirm} />;
};

type TUploadPopupProps = {
  files: TExtendFile[];
  rules: TFileRules;
  uploadFor: TFileUploadFor;
  updateFile: (file: TExtendFile) => void;
  onClose: () => void;
};

const UploadPopup: FC<TUploadPopupProps> = ({ files, rules, onClose, updateFile, uploadFor }) => {
  const renderTitle = useMemo(() => {
    const inprogressFiles = files.filter((file) => file.status === FILE_STATUS.IN_PROGRESS);
    if (inprogressFiles.length) return `${inprogressFiles.length}件のファイルをアップロード中です。`;
    const completedFiles = files.filter((file) => file.status === FILE_STATUS.OK);
    if (completedFiles.length) return `${completedFiles.length}件のファイルのアップロードが完了しました。`;
    const errorFiles = files.filter((file) =>
      [FILE_STATUS.ERROR, FILE_STATUS.ERROR_ACCEPT_FILES, FILE_STATUS.ERROR_MAX_SIZE, FILE_STATUS.ERROR_MIN_SIZE].includes(file.status),
    );
    if (errorFiles.length) return `${errorFiles.length}ファイルのアップロードに失敗しました。`;
  }, [files]);

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body14">{renderTitle}</Typography>
        <IconBox onClick={onClose}>
          <IconClose fontSize={24} />
        </IconBox>
      </Stack>
      <Box mt={1} border="1px solid" borderColor={divider.middle} borderRadius={rounded.sm} p={2}>
        {files.map((file) => (
          <FileProgress key={file.id} file={file} rules={rules} updateFile={updateFile} uploadFor={uploadFor} />
        ))}
      </Box>
    </Stack>
  );
};

type TFileProgressProps = {
  file: TExtendFile;
  rules: TFileRules;
  updateFile: (file: TExtendFile) => void;
  uploadFor: TFileUploadFor;
};

const FileProgress: FC<TFileProgressProps> = ({ file, rules, updateFile, uploadFor }) => {
  const [uploadProgress, setUploadProgress] = useState({ loaded: 0, total: 1 });

  const handleUploadFile = async () => {
    if (file.status !== FILE_STATUS.IN_PROGRESS) return;
    try {
      const rs = await uploadFileApi({ file, uploadFor }, ({ loaded, total }) => {
        setUploadProgress({ loaded, total: total ?? 1 });
      });

      updateFile(Object.assign(file, { status: FILE_STATUS.OK, filePath: rs.data.filePath }));
    } catch (error) {
      updateFile(Object.assign(file, { status: FILE_STATUS.ERROR }));
      return;
    }
  };

  useEffect(() => {
    handleUploadFile();
  }, []);

  const progressPercent = useMemo(() => {
    if (file.status === FILE_STATUS.OK) return 100;
    return (uploadProgress.loaded / uploadProgress.total) * 100;
  }, [uploadProgress, file.status]);

  return (
    <Stack gap={0.5}>
      <Stack direction="row" gap={2} alignItems="center">
        <FileItem file={file} flex={1} />
        <Box width={140} minWidth={140}>
          <Progress variant="determinate" value={progressPercent} />
        </Box>
        <Typography width={42} minWidth={42} variant="body14">
          {isFileError(file) ? "中断" : `${progressPercent.toFixed(0)}%`}
        </Typography>
      </Stack>
      {isFileError(file) && (
        <Typography variant="body14" color={text.error}>
          {replaceStr(FILE_ERROR_MESSAGE[file.status], {
            maxSize: rules.maxSize,
            minSize: rules.minSize,
            extensions: getExtensionsOfMimeTypes(rules.acceptMimeTypes ?? []).join("、"),
          })}
        </Typography>
      )}
    </Stack>
  );
};

type TConfirmCloseUploadPopupProps = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};
const ConfirmCloseUploadPopup: FC<TConfirmCloseUploadPopupProps> = ({ open, onOk, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい">
      <Stack mb={2}>
        <Typography>入力したデータがなくなりますが、よろしいですか。</Typography>
      </Stack>
    </Modal>
  );
};
