import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { registerSchema } from "@/features/auth/schema/registerSchema";
import { registerApi } from "@/features/auth/services/registerApi";
import { TRegisterForm } from "@/features/auth/types/registerForm";
import { TRegisterQueryParam } from "@/features/auth/types/registerQueryParam";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { omit } from "@/utils/object";
import { getQueryFromURL } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const initValue: TRegisterForm = {
  email: "",
  adminRole: "",
  confirmPassword: "",
  fullName: "",
  password: "",
};

export const RegisterForm = () => {
  const query = getQueryFromURL<TRegisterQueryParam>();
  const toast = useToast();
  const navigate = useNavigate();
  const methods = useForm<TRegisterForm>({
    defaultValues: { ...initValue, ...query },
    resolver: yupResolver(registerSchema),
    mode: "onChange",
  });
  const { control } = methods;

  const onSubmit = async (data: TRegisterForm) => {
    if (!query) return null;
    try {
      await registerApi({ ...omit(data, "confirmPassword"), resetToken: query?.resetToken });
      navigate(AppRoutes.login);
      toast.success(API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={registerSchema}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormField control={control} name="fullName" label="氏名">
            <Input />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="email" label="メールアドレス">
            <Input readonly />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="adminRole" label="担当">
            <Select enumName="EAdminRole" />
          </FormField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormField control={control} name="password" label="パスワード">
            <Input type="password" />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="confirmPassword" label="パスワードの認証">
            <Input type="password" />
          </FormField>
        </Grid>
      </Grid>

      <Box textAlign="center" mt={5}>
        <Button type="submit">保存</Button>
      </Box>
    </Form>
  );
};
