import { useConvertList } from "@/api/services/admin/convert-request";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { Pagination } from "@/components/elements/Pagination";
import { TopPage } from "@/components/elements/TopPage";
import { CONVERT_STATUS } from "@/constants/enum";
import { ConvertTable } from "@/features/convert/component/ConvertTable";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";

export const ConvertHistoryListPage = () => {
  const { data: convertListResult, isLoading } = useConvertList(
    [CONVERT_STATUS.COMPLETE, CONVERT_STATUS.ERROR, CONVERT_STATUS.CANCEL],
    ["cr.createdAt|DESC"],
  );

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <TopPage title="過去の変換依頼一覧" backUrl={AppRoutes.convert} />
      <Stack gap={2}>
        <Pagination totalPage={1} />
        {convertListResult && <ConvertTable isLoading={isLoading} data={convertListResult ?? []} />}
      </Stack>
    </>
  );
};
