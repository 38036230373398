import { valueOf } from "@/types/common";

export const FILE_STATUS = {
  IN_PROGRESS: 0,
  OK: 1,
  ERROR: 2,
  ERROR_MIN_SIZE: 3,
  ERROR_MAX_SIZE: 4,
  ERROR_ACCEPT_FILES: 5,
};

export type TFileStatus = valueOf<typeof FILE_STATUS>;

export const FILE_ERROR_MESSAGE = {
  [FILE_STATUS.ERROR]: "FILE is invalid",
  [FILE_STATUS.ERROR_MAX_SIZE]: "ファイルサイズは{{maxSize}}MB以内でお願いします。",
  [FILE_STATUS.ERROR_MIN_SIZE]: "lt {{minSize}}MB。",
  [FILE_STATUS.ERROR_ACCEPT_FILES]: "ファイル形式は{{extensions}}にてお願いします。",
};

export const FILE_UPLOAD_FOR = {
  INFORMATION_FILE: "INFORMATION_FILE",
  CONVERT_REQUEST_FILE: "CONVERT_REQUEST_FILE",
};

export type TFileUploadFor = valueOf<typeof FILE_UPLOAD_FOR>;

export const MIME_TYPES = {
  IMAGE: ["image/jpg", "image/jpeg", "image/gif", "image/png"],
  HEIC: ["image/heic", "image/heif"],
  PDF: ["application/pdf"],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/xml",
    "text/xml",
    "application/vnd.oasis.opendocument.spreadsheet",
    "text/csv",
  ],
  WORD: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/msword",
    "application/x-ole-storage",
  ],
  TXT: ["text/plain"],
  CSV: ["text/csv"],
  XML: ["application/xml", "text/xml"],
};

export const EXTENSION_NAMES: Record<string, string> = {
  IMAGE: "Word",
  HEIC: "HEIC",
  PDF: "PDF",
  EXCEL: "Excel",
  WORD: "Word",
  TXT: "Txt",
  CSV: "CSV",
  XML: "XML",
};

export const EXTENSIONS = {
  IMAGE: [".jpg", ".jpeg", ".png", ".gif"],
  DOC: [".doc", ".docx"],
  PDF: [".pdf"],
  EXCEL: [".xlsx", ".csv"],
  XML: [".xml"],
};
