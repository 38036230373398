import { validator } from "@/utils/validator";

export const maintenanceSchema = validator.object().shape({
  maintenanceMode: validator.string().isSelection().emptyToNull().label("メンテナンスモード").required(),
  modeStartDateTime: validator.mixed().isSelection().isDayjs().label("モード切替日時").notRequired().nullable().optional(),
  modeEndDateTime: validator
    .mixed()
    .isSelection()
    .isDayjs()
    .isAfterDateTime("modeStartDateTime", { startLabel: "モード切替日時", endLabel: "終了予定日時" })
    .label("終了予定日時")
    .notRequired()
    .nullable()
    .optional(),
});
