import { TMasterResidencePeriod } from "@/api/entities/master-residence-period.entity";
import { createResidencePeriodApi, updateResidencePeriodApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { residencePeriodSchema } from "@/features/master-data/schema/masterDataResidencePeriodSchema";
import { TResidencePeriodForm } from "@/features/master-data/types/masterDataResidencePeriodForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TResidencePeriodFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedResidencePeriod?: TMasterResidencePeriod;
  existingResidenceStatusId: number;
  existingResidenceStatus: string;
  existingResidenceStatusRenewVisaCsvCode: string;
  setSelectedResidencePeriodRows: React.Dispatch<React.SetStateAction<TMasterResidencePeriod[]>>;
};

export const ResidencePeriodFormModal: FC<TResidencePeriodFormModalProps> = ({
  onClose,
  isOpen,
  existingResidenceStatusId,
  existingResidenceStatus,
  existingResidenceStatusRenewVisaCsvCode,
  setSelectedResidencePeriodRows,
  selectedResidencePeriod,
}) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      residencePeriod: selectedResidencePeriod ? selectedResidencePeriod.residencePeriod : undefined,
      residencePeriodRenewVisaCsvCode: selectedResidencePeriod ? selectedResidencePeriod.residencePeriodRenewVisaCsvCode : undefined,
      desiredResidencePeriod: selectedResidencePeriod ? selectedResidencePeriod.desiredResidencePeriod : undefined,
      desiredResidencePeriodRenewVisaCsvCode: selectedResidencePeriod ? selectedResidencePeriod.desiredResidencePeriodRenewVisaCsvCode : undefined,
    }),
    [selectedResidencePeriod],
  );

  const methods = useForm<TResidencePeriodForm>({
    resolver: yupResolver(residencePeriodSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TResidencePeriodForm) => {
    if (!existingResidenceStatusId || !existingResidenceStatus || !existingResidenceStatusRenewVisaCsvCode) return;
    try {
      if (selectedResidencePeriod) {
        const rs = await updateResidencePeriodApi(selectedResidencePeriod.id, {
          residenceStatusId: toNumber(existingResidenceStatusId),
          residencePeriod: data.residencePeriod,
          residencePeriodRenewVisaCsvCode: data.residencePeriodRenewVisaCsvCode,
          desiredResidencePeriod: data.desiredResidencePeriod,
          desiredResidencePeriodRenewVisaCsvCode: data.desiredResidencePeriodRenewVisaCsvCode,
        });
        setSelectedResidencePeriodRows((prev) => {
          const arr = prev.map((item) => (item.id === rs.data.id ? rs.data : item));
          return arr;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createResidencePeriodApi({
          residenceStatusId: existingResidenceStatusId,
          desiredResidencePeriod: data.desiredResidencePeriod,
          desiredResidencePeriodRenewVisaCsvCode: data.desiredResidencePeriodRenewVisaCsvCode,
          residencePeriod: data.residencePeriod,
          residencePeriodRenewVisaCsvCode: data.residencePeriodRenewVisaCsvCode,
        });
        setSelectedResidencePeriodRows((prev) => [...prev, rs.data]);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      onClose();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal
      width={760}
      title={selectedResidencePeriod ? "編集" : "追加"}
      isOpen={isOpen}
      enableFooter={false}
      onClose={onClose}
      enableCloseIcon={false}
    >
      <Form methods={methods} onSubmit={onSubmit} schema={residencePeriodSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField label="現に有する在留資格">
              <Typography variant="body14">{existingResidenceStatus}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="有する在留資格の期間更新CSVのコード">
              <Typography variant="body14">{existingResidenceStatusRenewVisaCsvCode}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residencePeriod">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residencePeriodRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="desiredResidencePeriod">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="desiredResidencePeriodRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
