import { TFireEvent } from "@/types/common";
import { dayjs } from "@/utils/dayjs";
import { clearObject } from "@/utils/object";
import { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { ObjectSchema } from "yup";
import crypto, { CipherKey } from "crypto";

/**
 * Generates a new UUID (Universally Unique Identifier).
 *
 * @returns {string} - The generated UUID.
 */
export const makeUuid = (): string => {
  return uuidv4();
};

/**
 * Gets the start date of a given quarter in a specific year.
 *
 * @param {object} resource - The resource containing the year and quarter.
 * @param {number} resource.year - The year of the quarter.
 * @param {number} resource.quarter - The quarter number (1-4).
 * @returns {Dayjs} - The start date of the quarter.
 */
export const getStartDateOfQuarter = (resource: { year: number; quarter: number }): Dayjs => {
  const { quarter, year } = resource;
  const quarterStartMonth = (quarter - 1) * 3;

  return dayjs(new Date(year, quarterStartMonth, 1));
};

/**
 * Gets the end date of a given quarter in a specific year.
 *
 * @param {object} resource - The resource containing the year and quarter.
 * @param {number} resource.year - The year of the quarter.
 * @param {number} resource.quarter - The quarter number (1-4).
 * @returns {Dayjs} - The end date of the quarter.
 */
export const getEndDateOfQuarter = (resource: { year: number; quarter: number }): Dayjs => {
  const { quarter, year } = resource;
  const quarterEndMonth = quarter * 3;

  return dayjs(new Date(year, quarterEndMonth, 1)).subtract(1, "day");
};

/**
 * Converts a hex color code to an RGBA color string.
 *
 * @param {string} hexColor - The hex color code.
 * @param {number} alpha - The alpha (transparency) value (0 to 1).
 * @returns {string} - The RGBA color string.
 */
export const hexToRgba = (hexColor: string, alpha: number): string => {
  hexColor = hexColor.replace("#", "");
  // Convert hexadecimal to RGB
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Validate alpha value
  alpha = Math.min(1, Math.max(0, alpha));

  // Format and return RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

/**
 * Removes duplicate elements from an array.
 *
 * @template T
 * @param {T[]} arr - The array to process.
 * @returns {T[]} - A new array with unique elements.
 */
export const uniqueArray = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

/**
 * Pauses execution for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to sleep.
 * @returns {Promise<void>} - A promise that resolves after the specified time.
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

/**
 * Validates and returns the cleaned form values based on a given schema.
 *
 * @template T
 * @param {T} values - The form values to validate.
 * @param {ObjectSchema<object>} schema - The validation schema.
 * @returns {Promise<Partial<T>>} - A promise that resolves with the validated form values.
 * @throws {Error} - Throws an error if validation fails.
 */
export const getFormValues = async <T extends object>(values: T, schema: ObjectSchema<object>): Promise<Partial<T>> => {
  try {
    const data = await schema.validate(clearObject(values));
    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * Extracts and returns text content from an HTML string.
 *
 * @param {string} html - The HTML string to extract text from.
 * @returns {string} - The extracted text content.
 */
export const extractTextFromHTML = (html: string): string => {
  const span = document.createElement("span");
  span.innerHTML = html;
  span.remove();
  return span.textContent || span.innerText;
};

export const isFireEvent = <T>(event: Event): event is TFireEvent<T> => {
  if ("detail" in event) return true;
  return false;
};

export const encrypt = (text: string, secretKey: string) => {
  const iv = crypto.randomBytes(16);
  const secret = Buffer.from(secretKey, "utf-8").slice(-32);
  const cipher = crypto.createCipheriv("aes-256-cbc", secret as unknown as CipherKey, iv as unknown as Uint8Array);
  let encrypted = cipher.update(text, "utf8", "hex");
  encrypted += cipher.final("hex");
  return `${iv.toString("hex")}:${encrypted}`;
};

export const decrypt = (encryptedText: string, secretKey: string) => {
  const [iv, encrypted] = encryptedText.split(":");
  if (!iv || !encrypted) return encryptedText;
  if (iv.length < 16) return encryptedText;
  if (encrypted.length < 32) return encryptedText;
  const secret = Buffer.from(secretKey, "utf-8").slice(-32);
  const decipher = crypto.createDecipheriv("aes-256-cbc", secret as unknown as CipherKey, Buffer.from(iv, "hex") as unknown as Uint8Array);
  let decrypted = decipher.update(encrypted, "hex", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
};
