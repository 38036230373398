import {
  AdminUserRoutes,
  AppRoutes,
  ChatRoutes,
  CompanyRoutes,
  ContactRoutes,
  ConvertRoutes,
  MasterDataRoutes,
  SystemSettingRoutes,
  informationRoutes,
} from "@/routes/config";
import { TMenu } from "@/types/menu";

export const menus: TMenu[] = [
  {
    text: "自社支援企業の管理",
    children: [
      {
        text: "受け入れ企業管理",
        path: AppRoutes.company,
        relatedRoutes: CompanyRoutes,
      },
    ],
  },
  {
    text: "システム管理",
    children: [
      {
        text: "irohana担当者",
        path: AppRoutes.adminUser,
        relatedRoutes: AdminUserRoutes,
      },
      { text: "提出形式の変換依頼", path: AppRoutes.convert, relatedRoutes: ConvertRoutes },
      { text: "お知らせ配信", path: AppRoutes.information, relatedRoutes: informationRoutes },
      { text: "マスターデータ管理", path: AppRoutes.masterData, relatedRoutes: MasterDataRoutes },
      { text: "お問い合わせ管理", path: AppRoutes.unresolvedContact, relatedRoutes: ContactRoutes },
      { text: "チャット管理", path: AppRoutes.chat, relatedRoutes: ChatRoutes },
      { text: "システム設定", path: AppRoutes.emailSetting, relatedRoutes: SystemSettingRoutes },
    ],
  },
] as const;
