import { InnerBox } from "@/components/elements/InnerBox";
import { RegisterForm } from "@/features/auth/components/RegisterForm";
import { TRegisterQueryParam } from "@/features/auth/types/registerQueryParam";
import { getQueryFromURL } from "@/utils/url";
import { AppRoutes } from "@/routes/config";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type TRegisterPageProps = {};

export const RegisterPage: FC<TRegisterPageProps> = () => {
  const query = getQueryFromURL<TRegisterQueryParam>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!query || !query.email || !query.resetToken) {
      navigate(AppRoutes.login);
    }
  }, []);

  return (
    <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
      <RegisterForm />
    </InnerBox>
  );
};
