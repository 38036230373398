import { useSystemSetting } from "@/api/services/admin/system-setting";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { SettingForm } from "@/features/system-setting/components/SettingForm";
import { convertEmailSettingResToForm } from "@/features/system-setting/utils/converts";
import { FC } from "react";

type TEmailSettingPageProps = {};

export const EmailSettingPage: FC<TEmailSettingPageProps> = () => {
  const { data: systemSettingResult, isLoading } = useSystemSetting();

  if (isLoading) return <LoadingPage />;
  return (
    <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
      <SettingForm previousData={systemSettingResult ? convertEmailSettingResToForm(systemSettingResult.data) : undefined} />
    </InnerBox>
  );
};
