import { useSystemSetting } from "@/api/services/admin/system-setting";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { MaintenanceForm } from "@/features/system-setting/components/MaintenanceForm";
import { convertMaintenanceSettingResToForm } from "@/features/system-setting/utils/converts";
import { FC } from "react";

type TMaintenanceSettingPageProps = {};

export const MaintenanceSettingPage: FC<TMaintenanceSettingPageProps> = () => {
  const { data: systemSettingResult, isLoading } = useSystemSetting();

  if (isLoading) return <LoadingPage />;
  return (
    <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
      <MaintenanceForm previousData={systemSettingResult ? convertMaintenanceSettingResToForm(systemSettingResult.data) : undefined} />
    </InnerBox>
  );
};
