import { TOption } from "@/types/common";

/**
 * Finds the label associated with a given value in an array of options.
 *
 * @param {TOption[]} options - The array of options.
 * @param {unknown} [value] - The value to find the label for.
 * @returns {string} - The label associated with the value, or an empty string if not found.
 */
export const findLabelByValue = (options: TOption[], value?: unknown): string => {
  const found = options.find((option) => option.value == value);
  if (!found) return "";
  return found.label;
};

/**
 * Creates a deep copy of an object.
 *
 * @template T
 * @param {T} obj - The object to copy.
 * @returns {T} - The deep copied object.
 */
export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

/**
 * Picks specified keys from an object.
 *
 * @template T
 * @template K
 * @param {T} obj - The object to pick keys from.
 * @param {...K[]} keys - The keys to pick.
 * @returns {Pick<T, K>} - An object with only the picked keys.
 */
export const pick = <T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> => {
  const picked = {} as Pick<T, K>;
  keys.forEach((key) => {
    if (obj[key] !== undefined) {
      picked[key] = obj[key];
    }
  });
  return picked;
};

/**
 * Omits specified keys from an object.
 *
 * @template T
 * @template K
 * @param {T} obj - The object to omit keys from.
 * @param {...K[]} keys - The keys to omit.
 * @returns {Omit<T, K>} - An object without the omitted keys.
 */
export const omit = <T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
  const omitted = deepClone(obj) as T;
  keys.forEach((key) => {
    delete omitted[key];
  });
  return omitted;
};

/**
 * Checks if an object is empty.
 *
 * @param {object} obj - The object to check.
 * @returns {boolean} - True if the object is empty, false otherwise.
 */
export const isEmpty = (obj: object): boolean => {
  return JSON.stringify(obj) === "{}";
};

/**
 * Removes null and undefined values from an object.
 *
 * @template T
 * @param {T} obj - The object to clear.
 * @returns {Partial<T>} - A new object with only non-null and non-undefined values.
 */
export const clearObject = <T extends object>(obj: T): Partial<T> => {
  let newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      newObj = { ...newObj, [key]: value };
    }
  });
  return newObj as Partial<T>;
};

/**
 * Checks if a value is an object.
 *
 * @param {unknown} json - The value to check.
 * @returns {json is object} - True if the value is an object, false otherwise.
 */
export const isObject = (json: unknown): json is object => {
  if (typeof json !== "object" || json === null) return false;
  return true;
};

/**
 * Converts an object to an array of options with label and value.
 *
 * @param {object} obj - The object to convert.
 * @returns {TOption[]} - An array of options with label and value.
 */
export const objectToOption = (obj: object): TOption[] => {
  return Object.entries(obj).map(([key, value]) => ({ label: value, value: key }));
};

export const convertToCompactDate = (dateString: string) => {
  return dateString.replace(/-/g, "");
};
