export const QUERY_KEYS = {
  GLOBAL: {
    FETCH_ENUM: "GLOBAL_FETCH_ENUM",
    MASTER_DATA: "MASTER_DATA",
    MASTER_REGION: "MASTER_REGION",
    MASTER_NATIONALITY: "MASTER_NATIONALITY",
    MASTER_LIVING_RELATIONSHIP: "MASTER_LIVING_RELATIONSHIP",
    MASTER_MAIN_OCCUPATION: "MASTER_MAIN_OCCUPATION",
    MASTER_MAIN_INDUSTRY: "MASTER_MAIN_INDUSTRY",
    MASTER_LOCAL_GOVERNMENT_COUNTER: "MASTER_LOCAL_GOVERNMENT_COUNTER",
    MASTER_LANGUAGE_TEST: "MASTER_LANGUAGE_TEST",
    MASTER_SKILL_TEST: "MASTER_SKILL_TEST",
    MASTER_SPECIFIC_INDUSTRY: "MASTER_SPECIFIC_INDUSTRY",
    MASTER_MAJOR: "MASTER_MAJOR",
    MASTER_COMPANY_SPECIFIC_INDUSTRY: "MASTER_COMPANY_SPECIFIC_INDUSTRY",
    MASTER_RESIDENCE_STATUS: "MASTER_RESIDENCE_STATUS",
    PROVINCES_MUNICIPALITIES: "GLOBAL_PROVINCES_MUNICIPALITIES",
    ENCRYPT_SECRET_KEY: "ENCRYPT_SECRET_KEY",
  },
  COMPANY: {
    FETCH_LIST: "COMPANY_FETCH_LIST",
    FETCH_DETAIL: "COMPANY_FETCH_DETAIL",
    FETCH_USER_LIST: "COMPANY_FETCH_USER_LIST",
    FETCH_USER_DETAIL: "COMPANY_FETCH_USER_DETAIL",
    CHECK_TO_UPDATE_YEAR_QUARTER: "CHECK_TO_UPDATE_YEAR_QUARTER",
    FETCH_BILLING_LIST: "COMPANY_FETCH_BILLING_LIST",
  },
  ADMIN_USER: {
    FETCH_LIST: "ADMIN_USER_FETCH_LIST",
    FETCH_DETAIL: "ADMIN_USER_FETCH_DETAIL",
  },
  INFORMATION: {
    FETCH_LIST: "INFORMATION_FETCH_LIST",
    FETCH_DETAIL: "INFORMATION_FETCH_DETAIL",
  },
  FILE: {
    FETCH_DETAIL: "FILE_FETCH_DETAIL",
    FETCH_INFO: "FILE_FETCH_INFO",
  },
  USER: {
    FETCH_ME: "USER_FETCH_ME",
  },
  CONVERT: {
    FETCH_DETAIL: "CONVERT_FETCH_DETAIL",
    FETCH_LIST: "CONVERT_FETCH_LIST",
    FETCH_ACCEPTANCE_ACTIVITY_DETAIL: "CONVERT_FETCH_ACCEPTANCE_ACTIVITY_DETAIL",
    FETCH_LIFE_SUPPORT_DETAIL: "CONVERT_FETCH_LIFE_SUPPORT_DETAIL",
  },
  CONTACT: {
    FETCH_DETAIL: "CONTACT_FETCH_DETAIL",
    FETCH_LIST: "CONTACT_FETCH_LIST",
  },
  CONTACT_TYPE: {
    FETCH_LIST: "CONTACT_TYPE_FETCH_LIST",
    FETCH_DETAIL: "CONTACT_TYPE_FETCH_DETAIL",
  },
  SYSTEM_SETTING: {
    FETCH_SYSTEM_DETAIL: "SYSTEM_SETTING_FETCH_SYSTEM_DETAIL",
    FETCH_MAINTENANCE_DETAIL: "SYSTEM_SETTING_FETCH_MAINTENANCE_DETAIL",
  },
  CONVERSATION: {
    FETCH_LIST: "CONVERSATION_FETCH_LIST",
    FETCH_DETAIL: "CONVERSATION_FETCH_DETAIL",
    FETCH_MESSAGE_LIST: "CONVERSATION_FETCH_MESSAGE_LIST",
  },
} as const;
