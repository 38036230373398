import { TMasterLocalGovernmentCounter } from "@/api/entities/master-local-government-counter.entity";
import { setHiddenLocalGovernmentCounterApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenLocalGovernmentCounterModalProps = {
  hiddenLocalGovernmentCounter?: TMasterLocalGovernmentCounter;
  setHiddenLocalGovernmentCounter: Dispatch<SetStateAction<TMasterLocalGovernmentCounter | undefined>>;
};

export const HiddenLocalGovernmentCounterModal: FC<THiddenLocalGovernmentCounterModalProps> = ({
  hiddenLocalGovernmentCounter,
  setHiddenLocalGovernmentCounter,
}) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenLocalGovernmentCounter) return;
    try {
      await setHiddenLocalGovernmentCounterApi(toNumber(hiddenLocalGovernmentCounter.id), { isHidden: !hiddenLocalGovernmentCounter.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenLocalGovernmentCounter(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LOCAL_GOVERNMENT_COUNTER] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenLocalGovernmentCounter?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenLocalGovernmentCounter}
      onClose={() => setHiddenLocalGovernmentCounter(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenLocalGovernmentCounter?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
