import { validator } from "@/utils/validator";

export const jobTypeSchema = validator.object().shape({
  jobName: validator.string().emptyToNull().label("作業").required(),
  cpJobRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列CP）").required(),
});

export const masterDataCreateMajorSchema = validator.object().shape({
  majorName: validator.string().emptyToNull().label("職種").required(),
  coMajorRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列CO）").required(),
  jobTypes: validator.array().of(jobTypeSchema).label("occupations").required(),
});

export const masterDataUpdateMajorSchema = validator.object().shape({
  majorName: validator.string().emptyToNull().label("職種").required(),
  coMajorRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列CO）").required(),
});
