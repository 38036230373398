import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { Table } from "@/components/elements/Table";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TEmployeeLite } from "@/features/convert/types/employeeLite";
import { useTable } from "@/hooks/useTable";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { Box, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { useMemo } from "react";

type TConvertEmployeeTableProps = {
  employees: TEmployeeLite[];
  showSpecific?: boolean;
};

type TRow = {
  row: MRT_Row<Record<string, string | null>>;
};

export const ConvertEmployeeTable = ({ employees, showSpecific = false }: TConvertEmployeeTableProps) => {
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<Record<string, string | null>>[]>(
    () => [
      {
        header: "氏名",
        id: "employeeFullName",
        accessorKey: "employeeFullName",
        size: 120,
      },
      {
        header: "性別",
        Cell: ({ row }) => findLabelByValue(genderOptions, row.original?.employeeGender) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "生年月日",
        Cell: ({ row }) => (row.original?.birthday ? dayjs(row.original?.birthday).format(COMPACT_DATE_FORMAT) : EMPTY_STR.DATE),
        size: 120,
      },
      {
        header: "国籍",
        id: "employeeNationality",
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original?.employeeNationalityId) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "在留カード番号",
        Cell: ({ row }) => row.original?.employeeResidenceCardNumber || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "郵便番号",
        Cell: ({ row }) => (row.original?.zipCode ? convertNumberToZipCode(row.original?.zipCode) : EMPTY_STR.TEXT),
        size: 120,
      },
      {
        header: "住所",
        Cell: ({ row }) => row.original?.fullAddress || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "電話番号",
        Cell: ({ row }) => row.original?.telNumber || EMPTY_STR.TEXT,
        size: 120,
      },
      ...(showSpecific
        ? [
            { header: "特定産業分野", Cell: ({ row }: TRow) => row.original?.specificBusinessClassificationName || EMPTY_STR.TEXT, size: 120 },
            { header: "業務区分", Cell: ({ row }: TRow) => row.original?.specificIndustryName || EMPTY_STR.TEXT, size: 120 },
          ]
        : []),
    ],
    [genderOptions, nationalityOptions],
  );

  const { table } = useTable({ columns, rows: employees ?? [] });

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">対象の外国人</Typography>
        <Box maxHeight={586}>
          <Table table={table} />
        </Box>
      </Stack>
    </InnerBox>
  );
};
