import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export const LoginLayout: FC = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" mt={13}>
      <Outlet />
    </Box>
  );
};
