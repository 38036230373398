import { EXTENSION_NAMES, FILE_ERROR_MESSAGE, FILE_STATUS, MIME_TYPES, TFileStatus } from "@/constants/file";
import { TExtendFile } from "@/types/file";
import { uniqueArray } from "@/utils/pieces";
import { replaceStr } from "@/utils/string";
import { Accept } from "react-dropzone";

/**
 * Converts file size from kilobytes to megabytes.
 * @param size - Size in kilobytes.
 * @returns Size in megabytes.
 */
export const convertKbToMb = (size: number) => {
  return size / (1024 * 1024);
};

export type TFileRules = Partial<{
  maxSize: number;
  minSize: number;
  acceptMimeTypes: string[];
}>;

/**
 * Evaluates the status of a file based on provided rules.
 * @param file - The file to check.
 * @param options - The rules to apply.
 * @returns The status of the file based on the rules.
 */
export const getFileStatusWithRules = (file: File, options: TFileRules): TFileStatus => {
  try {
    // Check if file size is less than minimum size if defined
    if (options.minSize !== undefined && file.size < options.minSize) {
      return FILE_STATUS.ERROR_MIN_SIZE;
    }
    // Check if file size exceeds maximum size if defined
    if (options.maxSize !== undefined && file.size > options.maxSize) {
      return FILE_STATUS.ERROR_MAX_SIZE;
    }
    // Check if file type is among the accepted MIME types
    if (options.acceptMimeTypes !== undefined && !options.acceptMimeTypes.includes(file.type)) {
      return FILE_STATUS.ERROR_ACCEPT_FILES;
    }
    // If all checks pass, return OK status
    return FILE_STATUS.OK;
  } catch {
    // Return a generic error status if any exception occurs
    return FILE_STATUS.ERROR;
  }
};

/**
 * Determines if a file has an error status.
 * @param file - The file to check.
 * @returns True if the file has an error status, otherwise false.
 */
export const isFileError = (file: TExtendFile) => {
  if (!file.status) return false;
  // Check if file status is one of the defined error statuses
  return [FILE_STATUS.ERROR, FILE_STATUS.ERROR_ACCEPT_FILES, FILE_STATUS.ERROR_MAX_SIZE, FILE_STATUS.ERROR_MIN_SIZE].includes(file.status);
};

/**
 * Retrieves the file extension corresponding to a MIME type.
 * @param mimeType - The MIME type to check.
 * @returns The file extension if found, otherwise null.
 */
export const getExtensionOfMimeType = (mimeType: string) => {
  // Find the MIME type entry in the MIME_TYPES object
  const found = Object.entries(MIME_TYPES).find(([_, mimeTypes]) => mimeTypes.includes(mimeType));
  if (!found) return null;
  const extensionKey = found[0];
  // Return the corresponding extension name
  return EXTENSION_NAMES[extensionKey] ?? null;
};

/**
 * Retrieves all file extensions corresponding to a list of MIME types.
 * @param mimeTypes - An array of MIME types.
 * @returns An array of unique file extensions.
 */
export const getExtensionsOfMimeTypes = (mimeTypes: string[]) => {
  const extensionNames: string[] = [];
  mimeTypes.forEach((mimeType) => {
    const extensionName = getExtensionOfMimeType(mimeType);
    if (extensionName) extensionNames.push(extensionName);
  });
  // Ensure extensions are unique
  return uniqueArray(extensionNames);
};

/**
 * Converts MIME types to a format acceptable by react-dropzone.
 * @param mimeTypes - An array of MIME types.
 * @returns An object where keys are MIME types and values are empty arrays.
 */
export const covertMimeTypesToAccepts = (mimeTypes?: string[]) => {
  if (!mimeTypes) return undefined;
  // Create an object with MIME types as keys and empty arrays as values
  return mimeTypes.reduce((cur, mimeType) => ({ ...cur, [mimeType]: [] }), {} as Accept);
};

/**
 * Extracts the file name from the file path.
 *
 * @param {string} [filePath=""] - The file path.
 * @returns {string | null} - The file name, or null if not found. Ex: 1724653683544_sample.pdf => sample.pdf
 */
export const getFileNameFromPath = (filePath: string = ""): string | null => {
  const fileName = filePath.split("/").pop();
  if (!fileName) return null;
  const [_, ...fileNameWithoutTimestamp] = fileName.split("_");
  if (fileNameWithoutTimestamp) return fileNameWithoutTimestamp.join("_");
  return null;
};

/**
 * Generates an error message for a file based on its status and provided rules.
 * @param file - The file to check.
 * @param rules - The rules to apply.
 * @returns An error message if the file has an error status, otherwise an empty string.
 */
export const getFileErrorMessage = (file: TExtendFile, rules: TFileRules) => {
  if (isFileError(file)) {
    // Generate the error message based on the file status and rules
    return replaceStr(FILE_ERROR_MESSAGE[file.status], {
      maxSize: rules.maxSize,
      minSize: rules.minSize,
      extensions: getExtensionsOfMimeTypes(rules.acceptMimeTypes ?? []).join("、"),
    });
  }
  return "";
};
