import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Table } from "@/components/elements/Table";
import {
  TCompanySpecificBusinessClassificationForm,
  TMasterCompanySpecificIndustryCreateForm,
} from "@/features/master-data/types/masterDataCompanySpecificIndustryForm";
import { useFormTable } from "@/hooks/useFormTable";
import { MRT_ColumnDef } from "material-react-table";
import { useFormContext } from "react-hook-form";

export const CreateCompanySpecificIndustryFormTable = () => {
  const { control } = useFormContext<TMasterCompanySpecificIndustryCreateForm>();

  const formColumns: MRT_ColumnDef<TCompanySpecificBusinessClassificationForm>[] = [
    {
      header: "業務区分（会社情報用）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`companySpecificBusinessClassifications.${row.index}.companySpecificBusinessClassificationName`}>
            <Input />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({
    columns: formColumns,
    control,
    name: "companySpecificBusinessClassifications",
    initFieldData: {
      companySpecificBusinessClassificationName: "",
    },
    primaryKey: "id",
  });

  return <Table table={table} maxHeight={400} />;
};
