import { Button } from "@/components/elements/Button";
import { Pagination } from "@/components/elements/Pagination";
import { useCompanyList } from "@/api/services/admin/company";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";

type TCompanyListTopProps = {};

export const CompanyListTop: FC<TCompanyListTopProps> = () => {
  const { data } = useCompanyList();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={data?.pagination.totalPage} />
      <Button component={Link} size="sm" variant="outline" to={AppRoutes.companyCreate}>
        新規作成
      </Button>
    </Stack>
  );
};
