import { AppDrawer } from "@/components/layout/elements/AppDrawer";
import { AppHeader } from "@/components/layout/elements/AppHeader";
import { withAuth } from "@/hoc/withAuth";
import { useVisible } from "@/hooks/useVisible";
import { background } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Box, Stack, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

export const AdminLayout: FCC = ({ children }) => {
  const showDrawer = useVisible();

  return (
    <>
      <AppHeader handleOpenDrawer={showDrawer.open} />
      <Stack width="100%" direction="row">
        <AppDrawer isOpenDrawer={showDrawer.visible} toggleDrawer={showDrawer.toggle} />
        <ChildrenWrapper onClick={showDrawer.close}>
          <Outlet />
          {children}
        </ChildrenWrapper>
      </Stack>
    </>
  );
};

export const AdminLayoutWithAuth = withAuth(AdminLayout);

const ChildrenWrapper = styled(Box)`
  padding: 24px;
  padding-left: 32px;
  padding-right: 48px;
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  max-width: 1200px;
  margin: 0 auto;
`;
