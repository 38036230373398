import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { recoverSchema } from "@/features/auth/schema/recoverSchema";
import { requestResetPasswordApi } from "@/features/auth/services/requestResetPassword";
import { TRecoverRequest } from "@/features/auth/types/recoverRequest";
import { useToast } from "@/hooks/useToast";
import { setErrorForm, showError } from "@/utils/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

export const RecoverForm = () => {
  const methods = useForm<TRecoverRequest>({
    mode: "onChange",
    resolver: yupResolver(recoverSchema),
  });
  const { control } = methods;
  const toast = useToast();

  const onSubmit = async (data: TRecoverRequest) => {
    try {
      await requestResetPasswordApi(data);
      toast.success("パスワードをリセットするためのURLリンクがメールに送付されました。メールをチェックしてください");
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={recoverSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="email" label="メールアドレス">
          <Input size="lg" />
        </FormField>
        <Button type="submit" variant="primary" fullWidth>
          メールを送信
        </Button>
      </Stack>
    </Form>
  );
};
