import { TopPage } from "@/components/elements/TopPage";
import { getDynamicRoute } from "@/utils/url";
import { Stack, styled } from "@mui/material";
import { FC, useMemo } from "react";
import { Link, matchRoutes, Outlet, useLocation, useParams } from "react-router-dom";
import { TContactMenu } from "@/features/contact-type/types/contactMenu";
import { contactMenu } from "@/features/contact-type/constants/contactMenu";
import { AppRoutes } from "@/routes/config";

type TContactLayoutProps = {};

export const ContactLayout: FC<TContactLayoutProps> = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const renderRoutes = (menu: TContactMenu[]) => {
    return menu.map(({ title, url, extraUrls }) => {
      const isActive =
        matchRoutes([{ path: url }], pathname) !== null || extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null);

      return (
        <StyledLink isActive={isActive} key={url} className={isActive ? "active" : ""} to={getDynamicRoute(url, { id })}>
          {title}
        </StyledLink>
      );
    });
  };

  const isExtraUrlActive = useMemo(() => {
    return contactMenu.some(({ extraUrls }) => extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null));
  }, [pathname]);

  const backUrl = useMemo(() => {
    if (isExtraUrlActive) {
      const activeMenu = contactMenu.find(({ extraUrls }) => extraUrls?.some((extraUrl) => matchRoutes([{ path: extraUrl }], pathname) !== null));
      return activeMenu ? getDynamicRoute(activeMenu.url, { id }) : AppRoutes.company;
    }
    return AppRoutes.company;
  }, [isExtraUrlActive, pathname, id]);

  return (
    <Stack gap={2.5}>
      {isExtraUrlActive ? <TopPage title="お問い合わせ管理" backUrl={backUrl} /> : <TopPage title="お問い合わせ管理" enablePageBack={false} />}
      <Stack direction="row" gap={10}>
        {renderRoutes(contactMenu)}
      </Stack>
      <Outlet />
    </Stack>
  );
};

const options = { shouldForwardProp: (propName: string) => !["isActive"].includes(propName) };

const StyledLink = styled(
  Link,
  options,
)<{ isActive: boolean }>(({ isActive }) => ({
  textDecoration: isActive ? "underline" : "none",
}));
