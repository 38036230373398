import { hexToRgba } from "@/utils/pieces";
import { text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { Chip, ChipProps, css, styled } from "@mui/material";
import { FC } from "react";

export type TTagVariant = "bold" | "light";

export type TTagProps = {
  variant: TTagVariant;
  color: string;
  title: string;
  enablePoint?: boolean;
  pointColor?: string;
} & Omit<ChipProps, "variant" | "color">;

export const Tag: FC<TTagProps> = ({ variant, color, title, enablePoint = variant === "light", pointColor = color, ...rest }) => {
  return (
    <StyledChip
      chipVariant={variant}
      backgroundColor={color}
      label={
        <StyledTypography>
          {enablePoint && <StyledPoint background={pointColor} />}
          <span>{title}</span>
        </StyledTypography>
      }
      {...rest}
    />
  );
};

const options = { shouldForwardProp: (propName: string) => !["chipVariant", "backgroundColor"].includes(propName) };

const StyledChip = styled(Chip, options)<{ chipVariant: TTagVariant; backgroundColor: string }>`
  ${({ chipVariant }) => (chipVariant === "bold" ? BoldTagCSS : LightTagCSS)};
  background-color: ${({ chipVariant, backgroundColor }) => (chipVariant === "bold" ? backgroundColor : hexToRgba(backgroundColor, 0.2))};
`;

const BoldTagCSS = css`
  border-radius: ${rounded.md};
  .MuiTypography-root {
    font-size: 12px;
    font-weight: bold;
    color: ${text.white};
  }
`;

const LightTagCSS = css`
  border-radius: ${rounded.lg};
  .MuiTypography-root {
    font-size: 14px;
    color: ${text.primary};
  }
`;

const StyledTypography = styled("span")`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  ${css(typography.body14)}
`;

const StyledPoint = styled("span")<{ background: string }>`
  width: 8px;
  height: 8px;
  border-radius: ${rounded.full};
  background-color: ${({ background }) => background};
`;
