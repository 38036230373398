import { TApiPagReq } from "@/types/api";

export const DEFAULT_PER_PAGE = 20;

export const START_PAGE = 1;

export const DEFAULT_PAGINATION_REQUEST: TApiPagReq = {
  page: START_PAGE,
  perPage: DEFAULT_PER_PAGE,
};
