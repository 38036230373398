import { useAdminUserList } from "@/api/services/admin/user";
import { TGetUserListDto } from "@/api/services/admin/user/dtos/get-user-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { IconChevronRight } from "@/assets/icons";
import { IconBox } from "@/components/elements/IconBox";
import { Linkable } from "@/components/elements/Linkable";
import { Table } from "@/components/elements/Table";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const AdminUserTable = () => {
  const navigate = useNavigate();
  const { data: adminRole } = useEnum("EAdminRole");
  const columns = useMemo<MRT_ColumnDef<TGetUserListDto>[]>(
    () => [
      {
        header: "名前",
        Cell: ({ row }) => row.original.fullName || EMPTY_STR.TEXT,
      },
      {
        header: "メールアドレス",
        Cell: ({ row }) => row.original.email || EMPTY_STR.TEXT,
      },
      {
        header: "担当",
        Cell: ({ row }) => findLabelByValue(adminRole, row.original.adminRole) || EMPTY_STR.TEXT,
        size: 100,
      },
      {
        header: "詳細へ",
        id: "action",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.adminUserDetail, { id })}>
              <IconBox>
                <IconChevronRight fontSize={20} />
              </IconBox>
            </Linkable>
          );
        },
        size: 20,
      },
    ],
    [adminRole],
  );

  const { data, isLoading } = useAdminUserList();
  const { table } = useTable({
    columns,
    rows: data?.data ?? [],
    onClickRow: (row) => navigate(getDynamicRoute(AppRoutes.adminUserDetail, { id: row.id })),
    pinningColumns: ["action"],
  });

  return <Table table={table} isLoading={isLoading} />;
};
