import { useInformation } from "@/api/services/admin/information";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { InformationForm } from "@/features/information/components/InformationForm";
import { convertInformationDetailToForm } from "@/features/information/utils/convert";
import { FC } from "react";

type TInformationEditPageProps = {};

export const InformationEditPage: FC<TInformationEditPageProps> = () => {
  const { data: informationResult, isLoading } = useInformation();
  const informationData = informationResult?.data;

  if (isLoading || !informationData) return <LoadingPage />;

  return <InformationForm previousData={convertInformationDetailToForm(informationData)} />;
};
