import { Clickable } from "@/components/elements/Clickable/Clickable";
import { useVisible } from "@/hooks/useVisible";
import { FCC } from "@/types/common";
import { Popover as MuiPopover, PopoverProps } from "@mui/material";
import { ReactNode, useRef } from "react";

type TPopoverProps = {
  anchor: ReactNode;
  closeClickInside?: boolean;
  disabled?: boolean;
} & Omit<PopoverProps, "open" | "anchorEl" | "onClose">;

export const Popover: FCC<TPopoverProps> = ({ children, anchor, closeClickInside, disabled = false, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { visible, close, open } = useVisible(false);

  const handleOpen = () => {
    if (!disabled) {
      open();
    }
  };

  return (
    <>
      <Clickable onClick={handleOpen} ref={ref}>
        {anchor}
      </Clickable>
      <MuiPopover
        onClick={closeClickInside ? close : undefined}
        open={visible}
        anchorEl={ref.current}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...rest}
      >
        {children}
      </MuiPopover>
    </>
  );
};
