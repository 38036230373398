import { valueOf } from "@/types/common";

export const MASTER_DATA_TYPE = {
  NATIONALITY: "nationality",
  RESIDENCE_PERIOD: "residence_period",
  LOCAL_GOVERNMENT_COUNTER: "local_government_counter",
  LIVING_RELATIONSHIP: "living_relationship",
  JOB_TYPE: "job_type",
  SPECIFIC_INDUSTRY: "specific_industry",
  MAIN_OCCUPATION: "main_occupation",
  MAIN_INDUSTRY: "main_industry",
  SKILL_TEST: "skill_test",
  LANGUAGE_TEST: "language_test",
  COMPANY_SPECIFIC_INDUSTRY: "company_specific_industry",
};

export type TMasterDataType = valueOf<typeof MASTER_DATA_TYPE>;

export const MASTER_DATA_TYPE_TITLE = {
  NATIONALITY: "irohanaがサポートする国籍",
  RESIDENCE_PERIOD: "在留期間・在留資格",
  LOCAL_GOVERNMENT_COUNTER: "地域別の地方官署窓口",
  LIVING_RELATIONSHIP: "在日親族の続柄",
  JOB_TYPE: "職種／作業",
  SPECIFIC_INDUSTRY: "在留申請における特定産業分野／業務区分",
  MAIN_OCCUPATION: "主たる職種",
  MAIN_INDUSTRY: "主たる業種",
  SKILL_TEST: "技能試験",
  LANGUAGE_TEST: "日本語試験",
  COMPANY_SPECIFIC_INDUSTRY: "特定産業分野",
};
