import { createLivingRelationshipApi, updateLivingRelationshipApi } from "@/api/services/global/master";
import { TGetLivingRelationshipListOutputDto } from "@/api/services/global/master/dtos/get-master-living-relationship-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { masterDataLivingRelationshipSchema } from "@/features/master-data/schema/masterDataLivingRelationshipSchema";
import { TMasterDataLivingRelationshipForm } from "@/features/master-data/types/masterDataLivingRelationshipForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TLivingRelationshipFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLivingRelationship?: TGetLivingRelationshipListOutputDto;
};

export const LivingRelationshipFormModal: FC<TLivingRelationshipFormModalProps> = ({ onClose, isOpen, selectedLivingRelationship }) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      relationship: selectedLivingRelationship ? selectedLivingRelationship.relationship : undefined,
      xLivingRelationshipRenewVisaCsvCode: selectedLivingRelationship ? selectedLivingRelationship.xLivingRelationshipRenewVisaCsvCode : undefined,
    }),
    [selectedLivingRelationship],
  );

  const methods = useForm<TMasterDataLivingRelationshipForm>({
    resolver: yupResolver(masterDataLivingRelationshipSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterDataLivingRelationshipForm) => {
    try {
      if (selectedLivingRelationship) {
        const rs = await updateLivingRelationshipApi({
          livingRelationships: [
            {
              id: toNumber(selectedLivingRelationship.id),
              relationship: data.relationship,
              xLivingRelationshipRenewVisaCsvCode: data.xLivingRelationshipRenewVisaCsvCode,
            },
          ],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createLivingRelationshipApi({
          relationship: data.relationship,
          xLivingRelationshipRenewVisaCsvCode: data.xLivingRelationshipRenewVisaCsvCode,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_LIVING_RELATIONSHIP] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedLivingRelationship ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataLivingRelationshipSchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={6}>
            <FormField control={control} name="relationship" label="続柄">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="xLivingRelationshipRenewVisaCsvCode" label="期間更新CSVのコード（列X）">
              <Input />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
