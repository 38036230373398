import { TMasterCompanySpecificIndustry } from "@/api/entities/master-company-specific-industry.entity";
import { setHiddenCompanySpecificIndustryApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenCompanySpecificIndustryModalProps = {
  hiddenCompanySpecificIndustry?: TMasterCompanySpecificIndustry;
  setHiddenCompanySpecificIndustry: Dispatch<SetStateAction<TMasterCompanySpecificIndustry | undefined>>;
};

export const HiddenCompanySpecificIndustryModal: FC<THiddenCompanySpecificIndustryModalProps> = ({
  hiddenCompanySpecificIndustry,
  setHiddenCompanySpecificIndustry,
}) => {
  const queryClient = useQueryClient();
  const handleSubmit = async () => {
    if (!hiddenCompanySpecificIndustry) return;
    try {
      await setHiddenCompanySpecificIndustryApi(toNumber(hiddenCompanySpecificIndustry.id), { isHidden: !hiddenCompanySpecificIndustry.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenCompanySpecificIndustry(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenCompanySpecificIndustry?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenCompanySpecificIndustry}
      onClose={() => setHiddenCompanySpecificIndustry(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenCompanySpecificIndustry?.isHidden ? "この項目が有効になれますが、よろしいですか。" : "この項目が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
