import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { PASSWORD_CHANGE_SUCCESS_MESSAGE } from "@/constants/common";
import { resetPasswordSchema } from "@/features/auth/schema/resetPasswordSchema";
import { resetPasswordApi } from "@/features/auth/services/resetPassword";
import { TResetPasswordForm } from "@/features/auth/types/resetPasswordForm";
import { TResetPasswordQuery } from "@/features/auth/types/resetPasswordQuery";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { createUrlWithParams, getQueryFromURL } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

const initForm: TResetPasswordForm = {
  email: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const ResetPasswordForm = () => {
  const query = getQueryFromURL<TResetPasswordQuery>();
  const methods = useForm<TResetPasswordForm>({
    mode: "onChange",
    defaultValues: { ...initForm, email: query?.email ?? "" },
    resolver: yupResolver(resetPasswordSchema),
  });
  const { control } = methods;
  const toast = useToast();

  const onSubmit = async (data: TResetPasswordForm) => {
    const { newPassword } = data;
    if (!query) return;
    const { email, resetToken } = query;
    try {
      if (email && resetToken) {
        await resetPasswordApi({ email, newPassword, resetToken });
        toast.success(PASSWORD_CHANGE_SUCCESS_MESSAGE);
        const url = createUrlWithParams(AppRoutes.login, { email });
        // Delay 2 seconds to show success message
        setTimeout(() => {
          location.href = url;
        }, 2000);
      }
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} enableBlocker={false} schema={resetPasswordSchema}>
      <Stack gap={3} width={400}>
        <FormField control={control} name="email">
          <Input disabled size="lg" />
        </FormField>
        <FormField control={control} name="newPassword">
          <Input type="password" size="lg" />
        </FormField>
        <FormField control={control} name="confirmNewPassword">
          <Input type="password" size="lg" />
        </FormField>
        <Button type="submit" variant="primary" fullWidth>
          パスワード再設定
        </Button>
      </Stack>
    </Form>
  );
};
