import { updateContact, useContact } from "@/api/services/admin/contact";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { Textarea } from "@/components/elements/Textarea";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { CONTACT_STATUS, CONTACT_STATUS_LABEL } from "@/constants/enum";
import { EMPTY_STR } from "@/constants/string";
import { FCC } from "@/types/common";
import { showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

type TContactInfoProps = {};

export const ContactInfo: FCC<TContactInfoProps> = () => {
  const { data, isLoading } = useContact();
  const [isResolvedStatus, setIssResolvedStatus] = useState<boolean>(data?.data.isResolved ?? false);
  const [isStatusUpdatingLoading, setIsStatusUpdatingLoading] = useState<boolean>(false);
  const { id } = useParams();

  const handleUpdateContactStatus = async () => {
    setIsStatusUpdatingLoading(true);
    try {
      await updateContact(Number(id));
      toast.success(API_SUCCESS_MESSAGE);
      setIssResolvedStatus(true);
    } catch (error) {
      showError(error);
    } finally {
      setIsStatusUpdatingLoading(false);
    }
  };

  if (isLoading) return <LoadingPage />;
  return (
    <Stack gap={2}>
      <InnerBox padding={4}>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">お客様の企業名</Typography>
                <Typography>{data?.data.company.companyName}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">担当者名</Typography>
                <Typography>{data?.data.user?.fullName}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">お問い合わせの種類</Typography>
                <Typography>{data?.data.contactType.name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">お問い合わせ内容の詳細</Typography>
                <Textarea readonly value={data?.data.content} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {data?.data.filePath && (
                <Stack padding={1} gap={1}>
                  <Typography variant="body14Bold">添付ファイル</Typography>
                  <FileItem filePath={data?.data.filePath} downloadable />
                </Stack>
              )}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">メールアドレス</Typography>
                <Typography>{data?.data.email}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack padding={1} gap={1}>
                <Typography variant="body14Bold">連絡先電話番号</Typography>
                <Typography>{data?.data.telNumber || EMPTY_STR.TEXT}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </InnerBox>
      <InnerBox>
        <Stack alignItems="end">
          <Button
            sx={{ minWidth: "210px" }}
            variant="primary"
            disabled={isStatusUpdatingLoading || data?.data.isResolved || isResolvedStatus}
            onClick={handleUpdateContactStatus}
          >
            {isStatusUpdatingLoading
              ? "作成中…"
              : data?.data.isResolved || isResolvedStatus
                ? CONTACT_STATUS_LABEL[CONTACT_STATUS.RESOLVED]
                : "返信済みとしてマーク"}
          </Button>
        </Stack>
      </InnerBox>
    </Stack>
  );
};
