import { validator } from "@/utils/validator";

export const residencePeriodSchema = validator.object().shape({
  residencePeriod: validator.string().emptyToNull().label("在留期間").notRequired(),
  residencePeriodRenewVisaCsvCode: validator.string().emptyToNull().label("在留期間の期間更新CSVのコード　BP列").notRequired(),
  desiredResidencePeriod: validator.string().emptyToNull().label("希望する在留期間").notRequired(),
  desiredResidencePeriodRenewVisaCsvCode: validator.string().emptyToNull().label("希望する在留期間の期間更新CSVのコード　BU列").notRequired(),
});

export const masterDataCreateResidenceStatusSchema = validator.object().shape({
  residenceStatus: validator.string().emptyToNull().label("現に有する在留資格").required(),
  residenceStatusRenewVisaCsvCode: validator.string().emptyToNull().label("有する在留資格の期間更新CSVのコード").notRequired(),
  residencePeriods: validator.array().of(residencePeriodSchema).label("residencePeriods").required(),
});

export const masterDataUpdateResidenceStatusSchema = validator.object().shape({
  residenceStatus: validator.string().emptyToNull().label("現に有する在留資格").required(),
  residenceStatusRenewVisaCsvCode: validator.string().emptyToNull().label("有する在留資格の期間更新CSVのコード").notRequired(),
});
