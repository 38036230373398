import { MasterDataTable } from "@/features/master-data/components/MasterDataTable";
import { Box, Typography } from "@mui/material";
import { FC } from "react";

type TMasterDataListProps = {};

export const MasterDataListPage: FC<TMasterDataListProps> = () => {
  return (
    <>
      <Box mt="78px" mb="55px">
        <Typography variant="head32Bold">マスターデータ管理</Typography>
      </Box>
      <MasterDataTable />
    </>
  );
};
