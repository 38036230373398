import { useCompany } from "@/api/services/admin/company";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { CompanyForm } from "@/features/company/components/CompanyForm";
import { convertCompanyResToForm } from "@/features/company/utils/converts";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";

type TCompanyEditPageProps = {};

export const CompanyEditPage: FC<TCompanyEditPageProps> = () => {
  const { data: companyResult, isLoading } = useCompany();
  const companyData = companyResult?.data;

  if (isLoading || !companyData) return <LoadingPage />;

  return (
    <Stack gap={4}>
      <TopPage
        title="受け入れ企業を編集"
        description="企業の基本情報を入力して追加しましょう"
        backUrl={getDynamicRoute(AppRoutes.companyDetail, { id: companyData.id })}
      />
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyForm previousData={convertCompanyResToForm(companyData)} />
      </InnerBox>
    </Stack>
  );
};
