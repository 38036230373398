import { TCheckToUpdateStartReportYearAndQuarterDtoResDto } from "@/api/services/admin/company/dtos/check-to-update-start-report-year-and-quarter.dto";
import { TCreateCompanyReqDto, TCreateCompanyResDto } from "@/api/services/admin/company/dtos/create-company.dto";
import { TDeleteCompanyResDto } from "@/api/services/admin/company/dtos/delete-company.dto";
import { TGetCompanyListResDto } from "@/api/services/admin/company/dtos/get-company-list.dto";
import { TGetCompanyResDto } from "@/api/services/admin/company/dtos/get-company.dto";
import { TSwitchUserAsAdminResDto } from "@/api/services/admin/company/dtos/switch-user-as-admin.dto";
import { TUpdateCompanyReqDto, TUpdateCompanyResDto } from "@/api/services/admin/company/dtos/update-company.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiPagReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const checkToUpdateYearAndQuarterApi = async (companyId: string | number) => {
  const rs = await clientApi.get<TCheckToUpdateStartReportYearAndQuarterDtoResDto>(`/companies/${companyId}/check-to-update-start-year-and-quarter`);
  return rs.data;
};

export const useCheckToUpdateYearAndQuarter = (companyId?: string | number) => {
  const { id } = useParams();

  const fetchId = companyId ?? id;

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.CHECK_TO_UPDATE_YEAR_QUARTER, fetchId],
    queryFn: () => (fetchId ? checkToUpdateYearAndQuarterApi(fetchId) : undefined),
    enabled: Boolean(fetchId),
  });
};

export const createCompanyApi = async (payload: TCreateCompanyReqDto): Promise<TCreateCompanyResDto> => {
  const rs = await clientApi.post<TCreateCompanyResDto>("/companies", payload);
  return rs.data;
};

export const fetchCompanyApi = async (companyId: string): Promise<TGetCompanyResDto> => {
  const rs = await clientApi.get<TGetCompanyResDto>(`/companies/${companyId}`);
  return rs.data;
};

export const useCompany = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchCompanyApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchCompanyListApi = async (req: TApiPagReq): Promise<TGetCompanyListResDto> => {
  const url = createUrlWithParams("/companies", req);
  const rs = await clientApi.get<TGetCompanyListResDto>(url);
  return rs.data;
};

export const useCompanyList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST, pagReq],
    queryFn: () => fetchCompanyListApi({ ...pagReq, perPage: 20 }),
    placeholderData: keepPreviousData,
  });
};

export const setCompanyAdminApi = async (companyId: string, userId: number): Promise<TSwitchUserAsAdminResDto> => {
  const rs = await clientApi.put<TSwitchUserAsAdminResDto>(`/companies/${companyId}/users/${userId}/set-as-admin`);
  return rs.data;
};

export const updateCompanyApi = async (companyId: string, payload: TUpdateCompanyReqDto): Promise<TUpdateCompanyResDto> => {
  const rs = await clientApi.put<TUpdateCompanyResDto>(`/companies/${companyId}`, payload);
  return rs.data;
};

export const deleteCompanyApi = async (companyId: string): Promise<TDeleteCompanyResDto> => {
  const rs = await clientApi.delete<TDeleteCompanyResDto>(`/companies/${companyId}`);
  return rs.data;
};
