import { useEffect, useRef, useState } from "react";

type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useCopyToClipboard(): [boolean, CopyFn] {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isCopied) {
      timeoutId.current = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timeoutId.current);
  }, [isCopied]);

  const copy: CopyFn = async (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        return true;
      } catch (error) {
        setIsCopied(false);
        return false;
      }
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        setIsCopied(true);
        return true;
      } catch (error) {
        setIsCopied(false);
        return false;
      } finally {
        textArea.remove();
      }
    }
  };

  return [isCopied, copy];
}
