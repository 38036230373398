import { createMainIndustryApi, updateMainIndustryApi } from "@/api/services/global/master";
import { TGetMainIndustryListOutputDto } from "@/api/services/global/master/dtos/get-master-main-industry-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { masterDataMainIndustrySchema } from "@/features/master-data/schema/masterDataMainIndustrySchema";
import { TMasterDataMainIndustryForm } from "@/features/master-data/types/masterDataMainIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TMainIndustryFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedMainIndustry?: TGetMainIndustryListOutputDto;
};

export const MainIndustryFormModal: FC<TMainIndustryFormModalProps> = ({ onClose, isOpen, selectedMainIndustry }) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      mainIndustry: selectedMainIndustry ? selectedMainIndustry.mainIndustry : undefined,
      gzMainIndustryRenewVisaCsvCode: selectedMainIndustry ? selectedMainIndustry.gzMainIndustryRenewVisaCsvCode : undefined,
    }),
    [selectedMainIndustry],
  );

  const methods = useForm<TMasterDataMainIndustryForm>({
    resolver: yupResolver(masterDataMainIndustrySchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterDataMainIndustryForm) => {
    try {
      if (selectedMainIndustry) {
        const rs = await updateMainIndustryApi({
          mainIndustries: [
            {
              id: toNumber(selectedMainIndustry.id),
              mainIndustry: data.mainIndustry,
              gzMainIndustryRenewVisaCsvCode: data.gzMainIndustryRenewVisaCsvCode,
            },
          ],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createMainIndustryApi({
          mainIndustry: data.mainIndustry,
          gzMainIndustryRenewVisaCsvCode: data.gzMainIndustryRenewVisaCsvCode,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_INDUSTRY] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedMainIndustry ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataMainIndustrySchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={6}>
            <FormField control={control} name="mainIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="gzMainIndustryRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
