import { uploadFileApi } from "@/api/services/global/file";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem/FileItem";
import { useFormField } from "@/components/elements/FormField";
import { FILE_STATUS, TFileUploadFor } from "@/constants/file";
import { button, divider, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { TExtendFile, TExtendFileOption } from "@/types/file";
import { showError } from "@/utils/error";
import { TFileRules, covertMimeTypesToAccepts, getFileErrorMessage, getFileStatusWithRules } from "@/utils/file";
import { makeUuid } from "@/utils/pieces";
import { Box, Stack, Typography, css, styled } from "@mui/material";
import { Key, useCallback, useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export type TDropZoneProps = {
  value?: TExtendFile | TExtendFile[] | null;
  onChange?: (value: TExtendFile | TExtendFile[] | null) => void;
  options?: Omit<DropzoneOptions, "onDrop">;
  enableFileList?: boolean;
  nonControl?: boolean;
  rules?: TFileRules;
  bordered?: boolean;
  disabled?: boolean;
  uploadFor?: TFileUploadFor;
};

export const DropZone: FCC<TDropZoneProps> = ({
  options = {},
  value,
  onChange,
  enableFileList = true,
  children,
  rules = {},
  nonControl = false,
  bordered = true,
  disabled,
  uploadFor,
}) => {
  const { field } = useFormField(nonControl);
  const [files, setFiles] = useState<TExtendFile[]>([field?.value ?? value ?? []].flat());
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (field?.value ?? value) {
      const newValue: TExtendFile[] = [field?.value ?? value].flat();
      if (files.some((file) => !newValue.find(({ id }) => file.id === id))) {
        setFiles([field?.value ?? value].flat());
      }
    }
  }, [value, field]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let newFiles = files;
      setUploading(true);
      const droppedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const fileOption: TExtendFileOption = {
            id: makeUuid(),
            status: getFileStatusWithRules(file, rules),
          };

          if (fileOption.status === FILE_STATUS.OK && uploadFor) {
            try {
              const rs = await uploadFileApi({ file, uploadFor });
              return Object.assign(file, { ...fileOption, filePath: rs.data.filePath });
            } catch (error) {
              showError(error);
              return Object.assign(file, { ...fileOption, status: FILE_STATUS.ERROR });
            }
          }
          return Object.assign(file, fileOption);
        }),
      );

      if (options.multiple) {
        newFiles = [newFiles, droppedFiles].flat();
      } else {
        newFiles = [droppedFiles[0]];
      }
      setFiles(newFiles);
      triggerChangeFiles(newFiles);
      setUploading(false);
    },
    [uploadFor, files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,
    onDrop,
    accept: covertMimeTypesToAccepts(rules.acceptMimeTypes),
    disabled: uploading || disabled,
  });

  const removeFile = (removedId: Key) => {
    const updatedFiles = files.filter(({ id }) => removedId !== id);
    setFiles(updatedFiles);
    onChange?.(updatedFiles);
    triggerChangeFiles(updatedFiles);
  };

  const triggerChangeFiles = (files: TExtendFile[]) => {
    const { multiple } = options;
    onChange?.(multiple === false ? files.at(0) ?? null : files);
    field?.onChange(multiple === false ? files.at(0) ?? null : files);
    field?.onBlur();
  };

  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <DropZoneArea dragged={isDragActive} bordered={bordered}>
          {children ?? (
            <>
              <Typography variant="body14">アップロードするファイルをドラッグ&ドロップ</Typography>
              <Typography variant="body14">または</Typography>
              <Button variant="outline">ファイルを選択する</Button>
            </>
          )}
          {uploading && <UploadingBackup>作成中…</UploadingBackup>}
        </DropZoneArea>
      </Box>
      {enableFileList && (
        <Stack flexDirection="row" flexWrap="wrap" gap={3} mt={files.length ? 1 : 0}>
          {files.map((file) => (
            <FileItem key={file.id} file={file} onRemove={() => removeFile(file.id)} maxWidth={336} errorMsg={getFileErrorMessage(file, rules)} />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const options = { shouldForwardProp: (propName: string) => !["dragged", "bordered"].includes(propName) };

const DropZoneAreaActive = css`
  border-color: ${button.primary};
`;

const DropZoneArea = styled(Stack, options)<{ dragged: boolean; bordered: boolean }>`
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
  border: 1px dashed ${divider.middle};
  border-radius: ${rounded.sm};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    ${DropZoneAreaActive}
  }
  ${({ dragged }) => dragged && DropZoneAreaActive}
  ${({ bordered }) =>
    !bordered &&
    css`
      padding: 0;
      border: none;
    `}
`;

const UploadingBackup = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${button.disable};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${text.white};
`;
