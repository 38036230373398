import * as React from "react";

export const IconFile = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" {...props}>
      <path
        d="M27.2133 11.2133L20.7733 4.77333C20.28 4.28 19.6 4 18.8933 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V13.1067C28 12.4 27.72 11.72 27.2133 11.2133ZM9.33333 9.33333H18.6667V12H9.33333V9.33333ZM22.6667 22.6667H9.33333V20H22.6667V22.6667ZM22.6667 17.3333H9.33333V14.6667H22.6667V17.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};
