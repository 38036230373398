import { TMasterSpecificIndustry } from "@/api/entities/master-specific-industry.entity";
import { setHiddenSpecificIndustryApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenSpecificIndustryModalProps = {
  hiddenSpecificIndustry?: TMasterSpecificIndustry;
  setHiddenSpecificIndustry: Dispatch<SetStateAction<TMasterSpecificIndustry | undefined>>;
};

export const HiddenSpecificIndustryModal: FC<THiddenSpecificIndustryModalProps> = ({ hiddenSpecificIndustry, setHiddenSpecificIndustry }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenSpecificIndustry) return;
    try {
      await setHiddenSpecificIndustryApi(toNumber(hiddenSpecificIndustry.id), { isHidden: !hiddenSpecificIndustry.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenSpecificIndustry(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenSpecificIndustry?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenSpecificIndustry}
      onClose={() => setHiddenSpecificIndustry(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenSpecificIndustry?.isHidden ? "この項目が有効になれますが、よろしいですか。" : "この項目が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
