import { TCreateInformationReqDto, TCreateInformationResDto } from "@/api/services/admin/information/dtos/create-information.dto";
import { TDeleteInformationResDto } from "@/api/services/admin/information/dtos/delete-information.dto";
import { TGetInformationResDto } from "@/api/services/admin/information/dtos/get-infomation.dto";
import { TGetInformationListReqDto, TGetInformationListResDto } from "@/api/services/admin/information/dtos/get-information-list.dto";
import { TUpdateInformationReqDto, TUpdateInformationResDto } from "@/api/services/admin/information/dtos/update-information.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createInformationApi = async (payload: TCreateInformationReqDto): Promise<TCreateInformationResDto> => {
  const rs = await clientApi.post<TCreateInformationResDto>(`/informations`, payload);
  return rs.data;
};

export const deleteInformationApi = async (informationId: number): Promise<TDeleteInformationResDto> => {
  const rs = await clientApi.delete<TDeleteInformationResDto>(`/informations/${informationId}`);
  return rs.data;
};

export const fetchInformationApi = async (informationId: string): Promise<TGetInformationResDto> => {
  const rs = await clientApi.get<TGetInformationResDto>(`/informations/${informationId}`);
  return rs.data;
};

export const useInformation = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.INFORMATION.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchInformationApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchInformationListApi = async (req: TGetInformationListReqDto): Promise<TGetInformationListResDto> => {
  try {
    const url = createUrlWithParams("/informations", { ...req, sorts: ["id|DESC"] });
    const { data } = await clientApi.get<TGetInformationListResDto>(url);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useInformationList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.INFORMATION.FETCH_LIST, pagReq],
    queryFn: () => fetchInformationListApi(pagReq),
    placeholderData: keepPreviousData,
  });
};

export const updateInformationApi = async (informationId: string, payload: TUpdateInformationReqDto): Promise<TUpdateInformationResDto> => {
  const rs = await clientApi.put<TUpdateInformationResDto>(`/informations/${informationId}`, payload);
  return rs.data;
};
