import {
  TGetCompanyEmployeeBillingListReqDto,
  TGetCompanyEmployeeBillingListResDto,
} from "@/api/services/admin/company/company-employee-billings/dtos/get-company-employee-billing-list.dto";
import {
  TSetEnableEmployeeBillingReqDto,
  TSetEnableEmployeeBillingResDto,
} from "@/api/services/admin/company/company-employee-billings/dtos/set-enable-employee-billing.dto";
import { DEFAULT_PAGINATION_REQUEST } from "@/constants/pagination";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useSorting } from "@/hooks/useSorting";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useParams } from "react-router-dom";

export const fetchCompanyEmployeeBillingListApi = async (
  companyId: string | number,
  req: TGetCompanyEmployeeBillingListReqDto,
): Promise<TGetCompanyEmployeeBillingListResDto> => {
  const url = createUrlWithParams(`/companies/${companyId}/employee-billings`, req);
  const { data } = await clientApi.get<TGetCompanyEmployeeBillingListResDto>(url);
  return data;
};

export const useCompanyEmployeeBillingList = (options?: { fetchAll?: boolean }) => {
  const { id } = useParams();
  const { sorts } = useSorting();

  const req = {
    ...DEFAULT_PAGINATION_REQUEST,
    sorts,
  };
  if (options?.fetchAll === true) {
    req.page = 1;
    req.perPage = undefined;
  }
  return useInfiniteScroll([QUERY_KEYS.COMPANY.FETCH_BILLING_LIST, id, req], (page) =>
    fetchCompanyEmployeeBillingListApi(id as string, { ...req, page }),
  );
};

export const changeFirstDayPaymentStatusApi = async (
  companyId: number | string,
  employeeId: number | string,
  payload: TSetEnableEmployeeBillingReqDto,
) => {
  const { data } = await clientApi.put<TSetEnableEmployeeBillingResDto>(
    `/companies/${companyId}/employee-billings/${employeeId}/change-first-day-employee-billing`,
    payload,
  );
  return data;
};

export const changeCurrentPaymentStatusApi = async (
  companyId: number | string,
  employeeId: number | string,
  payload: TSetEnableEmployeeBillingReqDto,
) => {
  const { data } = await clientApi.put<TSetEnableEmployeeBillingResDto>(
    `/companies/${companyId}/employee-billings/${employeeId}/change-current-employee-billing`,
    payload,
  );
  return data;
};
