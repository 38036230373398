import { TMasterSpecificBusinessClassification } from "@/api/entities/master-specific-business-classification.entity";
import { updateSpecificIndustryApi, useGetCompanySpecificIndustryList, useGetResidenceStatusList } from "@/api/services/global/master";
import { TGetSpecificIndustryListOutputDto } from "@/api/services/global/master/dtos/specificIndustry/get-master-specific-industry-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { UpdateSpecificIndustryFormTable } from "@/features/master-data/components/SpecificIndustry/UpdateSpecificIndustryFormTable";
import { masterDataUpdateSpecificIndustrySchema } from "@/features/master-data/schema/masterDataSpecificIndustrySchema";
import { TMasterSpecificIndustryUpdateForm } from "@/features/master-data/types/masterDataSpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

type TUpdateSpecificIndustryFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedSpecificIndustry?: TGetSpecificIndustryListOutputDto;
};

export const UpdateSpecificIndustryFormModal: FC<TUpdateSpecificIndustryFormModalProps> = ({ onClose, isOpen, selectedSpecificIndustry }) => {
  const queryClient = useQueryClient();

  const [selectedBusinessClassificationRows, setSelectedBusinessClassificationRows] = useState<TMasterSpecificBusinessClassification[]>(
    selectedSpecificIndustry?.specificBusinessClassifications ?? [],
  );

  const methods = useForm<TMasterSpecificIndustryUpdateForm>({
    resolver: yupResolver(masterDataUpdateSpecificIndustrySchema),
    mode: "onBlur",
    defaultValues: {
      fgSpecificIndustryRenewVisaCsvCode: selectedSpecificIndustry?.fgSpecificIndustryRenewVisaCsvCode,
      regularAnyTimeSpecificIndustry: selectedSpecificIndustry?.regularAnyTimeSpecificIndustry,
      renewVisaSpecificIndustry: selectedSpecificIndustry?.renewVisaSpecificIndustry,
      companySpecificIndustryId: selectedSpecificIndustry?.companySpecificIndustryId.toString(),
      residenceStatusId: selectedSpecificIndustry?.residenceStatusId.toString(),
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterSpecificIndustryUpdateForm) => {
    if (!selectedSpecificIndustry) return;
    try {
      const rs = await updateSpecificIndustryApi({
        specificIndustries: [
          {
            id: toNumber(selectedSpecificIndustry?.id),
            companySpecificIndustryId: toNumber(data.companySpecificIndustryId),
            residenceStatusId: toNumber(data.residenceStatusId),
            regularAnyTimeSpecificIndustry: data.regularAnyTimeSpecificIndustry,
            renewVisaSpecificIndustry: data.renewVisaSpecificIndustry,
            fgSpecificIndustryRenewVisaCsvCode: data.fgSpecificIndustryRenewVisaCsvCode,
            specificBusinessClassifications: selectedBusinessClassificationRows.map((item) => ({
              id: toNumber(item.id),
              specificBusinessClassificationName: item.specificBusinessClassificationName,
              fhSpecificBusinessClassificationRenewVisaCsvCode: item.fhSpecificBusinessClassificationRenewVisaCsvCode,
              companySpecificBusinessClassificationId: toNumber(item.companySpecificBusinessClassificationId),
              anyTimeSpecificBusinessClassificationName: item.anyTimeSpecificBusinessClassificationName,
              specificBusinessClassificationAnyTimeCsvCode: item.specificBusinessClassificationAnyTimeCsvCode,
            })),
          },
        ],
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const { isLoading: isLoadingResidenceStatus, residenceStatusOptions } = useGetResidenceStatusList();

  const { isLoading: isLoadingCompanySpecificIndustry, companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataUpdateSpecificIndustrySchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustryId">
              <Select loading={isLoadingCompanySpecificIndustry} options={companySpecificIndustryOptions} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatusId">
              <Select loading={isLoadingResidenceStatus} options={residenceStatusOptions} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="regularAnyTimeSpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="renewVisaSpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="fgSpecificIndustryRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">業務区分（外国人用、期間更新の表記）</Typography>
              <UpdateSpecificIndustryFormTable
                selectedBusinessClassificationRows={selectedBusinessClassificationRows}
                setSelectedBusinessClassificationRows={setSelectedBusinessClassificationRows}
                existingSpecificIndustryId={toNumber(selectedSpecificIndustry?.id)}
                existingRegularAnyTimeSpecificIndustry={selectedSpecificIndustry?.regularAnyTimeSpecificIndustry ?? EMPTY_STR.TEXT}
                existingRenewVisaSpecificIndustry={selectedSpecificIndustry?.renewVisaSpecificIndustry ?? EMPTY_STR.TEXT}
                existingFgSpecifiedIndustryRenewVisaCsvCode={selectedSpecificIndustry?.fgSpecificIndustryRenewVisaCsvCode ?? EMPTY_STR.TEXT}
                existingCompanySpecificIndustry={selectedSpecificIndustry?.companySpecificIndustry.companySpecificIndustry ?? EMPTY_STR.TEXT}
                existingCompanySpecificIndustryId={toNumber(selectedSpecificIndustry?.companySpecificIndustryId)}
                existingResidenceStatus={selectedSpecificIndustry?.residenceStatus.residenceStatus ?? EMPTY_STR.TEXT}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
