import { TRecoverRequest } from "@/features/auth/types/recoverRequest";
import { clientApi } from "@/utils/api";
import { TApiRes } from "@/types/api";

export const requestResetPasswordApi = async (payload: TRecoverRequest): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await clientApi.post<TApiRes<boolean>>("/request-reset-password", payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
