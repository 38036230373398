import { TCompany } from "@/api/entities/company.entity";
import { TCreateUserReqDto, TCreateUserResDto } from "@/api/services/admin/company/user/dtos/create-user.dto";
import { TDeleteUserResDto } from "@/api/services/admin/company/user/dtos/delete-user.dto";
import { TGetUserListResDto } from "@/api/services/admin/company/user/dtos/get-user-list.dto";
import { TGetUserResDto } from "@/api/services/admin/company/user/dtos/get-user.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompanyUserForm } from "@/features/company/types/companyUserForm";
import { TApiRes } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const createCompanyUserApi = async (companyId: string, payload: TCreateUserReqDto): Promise<TCreateUserResDto> => {
  const rs = await clientApi.post<TCreateUserResDto>(`/companies/${companyId}/users`, payload);
  return rs.data;
};

export const deleteCompanyUserApi = async (companyId: number, userId: number): Promise<TDeleteUserResDto> => {
  const rs = await clientApi.delete<TDeleteUserResDto>(`/companies/${companyId}/users/${userId}`);
  return rs.data;
};

export const fetchCompanyUserApi = async (companyId: string, userId: string): Promise<TGetUserResDto> => {
  const rs = await clientApi.get<TGetUserResDto>(`/companies/${companyId}/users/${userId}`);
  return rs.data;
};

export const useCompanyUser = () => {
  const { id, userId } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_DETAIL, id, userId],
    queryFn: () => (id && userId ? fetchCompanyUserApi(id, userId) : undefined),
    enabled: Boolean(id && userId),
  });
};

export const fetchCompanyUserListApi = async (companyId: string): Promise<TGetUserListResDto> => {
  const url = createUrlWithParams(`/companies/${companyId}/users`, { sorts: ["id|ASC"] });
  const rs = await clientApi.get<TGetUserListResDto>(url);
  return rs.data;
};

export const useCompanyUserList = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST, id],
    queryFn: () => (id ? fetchCompanyUserListApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const updateCompanyUserApi = async (companyId: string, userId: string, payload: TCompanyUserForm): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.put<TApiRes<TCompany>>(`/companies/${companyId}/users/${userId}`, {
      ...payload,
      isCompanyUser: Boolean(payload.isCompanyUser),
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
