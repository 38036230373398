import { Button } from "@/components/elements/Button";
import { Pagination } from "@/components/elements/Pagination";
import { useAdminUserList } from "@/api/services/admin/user";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";

type TAdminUserListTopProps = {};

export const AdminUserListTop: FC<TAdminUserListTopProps> = () => {
  const { data } = useAdminUserList();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={data?.pagination.totalPage} />
      <Button component={Link} size="sm" variant="primary" to={AppRoutes.adminUserCreate}>
        メンバー追加
      </Button>
    </Stack>
  );
};
