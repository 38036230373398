import { deleteInformationApi, useInformationList } from "@/api/services/admin/information";
import { TGetInformationListDto } from "@/api/services/admin/information/dtos/get-information-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";
import { Button } from "@/components/elements/Button";
import { Table, TableSorting } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { DATE_FORMAT } from "@/constants/datetime";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useDialog } from "@/hooks/useDialog";
import { useTable } from "@/hooks/useTable";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { dayjs } from "@/utils/dayjs";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export const InformationTable = () => {
  const { data: informationTypes } = useEnum("EInformationType");
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmDelete = useDialog();

  const handleDelete = async (id: number) => {
    try {
      const agreeToDelete = await confirmDelete.show();
      if (!agreeToDelete) return;
      const rs = await deleteInformationApi(id);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INFORMATION.FETCH_LIST] });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    }
  };

  const columns = useMemo<MRT_ColumnDef<TGetInformationListDto>[]>(
    () => [
      {
        header: "ステータス",
        size: 60,
        Cell: ({ row }) => {
          return row.original.isDraft ? "下書き" : "公開済み";
        },
      },
      {
        header: "タイトル",
        Cell: ({ row }) => {
          return row.original.title || EMPTY_STR.TEXT;
        },
      },
      {
        header: "タグ",
        size: 120,
        Cell: ({ row }) => {
          return row.original.type ? findLabelByValue(informationTypes, row.original.type) : EMPTY_STR.TEXT;
        },
      },
      {
        header: "配信開始日",
        size: 120,
        Cell: ({ row }) => {
          const { startDate } = row.original;
          return startDate ? dayjs(startDate).format(DATE_FORMAT) : EMPTY_STR.DATE;
        },
      },
      {
        header: "配信終了日",
        size: 120,
        Cell: ({ row }) => {
          const { endDate } = row.original;
          return endDate ? dayjs(endDate).format(DATE_FORMAT) : EMPTY_STR.DATE;
        },
      },
      {
        header: "削除",
        id: "delete",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button size="sm" variant="outline" onClick={() => handleDelete(id)}>
              削除
            </Button>
          );
        },
        size: 100,
      },
      {
        header: "修正・更新",
        id: "edit",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button component={Link} size="sm" to={getDynamicRoute(AppRoutes.informationEdit, { id })}>
              修正
            </Button>
          );
        },
        size: 100,
      },
    ],
    [informationTypes],
  );

  const { data, isLoading } = useInformationList();
  const { table } = useTable({ columns, rows: data?.data ?? [], pinningColumns: ["delete", "edit"] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      <ConfirmDeleteModal
        open={confirmDelete.open}
        onClose={confirmDelete.cancel}
        onOk={confirmDelete.confirm}
        message="お知らせを削除してもよろしいですか？"
      />
    </>
  );
};
