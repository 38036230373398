import { TopPage } from "@/components/elements/TopPage";
import { InformationListTop } from "@/features/information/components/InformationListTop";
import { InformationTable } from "@/features/information/components/InformationTable";

import { Box, Stack } from "@mui/material";
import { FC } from "react";

type TInformationPageProps = {};

export const InformationPage: FC<TInformationPageProps> = () => {
  return (
    <Box>
      <TopPage title="お知らせ一覧" enablePageBack={false} />
      <Stack gap={3}>
        <InformationListTop />
        <InformationTable />
      </Stack>
    </Box>
  );
};
