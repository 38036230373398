import { deleteContactTypeApi } from "@/api/services/admin/contact-type";
import { TGetContactTypeListDto, TGetContactTypeListResDto } from "@/api/services/contact-type/dtos/get-contact-type-list.dto";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";
import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useDialog } from "@/hooks/useDialog";
import { useTable } from "@/hooks/useTable";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef } from "material-react-table";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

type ContactTypeTableProps = {
  isLoading: boolean;
  data: TGetContactTypeListResDto;
};

export const ContactTypeTable: FC<ContactTypeTableProps> = ({ isLoading, data }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmDelete = useDialog();

  const handleDelete = async (id: number) => {
    try {
      const agreeToDelete = await confirmDelete.show();
      if (!agreeToDelete) return;
      const rs = await deleteContactTypeApi(id);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_LIST] });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    }
  };

  const columns = useMemo<MRT_ColumnDef<TGetContactTypeListDto>[]>(
    () => [
      {
        header: "お問い合わせの種類",
        size: 120,
        Cell: ({ row }) => {
          return row.original.name || EMPTY_STR.TEXT;
        },
      },
      {
        header: "お問い合わせの通知先メールアドレス",
        Cell: ({ row }) => {
          return row.original.email || EMPTY_STR.TEXT;
        },
        size: 120,
      },
      {
        header: "削除",
        id: "delete",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button
              disabled={id.toString() === "1" || id.toString() === "2" || id.toString() === "3"}
              size="sm"
              variant="outline"
              onClick={() => handleDelete(id)}
            >
              削除
            </Button>
          );
        },
        size: 100,
      },
      {
        header: "修正・更新",
        id: "edit",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Button component={Link} size="sm" to={getDynamicRoute(AppRoutes.contactTypeEdit, { id })}>
              修正
            </Button>
          );
        },
        size: 100,
      },
    ],
    [],
  );
  const { table } = useTable({ columns, rows: data?.data ?? [], pinningColumns: ["delete", "edit"] });

  return (
    <>
      <Table table={table} isLoading={isLoading} />
      <ConfirmDeleteModal
        open={confirmDelete.open}
        onClose={confirmDelete.cancel}
        onOk={confirmDelete.confirm}
        message="お問い合わせを削除してもよろしいですか？"
      />
    </>
  );
};
