import { IconClose, IconVisibility, IconVisibilityOff } from "@/assets/icons";
import { useFormSchema } from "@/components/elements/Form";
import { useFormField } from "@/components/elements/FormField/FormField";
import { IconBox } from "@/components/elements/IconBox";
import { useDebounce } from "@/hooks/useDebounce";
import { useMount } from "@/hooks/useMount";
import { useVisible } from "@/hooks/useVisible";
import { background, blue, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { convertNumberToZipCode, formatDecimal, handleKatakanaToFurigana, handleZipCodeChange } from "@/utils/input";
import { InputAdornment, TextField, TextFieldProps, css, styled } from "@mui/material";
import { ChangeEvent, FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";

export type TInputSize = "md" | "lg";

export type TInputProps = {
  size?: TInputSize;
  readonly?: boolean;
  previousValue?: string | number;
  clearable?: boolean;
  nonControl?: boolean;
  endAdornment?: ReactNode;
  previous?: boolean;
  pattern?: RegExp;
  cleanWhitespace?: boolean;
  numberFormat?: boolean;
} & Omit<TextFieldProps, "size" | "readOnly">;

export const Input: FC<TInputProps> = ({
  size = "md",
  readonly = false,
  previousValue,
  clearable = true,
  nonControl = false,
  placeholder = "入力してください",
  endAdornment,
  type,
  cleanWhitespace,
  numberFormat,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>();
  const { field, fieldState } = useFormField(nonControl);
  const { visible, toggle } = useVisible();
  const value = field ? field.value : rest.value;
  const isMounted = useMount();
  const fieldName = field?.name;
  const fieldsMeta = useFormSchema();

  const handleClear = () => {
    if (!ref.current) return;
    ref.current.value = "";
    field?.onChange("");
    field?.onBlur();

    rest.onChange?.({ target: ref.current } as ChangeEvent<HTMLInputElement>);
  };

  const inputEndAdornment = useMemo(() => {
    if (value) {
      if (!clearable || readonly || rest.disabled) return endAdornment;
      if (type === "password") {
        return (
          <InputAdornment position="end">
            <IconBox onClick={toggle}>{visible ? <IconVisibilityOff fontSize="20px" /> : <IconVisibility fontSize="20px" />}</IconBox>
          </InputAdornment>
        );
      }
      return (
        <InputAdornment position="end">
          <IconBox onClick={handleClear}>
            <IconClose fontSize="20px" />
          </IconBox>
        </InputAdornment>
      );
    }
    return endAdornment;
  }, [value, clearable, endAdornment, readonly, rest.disabled, visible]);

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(previousValue) && (field?.value ?? rest.value) == previousValue;
  }, [fieldState?.isDirty, previousValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    let newValue = input.value;

    if (fieldName && fieldsMeta[fieldName]?.meta?.isZipCode) {
      newValue = handleZipCodeChange(newValue);
    }
    if (fieldName && fieldsMeta[fieldName]?.meta?.isFurigana) {
      newValue = handleKatakanaToFurigana(newValue);
    }

    if (numberFormat || (fieldName && fieldsMeta[fieldName]?.type === "number" && numberFormat !== false)) newValue = formatDecimal(newValue);

    input.value = newValue;

    field?.onChange(e);
    rest.onChange?.(e);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (cleanWhitespace) {
      e.target.value = e.target.value.replace(/\s+/g, " ").trim();
    }
    field?.onChange(e);
    field?.onBlur();
    rest.onChange?.(e);
  };

  const inputValue = useMemo(() => {
    if (value?.toString()) {
      if (fieldName && fieldsMeta[fieldName]?.meta?.isZipCode) {
        const zipCodeValue = String(value).replace(/-/g, "");
        if (zipCodeValue.length > 3) {
          return convertNumberToZipCode(zipCodeValue);
        }
        return zipCodeValue;
      }
      if (numberFormat || (fieldName && fieldsMeta[fieldName]?.type === "number" && numberFormat !== false)) return formatDecimal(String(value)) || 0;
      return value;
    }
    return "";
  }, [value, isMounted]);

  return (
    <StyledInput
      error={Boolean(fieldState?.error)}
      inputSize={size}
      inputProps={{ ref }}
      readonly={readonly}
      disabled={readonly}
      previous={isPrevious}
      InputProps={{
        endAdornment: inputEndAdornment,
      }}
      type={type === "password" && visible ? "text" : type}
      placeholder={type === "password" ? "••••••••" : placeholder}
      {...rest}
      {...field}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export const DebounceInput: FC<TInputProps> = (props) => {
  const { field, fieldState } = useFormField(props.nonControl);
  const [value, setValue] = useState(field?.value ?? props.value ?? "");
  const { pattern, error } = props;

  useDebounce(() => {
    if (value === (field?.value ?? props.value)) return;
    field?.onChange(value);
    props.onChange?.({ target: { value } } as ChangeEvent<HTMLInputElement>);
  }, [value]);

  useEffect(() => {
    setValue(field?.value ?? props.value ?? "");
  }, [field?.value, props.value]);

  const onBlur = () => {
    field?.onChange(value);
    props.onChange?.({ target: { value } } as ChangeEvent<HTMLInputElement>);
    field?.onBlur();
  };

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(props.previousValue) && value === props.previousValue;
  }, [fieldState?.isDirty, props.previousValue, value]);

  return (
    <Input
      {...props}
      error={error || (pattern ? !pattern.test(value) : undefined)}
      previous={isPrevious}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

const options = { shouldForwardProp: (propName: string) => !["inputSize", "previous", "readonly"].includes(propName) };

const StyledInput = styled(TextField, options)<{ inputSize: TInputSize; readonly: boolean; previous: boolean }>`
  .MuiInputBase-root {
    background: ${background.white};
    padding-right: 8px;
    white-space: nowrap;
    input {
      color: ${text.primary};
      caret-color: ${blue[70]};
      &::placeholder {
        opacity: 1;
        color: ${text.tertiary}!important;
      }
    }
    fieldset {
      border-color: ${divider.middle};
      border-radius: ${rounded.xs};
      border-width: 1px !important;
    }
    &:hover,
    &.Mui-focused {
      fieldset {
        border-color: ${blue[70]};
      }
    }
    &.Mui-error {
      background: ${background.error};
      fieldset {
        border-color: ${state.error_1}!important;
      }
    }
    &.Mui-disabled {
      background: ${background.disable};
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    ${({ inputSize }) => {
      if (inputSize === "md") return MediumInput;
      if (inputSize === "lg") return LargeInput;
      return MediumInput;
    }};
    ${({ readonly }) => readonly && readonlyInput};
    ${({ previous }) => previous && PreviousInput};
  }
`;

const readonlyInput = css`
  background: ${background.disable} !important;
  pointer-events: none;
  -webkit-text-fill-color: ${text.primary};
  input {
    color: ${text.primary}!important;
    -webkit-text-fill-color: ${text.primary};
    &::placeholder {
      opacity: 0;
    }
  }
  fieldset {
    border-color: ${divider.middle}!important;
  }
`;

const PreviousInput = css`
  input {
    color: ${lightBlue[60]};
  }
`;

const MediumInput = css`
  .MuiInputBase-input {
    padding: 6px 8px;
    ${css(typography.body14)};
  }
`;

const LargeInput = css`
  .MuiInputBase-input {
    padding: 12px 8px;
    ${css(typography.body14)};
  }
`;
