import { TMasterNationality } from "@/api/entities/master-nationality.entity";
import { createNationalityApi, updateNationalityApi, useGetRegionList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { nationalitySchema } from "@/features/master-data/schema/masterDataNationalitySchema";
import { TNationalityForm } from "@/features/master-data/types/masterDataNationalityForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useForm } from "react-hook-form";

type TNationalityFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedNationality?: TMasterNationality;
  setRows: Dispatch<SetStateAction<TMasterNationality[]>>;
};

export const NationalityFormModal: FC<TNationalityFormModalProps> = ({ onClose, isOpen, selectedNationality, setRows }) => {
  const { isLoading: isLoadingRegionList, regionOptions } = useGetRegionList();

  const defaultValues = useMemo(
    () => ({
      regionId: selectedNationality ? selectedNationality.regionId.toString() : undefined,
      nationalityName: selectedNationality ? selectedNationality.nationalityName : undefined,
      engNationalityName: selectedNationality ? selectedNationality.engNationalityName : undefined,
      nationalRenewVisaCsvCode: selectedNationality ? selectedNationality.nationalRenewVisaCsvCode : undefined,
    }),
    [selectedNationality],
  );

  const methods = useForm<TNationalityForm>({
    resolver: yupResolver(nationalitySchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TNationalityForm) => {
    try {
      if (selectedNationality) {
        const rs = await updateNationalityApi(selectedNationality.id, {
          regionId: toNumber(data.regionId),
          engNationalityName: data.engNationalityName,
          nationalityName: data.nationalityName,
          nationalRenewVisaCsvCode: data.nationalRenewVisaCsvCode,
        });
        setRows((prev) => {
          const arr = prev.map((item) => (item.id === rs.data.id ? rs.data : item));
          return arr;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createNationalityApi({
          regionId: toNumber(data.regionId),
          engNationalityName: data.engNationalityName,
          nationalityName: data.nationalityName,
          nationalRenewVisaCsvCode: data.nationalRenewVisaCsvCode,
        });
        setRows((prev) => {
          const lastIndex = prev.findLastIndex((item) => item.region.regionName === rs.data.region.regionName);
          const updated = prev.toSpliced(lastIndex + 1, 0, rs.data);
          return updated;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedNationality ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={nationalitySchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={12}>
            <FormField control={control} name="regionId">
              <Select loading={isLoadingRegionList} options={regionOptions} />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="nationalityName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="engNationalityName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField control={control} name="nationalRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
