import { TGetEncryptSecretKeyResDto } from "@/api/services/global/constant/dtos/get-encrypt-secret-key.dto";
import { TGetProvincesAndMunicipalitiesResDto } from "@/api/services/global/constant/dtos/get-provinces-municipalities.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { TOption } from "@/types/common";
import { globalApi } from "@/utils/api";
import { toNumber } from "@/utils/number";
import { decrypt } from "@/utils/pieces";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useCallback, useMemo } from "react";

type TUseGetProvincesAndMunicipalitiesParams = {
  province?: string;
};

export const fetchProvincesAndMunicipalitiesApi = async (): Promise<TGetProvincesAndMunicipalitiesResDto> => {
  const rs = await globalApi.get<TGetProvincesAndMunicipalitiesResDto>(`/provinces-and-municipalities`);
  return rs.data;
};

export const useGetProvincesAndMunicipalities = (params?: TUseGetProvincesAndMunicipalitiesParams) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.PROVINCES_MUNICIPALITIES],
    queryFn: () => fetchProvincesAndMunicipalitiesApi(),
  });

  const provinceOptions: TOption[] = useMemo(() => {
    if (!data) return [];
    return data.data.map((item) => ({ label: item.prefectureName, value: item.prefectureCode.toString() }));
  }, [data]);

  const municipalitiesOptions: TOption[] = useMemo(() => {
    if (!data || !params?.province) return [];
    const provinceByWatch = data.data.find((item) => item.prefectureCode === toNumber(params.province));
    if (!provinceByWatch) return [];
    return provinceByWatch.municipalities.map((item) => ({ label: item.municipalityName, value: item.municipalityCode.toString() }));
  }, [data, params?.province]);

  const getMunicipalityOptionsByProvince = useCallback(
    (provinceCode: string) => {
      if (!data) return [];
      const provinceByWatch = data.data.find((item) => item.prefectureCode === toNumber(provinceCode));
      if (!provinceByWatch) return [];
      return provinceByWatch.municipalities.map((item) => ({ label: item.municipalityName, value: item.municipalityCode.toString() }));
    },
    [data],
  );

  const getMunicipalityOptionsByMultiProvince = useCallback(
    (provinceCode: string[]) => {
      if (!data) return [];
      const provincesByWatch = data.data.map((item) => (provinceCode.includes(item.prefectureCode.toString()) ? item : null));
      if (provincesByWatch.length === 0) return [];
      return provincesByWatch
        .flatMap((item) => (item ? item.municipalities : []))
        .map((item) => ({ label: item.municipalityName, value: item.municipalityCode.toString() }));
    },
    [data],
  );

  return {
    data: data?.data ?? [],
    ...rest,
    municipalitiesOptions,
    provinceOptions,
    getMunicipalityOptionsByProvince,
    getMunicipalityOptionsByMultiProvince,
  };
};

export const fetchEncryptSecretKeyApi = async (): Promise<TGetEncryptSecretKeyResDto> => {
  const rs = await globalApi.get<TGetEncryptSecretKeyResDto>(`/encrypt-secret-key`);
  const encodedSecretKey = rs.data?.data;
  const accessToken = Cookies.get("admin-token");
  if (encodedSecretKey && accessToken) {
    rs.data.data = decrypt(encodedSecretKey, accessToken);
  }
  return rs.data;
};

export const useGetEncryptSecretKeyApi = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.ENCRYPT_SECRET_KEY],
    queryFn: () => fetchEncryptSecretKeyApi(),
  });
  return {
    data: data?.data,
    ...rest,
  };
};
