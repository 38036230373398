import { createMajorApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CreateMajorFormTable } from "@/features/master-data/components/JobType/CreateMajorFormTable";
import { masterDataCreateMajorSchema } from "@/features/master-data/schema/masterDataJobTypeSchema";
import { TMasterMajorCreateForm } from "@/features/master-data/types/masterDataJobTypeForm";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";

type TCreateMajorFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateMajorFormModal: FC<TCreateMajorFormModalProps> = ({ onClose, isOpen }) => {
  const queryClient = useQueryClient();

  const methods = useForm<TMasterMajorCreateForm>({
    resolver: yupResolver(masterDataCreateMajorSchema),
    mode: "onBlur",
    defaultValues: {
      majorName: "",
      coMajorRenewVisaCsvCode: "",
      jobTypes: [
        {
          cpJobRenewVisaCsvCode: "",
          jobName: "",
        },
      ],
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterMajorCreateForm) => {
    try {
      const rs = await createMajorApi({
        majorName: data.majorName,
        coMajorRenewVisaCsvCode: data.coMajorRenewVisaCsvCode,
        jobTypes: data.jobTypes.map((item) => ({
          cpJobRenewVisaCsvCode: item.cpJobRenewVisaCsvCode,
          jobName: item.jobName,
        })),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataCreateMajorSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="majorName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="coMajorRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">作業</Typography>
              <CreateMajorFormTable />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit" disabled={methods.watch("jobTypes").length === 0}>
            保存
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};
