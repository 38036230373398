import { LoadingScreen } from "@/components/elements/Loading/Loading";
import { clientApi, isAuth, setAxiosAuth } from "@/utils/api";
import { AdminRoutes } from "@/routes/config";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const withAuth: <CP extends {}>(WrappedComponent: FC<CP>) => FC<CP> = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [userId] = useState(Cookies.get("admin-user-id"));
  const navigate = useNavigate();

  useEffect(() => {
    // set axios auth at init
    if (isAuth() && !clientApi.defaults.headers.common.Authorization) {
      setAxiosAuth();
    }
    // Every 2 seconds check user id, if user id has changed then redirect to login page
    setInterval(() => {
      if (userId && userId != Cookies.get("admin-user-id")) {
        location.href = "/login";
      }
    }, 2000);
  }, []);

  useEffect(() => {
    // verify admin routes
    if (!isAuth() && AdminRoutes.some((path) => pathname.startsWith(path))) {
      location.href = "/login";
    }

    setIsVerified(true);
  }, [pathname, navigate]);

  if (!isVerified) return <LoadingScreen />;

  return <WrappedComponent {...props} />;
};
