import { MasterDataCompanySpecificIndustry } from "@/features/master-data/components/CompanySpecificIndustry/MasterDataCompanySpecificIndustry";
import { MasterDataJobType } from "@/features/master-data/components/JobType/MasterDataJobType";
import { MasterDataLanguageTest } from "@/features/master-data/components/LanguageTest/MasterDataLanguageTest";
import { MasterDataLivingRelationship } from "@/features/master-data/components/LivingRelationship/MasterDataLivingRelationship";
import { MasterDataLocalGovernmentCounter } from "@/features/master-data/components/LocalGovernmentCounter/MasterDataLocalGovernmentCounter";
import { MasterDataMainIndustry } from "@/features/master-data/components/MainIndustry/MasterDataMainIndustry";
import { MasterDataMainOccupation } from "@/features/master-data/components/MainOccupation/MasterDataMainOccupation";
import { MasterDataNationality } from "@/features/master-data/components/Nationality/MasterDataNationality";
import { MasterDataResidencePeriod } from "@/features/master-data/components/ResidencePeriod/MasterDataResidencePeriod";
import { MasterDataSkillTest } from "@/features/master-data/components/SkillTest/MasterDataSkillTest";
import { MasterDataSpecificIndustry } from "@/features/master-data/components/SpecificIndustry/MasterDataSpecificIndustry";
import { MASTER_DATA_TYPE } from "@/features/master-data/constants/masterDataType";
import FeatureNotReadyPage from "@/pages/FeatureNotReadyPage";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

export const MasterDataDetailPage: FC = () => {
  const { type: masterDataType } = useParams();

  const MasterDataDetailComponent = useMemo(() => {
    switch (masterDataType) {
      case MASTER_DATA_TYPE.NATIONALITY:
        return <MasterDataNationality />;
      case MASTER_DATA_TYPE.LIVING_RELATIONSHIP:
        return <MasterDataLivingRelationship />;
      case MASTER_DATA_TYPE.MAIN_OCCUPATION:
        return <MasterDataMainOccupation />;
      case MASTER_DATA_TYPE.LANGUAGE_TEST:
        return <MasterDataLanguageTest />;
      case MASTER_DATA_TYPE.SKILL_TEST:
        return <MasterDataSkillTest />;
      case MASTER_DATA_TYPE.MAIN_INDUSTRY:
        return <MasterDataMainIndustry />;
      case MASTER_DATA_TYPE.COMPANY_SPECIFIC_INDUSTRY:
        return <MasterDataCompanySpecificIndustry />;
      case MASTER_DATA_TYPE.JOB_TYPE:
        return <MasterDataJobType />;
      case MASTER_DATA_TYPE.SPECIFIC_INDUSTRY:
        return <MasterDataSpecificIndustry />;
      case MASTER_DATA_TYPE.RESIDENCE_PERIOD:
        return <MasterDataResidencePeriod />;
      case MASTER_DATA_TYPE.LOCAL_GOVERNMENT_COUNTER:
        return <MasterDataLocalGovernmentCounter />;

      default:
        return <FeatureNotReadyPage />;
    }
  }, [masterDataType]);

  return <>{MasterDataDetailComponent}</>;
};
