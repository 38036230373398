import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Table } from "@/components/elements/Table";
import { TMasterResidenceStatusCreateForm, TResidencePeriodForm } from "@/features/master-data/types/masterDataResidencePeriodForm";
import { useFormTable } from "@/hooks/useFormTable";
import { Box, styled } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useFormContext } from "react-hook-form";

export const CreateResidenceStatusFormTable = () => {
  const { control } = useFormContext<TMasterResidenceStatusCreateForm>();

  const formColumns: MRT_ColumnDef<TResidencePeriodForm>[] = [
    {
      header: "在留期間",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`residencePeriods.${row.index}.residencePeriod`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "在留期間の期間更新CSVのコード　BP列",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`residencePeriods.${row.index}.residencePeriodRenewVisaCsvCode`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "希望する在留期間",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`residencePeriods.${row.index}.desiredResidencePeriod`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "希望する在留期間の期間更新CSVのコード　BU列",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`residencePeriods.${row.index}.desiredResidencePeriodRenewVisaCsvCode`}>
            <Input />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({
    columns: formColumns,
    control,
    name: "residencePeriods",
    initFieldData: {
      desiredResidencePeriod: "",
      desiredResidencePeriodRenewVisaCsvCode: "",
      residencePeriod: "",
      residencePeriodRenewVisaCsvCode: "",
    },
    primaryKey: "id",
  });

  return (
    <TableWrapper>
      <Table table={table} maxHeight={400} />
    </TableWrapper>
  );
};

const TableWrapper = styled(Box)`
  .MuiTableHead-root {
    th {
      font-size: 14px;
    }
  }
`;
