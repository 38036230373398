import { TopPage } from "@/components/elements/TopPage";
import { getDynamicRoute } from "@/utils/url";
import { Stack, styled } from "@mui/material";
import { FC } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { TSettingMenu } from "@/features/system-setting/types/settingMenu";
import { settingMenu } from "@/features/system-setting/constants/settingMenu";

type TSettingLayoutProps = {};

export const SettingLayout: FC<TSettingLayoutProps> = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const renderRoutes = (menu: TSettingMenu[]) => {
    return menu.map(({ title, url }) => {
      const isActive = pathname.startsWith(url);

      return (
        <StyledLink isActive={isActive} key={url} className={isActive ? "active" : ""} to={getDynamicRoute(url, { id })}>
          {title}
        </StyledLink>
      );
    });
  };

  return (
    <Stack gap={2.5}>
      <TopPage title="システム設定" enablePageBack={false} />
      <Stack direction="row" gap={10}>
        {renderRoutes(settingMenu)}
      </Stack>
      <Outlet />
    </Stack>
  );
};

const options = { shouldForwardProp: (propName: string) => !["isActive"].includes(propName) };

const StyledLink = styled(
  Link,
  options,
)<{ isActive: boolean }>(({ isActive }) => ({
  textDecoration: isActive ? "underline" : "none",
}));
