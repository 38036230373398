import { setCompanyAdminApi } from "@/api/services/admin/company";
import { useCompanyUserList } from "@/api/services/admin/company/user";
import { TGetUserListDto } from "@/api/services/admin/company/user/dtos/get-user-list.dto";
import { useEnum } from "@/api/services/global/enum";
import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { useTable } from "@/hooks/useTable";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { getDynamicRoute } from "@/utils/url";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

export const CompanyUserTable = () => {
  const { data: userActiveStatuses } = useEnum("EUserActiveStatus");
  const { id } = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: companyUserListResult, isLoading } = useCompanyUserList();
  const companyUserListData = companyUserListResult?.data;

  const columns = useMemo<MRT_ColumnDef<TGetUserListDto>[]>(
    () => [
      {
        header: "ユーザー名",
        id: "fullName",
        accessorKey: "fullName",
      },
      {
        header: "メールアドレス",
        id: "email",
        accessorKey: "email",
      },
      {
        header: "所属",
        size: 120,
        Cell: ({ row }) => (row.original.isCompanyUser ? "企業ユーザー" : "irohana担当者"),
      },
      {
        header: "ステータス",
        size: 120,
        Cell: ({ row }) => (row.original.activeStatus ? findLabelByValue(userActiveStatuses, row.original.activeStatus) : EMPTY_STR.TEXT),
      },
      {
        header: "管理者",
        id: "manager",
        Cell: ({ row }) => {
          if (row.original.isCompanyAdmin) {
            return (
              <Button size="sm" variant="primary" disabled>
                現在管理者
              </Button>
            );
          }
          return (
            <Button size="sm" variant="primary" onClick={() => setCompanyAdmin(row.original.id)}>
              管理者にする
            </Button>
          );
        },
        size: 120,
      },
      {
        header: "編集",
        id: "edit",
        Cell: ({ row }) => {
          return (
            <Button component={Link} size="sm" variant="primary" to={getDynamicRoute(AppRoutes.companyUserEdit, { id, userId: row.original.id })}>
              編集
            </Button>
          );
        },
        size: 120,
      },
    ],
    [userActiveStatuses],
  );

  const { table } = useTable({ columns, rows: companyUserListData ?? [], pinningColumns: ["manager", "edit"] });

  const setCompanyAdmin = async (userId: number) => {
    try {
      if (!id) throw new Error();
      const rs = await setCompanyAdminApi(id, userId);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST, id] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="sub16Bold">企業ユーザー</Typography>
        <Button component={Link} to={getDynamicRoute(AppRoutes.companyUserCreate, { id })} size="sm" variant="outline">
          企業ユーザー追加
        </Button>
      </Stack>
      <Table table={table} isLoading={isLoading} />
    </Stack>
  );
};
