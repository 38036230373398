import { useConvertDetail } from "@/api/services/admin/convert-request";
import { useEnum } from "@/api/services/global/enum";
import { InnerBox } from "@/components/elements/InnerBox";
import { EMPTY_STR } from "@/constants/string";
import {
  isAnytimeEmploymentContractData,
  isAnytimeSupportContractData,
  isAnytimeSupportPlanData,
  isRetirementAcceptingWorkerData,
  isRetirementContractData,
} from "@/features/convert/utils/checkTypes";
import { findLabelByValue } from "@/utils/object";
import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

export const ConvertSummary = () => {
  const { data: convertResult } = useConvertDetail();
  const { data: documentTypeOptions } = useEnum("EDocumentType");
  const { data: convertNameOptions } = useEnum("EConvertDocumentName");

  const convertData = convertResult?.data;

  const renderDocumentName = useMemo(() => {
    try {
      if (convertData?.json) {
        const docJSON = JSON.parse(typeof convertData.json === "object" ? JSON.stringify(convertData.json) : convertData.json);
        if (isAnytimeEmploymentContractData(docJSON)) return "雇用契約の変更に係る届出";
        if (isAnytimeSupportContractData(docJSON)) return "支援委託契約に係る届出";
        if (isAnytimeSupportPlanData(docJSON)) return "支援計画変更に係る届出";
        if (isRetirementAcceptingWorkerData(convertData.documentName, convertData.documentType, docJSON)) return "受入れ困難に係る届出";
        if (isRetirementContractData(convertData.documentName, convertData.documentType, docJSON)) return "特定技能雇用契約に係る届出";
      }
    } catch {
      // do nothing
    }
    return findLabelByValue(convertNameOptions, convertData?.documentName) || EMPTY_STR.TEXT;
  }, []);

  const renderDocumentCategory = findLabelByValue(documentTypeOptions, convertData?.documentType) || EMPTY_STR.TEXT;

  return (
    <InnerBox height="100%">
      <Stack gap={1.5}>
        <Typography variant="sub16Bold">届出の情報</Typography>
        <Box>
          <Typography>申請カテゴリ：{renderDocumentCategory}</Typography>
          <Typography>届出の名称：{renderDocumentName}</Typography>
        </Box>
        <Typography variant="sub16Bold">企業と連絡先情報</Typography>
        <Box>
          <Typography>対象の企業：{convertData?.companyName || EMPTY_STR.TEXT}</Typography>
          <Typography>企業ユーザー：{convertData?.companyUserName || EMPTY_STR.TEXT}</Typography>
          <Typography>メールアドレス：{convertData?.companyUserEmail || EMPTY_STR.TEXT}</Typography>
          <Typography>連絡担当者：{convertData?.representativeName || EMPTY_STR.TEXT}</Typography>
          <Typography>メールアドレス：{convertData?.representativeEmail || EMPTY_STR.TEXT}</Typography>
        </Box>
        <Typography variant="sub16Bold">管理側備考欄</Typography>
        <Box>
          <Typography>{convertData?.companyNote || EMPTY_STR.TEXT}</Typography>
        </Box>
      </Stack>
    </InnerBox>
  );
};
