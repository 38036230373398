import { RegisterPage } from "@/features/auth/pages/Register";
import { background } from "@/theme/colors";
import { Box, Stack, Typography } from "@mui/material";

const Register = () => {
  return (
    <Box width="100%" display="flex" minHeight="100vh" justifyContent="center" py={13} bgcolor={background.primary}>
      <Stack gap={5} width="100%" maxWidth={814} justifyContent="center">
        <Typography variant="head32Bold">irohana担当者の登録</Typography>
        <RegisterPage />
      </Stack>
    </Box>
  );
};

export default Register;
