import { AppProvider } from "@/providers/AppProvider";
import { routes } from "@/routes";
import * as Sentry from "@sentry/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {
  const router = createBrowserRouter(routes);

  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default Sentry.withProfiler(App);
