import { TopPage } from "@/components/elements/TopPage";
import { AdminUserListTop } from "@/features/admin-user/components/AdminUserListTop";
import { AdminUserTable } from "@/features/admin-user/components/AdminUserTable";
import { Box, Stack } from "@mui/material";
import { FC } from "react";

export const AdminUserPage: FC = () => {
  return (
    <Box>
      <TopPage title="irohana担当者一覧" enablePageBack={false} />
      <Stack gap={3}>
        <AdminUserListTop />
        <AdminUserTable />
      </Stack>
    </Box>
  );
};
