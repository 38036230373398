import { TResetPasswordRequest } from "@/features/auth/types/resetPasswordRequest";
import { clientApi } from "@/utils/api";
import { TApiRes } from "@/types/api";

export const resetPasswordApi = async (payload: TResetPasswordRequest): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await clientApi.post<TApiRes<boolean>>("/reset-password", payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
