import { ReactNode, useCallback } from "react";
import { Bounce, Id, ToastOptions, toast } from "react-toastify";

const defaultConfig: TToastOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  rtl: false,
  draggable: false,
  theme: "colored",
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  transition: Bounce,
};

export type TToastType = "default" | "success" | "error";
export type TToastOptions = Omit<ToastOptions, "type">;

export const useToast = () => {
  const show = useCallback((type: TToastType, content: ReactNode, options?: TToastOptions) => {
    const id = toast(content, { type, ...defaultConfig, ...options });
    return id;
  }, []);

  const success = useCallback((message: string, options?: TToastOptions) => {
    return show("success", message, options);
  }, []);

  const error = useCallback((message: string, options?: TToastOptions) => {
    return show("error", message, options);
  }, []);

  const info = useCallback((message: string, options?: TToastOptions) => {
    return show("default", message, options);
  }, []);

  const close = useCallback((toastId: Id) => {
    toast.dismiss(toastId);
  }, []);

  const update = useCallback((toastId: Id, content: ReactNode, options?: TToastOptions) => {
    toast.update(toastId, { ...options, render: content });
  }, []);

  return {
    show,
    info,
    error,
    success,
    close,
    update,
  };
};
