import { useChat } from "@/features/chat/providers/ChatProvider";
import { useMount } from "@/hooks/useMount";
import { primary, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { Box, CircularProgress, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { MessageList } from "react-chat-elements";

export const Room = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { currentRoom } = useChat();
  const lastHeight = useRef(0);
  const isMounted = useMount();

  const handleScroll = async () => {
    if (ref.current?.scrollTop === 0) {
      await currentRoom.fetchNextPage();
      await resetScrollPosition();
    }
  };

  useEffect(() => {
    if (!isMounted) return;
    ref.current?.addEventListener("scroll", handleScroll);
    return () => {
      ref.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isMounted, currentRoom]);

  const handleFetchMoreMessages = async () => {
    if (!currentRoom.roomData) return;
    await currentRoom.fetchNextPage();
    await resetScrollPosition();
  };

  const resetScrollPosition = async () => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight - lastHeight.current;
    lastHeight.current = ref.current.scrollHeight;
  };

  useEffect(() => {
    handleFetchMoreMessages();
  }, [isMounted]);

  return (
    <RoomWrapper>
      {currentRoom.isFetching && (
        <SpinnerContainer>
          <CircularProgress color={"primary"} />
        </SpinnerContainer>
      )}
      <MessageList referance={ref} lockable toBottomHeight={"99%"} downButton dataSource={[...currentRoom.oldMessages, ...currentRoom.newMessages]} />
    </RoomWrapper>
  );
};

const RoomWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  flex: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .rce-container-mbox {
    position: relative;
    display: block;
    min-width: 300px;
    overflow-x: hidden;
  }

  .rce-container-mlist {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }
  .rce-mlist {
    display: block;
    flex: 1;
    position: relative;
    overflow: auto;
  }

  .rce-mbox {
    background-color: #f3f4f6;
    border-radius: 5px;
    border-top-left-radius: 0;
    flex-direction: column;
    float: left;
    margin: 6px 6px 6px 20px;
    min-width: 140px;
    max-width: 600px;
    padding: 8px 12px 8px;
    position: relative;
  }

  .rce-smsg-text {
    padding-top: 24px;
    padding-bottom: 8px;
    text-align: center;
    ${typography.body14}
    color: ${text.secondary};
  }

  .rce-mbox-right {
    background-color: ${primary.main};
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    .rce-mbox-title {
      color: white !important;
    }
  }

  .rce-mbox-title {
    font-weight: 800;
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
    margin: 0 0 8px;
    user-select: none;
    ${typography.body14Bold}
  }

  .rce-avatar {
    width: 36px;
    height: 36px;
    border-radius: 100px;
  }
  .rce-mbox-title--clear {
    margin-bottom: 5px;
  }

  .rce-mbox-right-notch {
    fill: ${primary.main};
    filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.2));
    height: 15px;
    position: absolute;
    right: -14px;
    top: 0;
    width: 15px;
  }
  .rce-mbox-left-notch {
    fill: #f3f4f6;
    filter: drop-shadow(-2px 0 1px rgba(0, 0, 0, 0.2));
    height: 15px;
    position: absolute;
    left: -12px;
    top: 1px;
    width: 15px;
  }
  .rce-mbox-text {
    white-space: pre-wrap;
    word-break: break-word;
    ${typography.body14}
    &.right {
      color: #ffffffdf !important;
    }
  }
  .rce-mbox-time.non-copiable {
    color: #bababa;
    align-items: center;
    bottom: -5px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    user-select: none;
    &::before {
      content: attr(title);
      font-size: 11px;
    }
  }
  &.error {
    padding-bottom: 25px;
    .rce-mbox-time.non-copiable {
      margin-right: 18px;
    }
    &::after {
      content: "Tin nhắn từ chối từ kiểm duyệt viên";
      position: absolute;
      bottom: 6px;
      right: 20px;
      font-size: 14px;
      color: red;
    }
  }
  /* } */

  .rce-mbox {
    position: relative;
    .rce-mbox-text-retracted {
      svg {
        position: absolute;
        fill: red !important;
        bottom: 1px;
        right: -5px;
        font-size: 16px;
      }
    }
  }
`;

const SpinnerContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;
