import { TMasterMajor } from "@/api/entities/master-major.entity";
import { setHiddenMajorApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenMajorModalProps = {
  hiddenMajor?: TMasterMajor;
  setHiddenMajor: Dispatch<SetStateAction<TMasterMajor | undefined>>;
};

export const HiddenMajorModal: FC<THiddenMajorModalProps> = ({ hiddenMajor, setHiddenMajor }) => {
  const queryClient = useQueryClient();
  console.log(hiddenMajor);
  const handleSubmit = async () => {
    if (!hiddenMajor) return;
    try {
      await setHiddenMajorApi(toNumber(hiddenMajor.id), { isHidden: !hiddenMajor.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenMajor(undefined);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenMajor?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenMajor}
      onClose={() => setHiddenMajor(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenMajor?.isHidden ? "この項目が有効になれますが、よろしいですか。" : "この項目が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
