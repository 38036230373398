import { createCompanyUserApi, updateCompanyUserApi } from "@/api/services/admin/company/user";
import { useEnum } from "@/api/services/global/enum";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { companyUserSchema } from "@/features/company/schema/companyUserSchema";
import { TCompanyUserForm } from "@/features/company/types/companyUserForm";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const initCreateForm: Partial<TCompanyUserForm> = {
  fullName: "",
  email: "",
};

type TCompanyUserFormProps = {
  previousData?: Partial<TCompanyUserForm>;
};

export const CompanyUserForm: FC<TCompanyUserFormProps> = ({ previousData }) => {
  const { data: activeStatuses } = useEnum("EUserActiveStatus");
  const toast = useToast();
  const { id, userId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TCompanyUserForm>({
    defaultValues: previousData ?? initCreateForm,
    resolver: yupResolver(companyUserSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TCompanyUserForm) => {
    try {
      if (!id) throw new Error();
      if (userId) {
        const rs = await updateCompanyUserApi(id, userId, data);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL, id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_DETAIL, id, userId] });
      } else {
        const payload = { ...data, isCompanyUser: Boolean(data.isCompanyUser) };
        const rs = await createCompanyUserApi(id, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      navigate(getDynamicRoute(AppRoutes.companyDetail, { id }));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={companyUserSchema}>
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={6}>
          <FormField control={control} name="fullName">
            <Input />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="email">
            <Input />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          <FormField control={control} name="isCompanyUser">
            <Select
              options={[
                { label: "irohana担当者", value: 0 },
                { label: "企業ユーザー", value: 1 },
              ]}
            />
          </FormField>
        </Grid>
        <Grid item xs={6}>
          {userId && (
            <FormField control={control} name="activeStatus">
              <Select options={activeStatuses} />
            </FormField>
          )}
        </Grid>
      </Grid>
      <Box textAlign="center" mt={5}>
        <Button type="submit">{userId ? "保存" : "メールを送信"}</Button>
      </Box>
    </Form>
  );
};
