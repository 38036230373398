import { RecoverForm } from "@/features/auth/components/RecoverForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TRecoverPageProps = {};

export const RecoverPage: FC<TRecoverPageProps> = () => {
  return (
    <Stack gap={12} alignItems="center">
      <Typography variant="head24Semi">パスワード再設定</Typography>
      <RecoverForm />
    </Stack>
  );
};
