import { TMasterJobType } from "@/api/entities/master-job-type.entity";
import { createJobTypeApi, updateJobTypeApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { jobTypeSchema } from "@/features/master-data/schema/masterDataJobTypeSchema";
import { TJobTypeForm } from "@/features/master-data/types/masterDataJobTypeForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TJobTypeFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedJobType?: TMasterJobType;
  existingMajorName: string;
  existingMajorId: number;
  existingCoMajorRenewVisaCsvCode: string;
  setSelectedJobTypeRows: React.Dispatch<React.SetStateAction<TMasterJobType[]>>;
};

export const JobTypeFormModal: FC<TJobTypeFormModalProps> = ({
  onClose,
  isOpen,
  selectedJobType,
  existingMajorName,
  existingMajorId,
  existingCoMajorRenewVisaCsvCode,
  setSelectedJobTypeRows,
}) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      cpJobRenewVisaCsvCode: selectedJobType ? selectedJobType.cpJobRenewVisaCsvCode : undefined,
      jobName: selectedJobType ? selectedJobType.jobName : undefined,
    }),
    [selectedJobType],
  );

  const methods = useForm<TJobTypeForm>({
    resolver: yupResolver(jobTypeSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TJobTypeForm) => {
    if (!existingMajorId || !existingMajorName || !existingCoMajorRenewVisaCsvCode) return;
    try {
      if (selectedJobType) {
        const rs = await updateJobTypeApi(selectedJobType.id, {
          majorId: toNumber(existingMajorId),
          cpJobRenewVisaCsvCode: data.cpJobRenewVisaCsvCode,
          jobName: data.jobName,
        });
        setSelectedJobTypeRows((prev) => {
          const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
          return arr;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createJobTypeApi({
          cpJobRenewVisaCsvCode: data.cpJobRenewVisaCsvCode,
          jobName: data.jobName,
          majorId: existingMajorId,
        });
        setSelectedJobTypeRows((prev) => [...prev, rs.data]);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      onClose();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal width={760} title={selectedJobType ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={jobTypeSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField label="職種">
              <Typography variant="body14">{existingMajorName}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField label="期間更新CSVのコード（列CO）">
              <Typography variant="body14">{existingCoMajorRenewVisaCsvCode}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="jobName">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="cpJobRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
