import { TMasterCompanySpecificBusinessClassification } from "@/api/entities/master-company-specific-business-classification.entity";
import { setHiddenCompanySpecificBusinessClassificationApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenCompanySpecificBusinessClassificationModalProps = {
  hiddenCompanySpecificBusinessClassification?: TMasterCompanySpecificBusinessClassification;
  setHiddenCompanySpecificBusinessClassification: Dispatch<SetStateAction<TMasterCompanySpecificBusinessClassification | undefined>>;
  setSelectedCompanySpecificBusinessClassificationRows: React.Dispatch<React.SetStateAction<TMasterCompanySpecificBusinessClassification[]>>;
};

export const HiddenCompanySpecificBusinessClassificationModal: FC<THiddenCompanySpecificBusinessClassificationModalProps> = ({
  hiddenCompanySpecificBusinessClassification,
  setHiddenCompanySpecificBusinessClassification,
  setSelectedCompanySpecificBusinessClassificationRows,
}) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenCompanySpecificBusinessClassification) return;
    try {
      const rs = await setHiddenCompanySpecificBusinessClassificationApi(
        toNumber(hiddenCompanySpecificBusinessClassification.companySpecificIndustryId),
        toNumber(hiddenCompanySpecificBusinessClassification.id),
        { isHidden: !hiddenCompanySpecificBusinessClassification.isHidden },
      );
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenCompanySpecificBusinessClassification(undefined);
      setSelectedCompanySpecificBusinessClassificationRows((prev) => {
        const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
        return arr;
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenCompanySpecificBusinessClassification?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenCompanySpecificBusinessClassification}
      onClose={() => setHiddenCompanySpecificBusinessClassification(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenCompanySpecificBusinessClassification?.isHidden
          ? "この行が有効になれますが、よろしいですか。"
          : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
