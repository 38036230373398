import { ResetPasswordForm } from "@/features/auth/components/ResetPasswordForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TResetPasswordPageProps = {};

export const ResetPasswordPage: FC<TResetPasswordPageProps> = () => {
  return (
    <Stack gap={12} alignItems="center">
      <Typography variant="head24Semi">パスワード再設定</Typography>
      <ResetPasswordForm />
    </Stack>
  );
};
