import { AdminLayoutWithAuth } from "@/components/layout/AdminLayout/AdminLayout";
import { LoginLayout } from "@/components/layout/LoginLayout/LoginLayout";
import { ContactLayout } from "@/features/contact-type/layout/ContactLayout";
import AdminUser from "@/pages/admin-user/AdminUser";
import AdminUserCreate from "@/pages/admin-user/AdminUserCreate";
import AdminUserDetail from "@/pages/admin-user/AdminUserDetail";
import AdminUserEdit from "@/pages/admin-user/AdminUserEdit";
import Login from "@/pages/auth/Login";
import Logout from "@/pages/auth/Logout";
import Recover from "@/pages/auth/Recover";
import Register from "@/pages/auth/Register";
import ResetPassword from "@/pages/auth/ResetPassword";
import Chat from "@/pages/chat/Chat";
import Company from "@/pages/company/Company";
import CompanyUserCreate from "@/pages/company/company-user/CompanyUserCreate";
import CompanyUserEdit from "@/pages/company/company-user/CompanyUserEdit";
import CompanyCreate from "@/pages/company/CompanyCreate";
import CompanyDetail from "@/pages/company/CompanyDetail";
import CompanyEdit from "@/pages/company/CompanyEdit";
import ContactType from "@/pages/contact-type/ContactType";
import ContactTypeCreate from "@/pages/contact-type/ContactTypeCreate";
import ContactTypeEdit from "@/pages/contact-type/ContactTypeEdit";
import ContactDetail from "@/pages/contact/ContactDetail";
import ResolvedContactList from "@/pages/contact/ResolvedContactList";
import ConvertDetail from "@/pages/convert/ConvertDetail";
import ConvertHistoryList from "@/pages/convert/ConvertHistoryList";
import ConvertList from "@/pages/convert/ConvertList";
import Information from "@/pages/information/Information";
import InformationCreate from "@/pages/information/InformationCreate";
import InformationEdit from "@/pages/information/InformationEdit";
import MasterDataDetail from "@/pages/master-data/MasterDataDetail";
import MasterDataList from "@/pages/master-data/MasterDataList";
import PageNotFound from "@/pages/PageNotFound";
import ServerErrorPage from "@/pages/ServerErrorPage";
import { AppRoutes } from "@/routes/config";
import { RouteObject } from "react-router-dom";
import UnresolvedContactList from "@/pages/contact/UnresolvedContactList";
import { SettingLayout } from "@/features/system-setting/layout/SettingLayout";
import EmailSetting from "@/pages/system-setting/EmailSetting";
import MaintenanceSetting from "@/pages/system-setting/MaintenanceSetting";
import Home from "@/pages/home/Home";

export const routes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [
      {
        path: AppRoutes.recover,
        element: <Recover />,
      },
      {
        path: AppRoutes.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: AppRoutes.login,
        element: <Login />,
      },
    ],
  },
  {
    path: AppRoutes.register,
    element: <Register />,
  },
  {
    element: <AdminLayoutWithAuth />,
    children: [
      {
        path: AppRoutes.home,
        element: <Home />,
      },
      {
        path: AppRoutes.company,
        element: <Company />,
      },
      { path: AppRoutes.companyCreate, element: <CompanyCreate /> },
      { path: AppRoutes.companyDetail, element: <CompanyDetail /> },
      { path: AppRoutes.companyEdit, element: <CompanyEdit /> },
      { path: AppRoutes.companyUserCreate, element: <CompanyUserCreate /> },
      { path: AppRoutes.companyUserEdit, element: <CompanyUserEdit /> },

      { path: AppRoutes.adminUser, element: <AdminUser /> },
      { path: AppRoutes.adminUserCreate, element: <AdminUserCreate /> },
      { path: AppRoutes.adminUserDetail, element: <AdminUserDetail /> },
      { path: AppRoutes.adminUserEdit, element: <AdminUserEdit /> },

      { path: AppRoutes.information, element: <Information /> },
      { path: AppRoutes.informationCreate, element: <InformationCreate /> },
      { path: AppRoutes.informationEdit, element: <InformationEdit /> },

      { path: AppRoutes.convert, element: <ConvertList /> },
      { path: AppRoutes.convertHistory, element: <ConvertHistoryList /> },
      { path: AppRoutes.convertDetail, element: <ConvertDetail /> },

      { path: AppRoutes.masterData, element: <MasterDataList /> },
      { path: AppRoutes.masterDataDetail, element: <MasterDataDetail /> },

      {
        element: <ContactLayout />,
        children: [
          { path: AppRoutes.unresolvedContact, element: <UnresolvedContactList /> },
          { path: AppRoutes.resolvedContact, element: <ResolvedContactList /> },
          { path: AppRoutes.unresolvedContactDetail, element: <ContactDetail /> },
          { path: AppRoutes.resolvedContactDetail, element: <ContactDetail /> },
          { path: AppRoutes.contactType, element: <ContactType /> },
          { path: AppRoutes.contactTypeCreate, element: <ContactTypeCreate /> },
          { path: AppRoutes.contactTypeEdit, element: <ContactTypeEdit /> },
        ],
      },
      { path: AppRoutes.chat, element: <Chat /> },
      { path: AppRoutes.chatDetail, element: <Chat /> },
      {
        element: <SettingLayout />,
        children: [
          { path: AppRoutes.emailSetting, element: <EmailSetting /> },
          { path: AppRoutes.maintenanceSetting, element: <MaintenanceSetting /> },
        ],
      },
    ],
  },
  {
    path: AppRoutes.logout,
    element: <Logout />,
  },
  { path: AppRoutes.serverErrorPage, element: <ServerErrorPage /> },
  { path: "*", element: <PageNotFound /> },
];
