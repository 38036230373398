export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_TIME_FORMAT_WITH_SECONDS = "YYYY/MM/DD HH:mm:ss";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";
export const DAY_FORMAT = "DD";
export const MONTH_FORMAT = "MM";
export const YEAR_FORMAT = "YYYY";
export const YEAR_MONTH_FORMAT = "YYYY/MM";
export const YEAR_MONTH_STR_FORMAT = "YYYY年MM月";
export const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";
export const COMPACT_DATE_FORMAT = "YYYYMMDD";
