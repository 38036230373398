import { TMasterSpecificBusinessClassification } from "@/api/entities/master-specific-business-classification.entity";
import { setHiddenBusinessClassificationApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenBusinessClassificationModalProps = {
  hiddenBusinessClassification?: TMasterSpecificBusinessClassification;
  setHiddenBusinessClassification: Dispatch<SetStateAction<TMasterSpecificBusinessClassification | undefined>>;
  setSelectedBusinessClassificationRows: React.Dispatch<React.SetStateAction<TMasterSpecificBusinessClassification[]>>;
};

export const HiddenBusinessClassificationModal: FC<THiddenBusinessClassificationModalProps> = ({
  hiddenBusinessClassification,
  setHiddenBusinessClassification,
  setSelectedBusinessClassificationRows,
}) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenBusinessClassification) return;
    try {
      const rs = await setHiddenBusinessClassificationApi(
        toNumber(hiddenBusinessClassification.specificIndustryId),
        toNumber(hiddenBusinessClassification.id),
        {
          isHidden: !hiddenBusinessClassification.isHidden,
        },
      );
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenBusinessClassification(undefined);
      setSelectedBusinessClassificationRows((prev) => {
        const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
        return arr;
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenBusinessClassification?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenBusinessClassification}
      onClose={() => setHiddenBusinessClassification(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenBusinessClassification?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
