import { Linkable } from "@/components/elements/Linkable";
import { LoginForm } from "@/features/auth/components/LoginForm";
import { AppRoutes } from "@/routes/config";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TLoginPageProps = {};

export const LoginPage: FC<TLoginPageProps> = () => {
  return (
    <Stack gap={6} alignItems="center">
      <Typography variant="head32Bold">irohana管理</Typography>
      <LoginForm />
      <Linkable href={AppRoutes.recover}>
        <Typography variant="sub16Semi" color="#1890FF">
          パスワードを忘れた方はこちら
        </Typography>
      </Linkable>
    </Stack>
  );
};
