import { TGetUserDto } from "@/api/services/admin/user/dtos/get-user.dto";
import { TAdminUserForm } from "@/features/admin-user/types/adminUserForm";

export const convertUserDetailToForm = (data: TGetUserDto): Partial<TAdminUserForm> => {
  return {
    adminRole: data?.adminRole ?? undefined,
    email: data?.email,
    fullName: data?.fullName ?? undefined,
  };
};
