import { TMasterJobType } from "@/api/entities/master-job-type.entity";
import { setHiddenJobTypeApi } from "@/api/services/global/master";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction } from "react";

type THiddenJobTypeModalProps = {
  hiddenJobType?: TMasterJobType;
  setHiddenJobType: Dispatch<SetStateAction<TMasterJobType | undefined>>;
  setSelectedJobTypeRows: React.Dispatch<React.SetStateAction<TMasterJobType[]>>;
};

export const HiddenJobTypeModal: FC<THiddenJobTypeModalProps> = ({ hiddenJobType, setHiddenJobType, setSelectedJobTypeRows }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!hiddenJobType) return;
    try {
      const rs = await setHiddenJobTypeApi(toNumber(hiddenJobType.majorId), toNumber(hiddenJobType.id), { isHidden: !hiddenJobType.isHidden });
      toast.success(API_SUCCESS_MESSAGE);
      setHiddenJobType(undefined);
      setSelectedJobTypeRows((prev) => {
        const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
        return arr;
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR] });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      title={hiddenJobType?.isHidden ? "有効の確認" : "無効化の確認"}
      isOpen={!!hiddenJobType}
      onClose={() => setHiddenJobType(undefined)}
      enableCloseIcon={false}
      labelClose="キャンセル"
      labelOk="保存"
      onOk={() => handleSubmit()}
    >
      <Typography variant="body14" component="p" sx={{ paddingY: 2 }}>
        {hiddenJobType?.isHidden ? "この行が有効になれますが、よろしいですか。" : "この行が無効化されますが、よろしいですか。"}
      </Typography>
    </Modal>
  );
};
