import { valueOf } from "@/types/common";

export const USER_TYPE = {
  ADMIN: "admin",
  ADMIN_MEMBER: "admin_member",
  COMPANY_USER: "company_user",
};

export type TUserType = valueOf<typeof USER_TYPE>;

export const ADMIN_ROLE = {
  MANAGER: "manager",
  SALE: "company_sale",
  CUSTOM_SERVICE: "custom_service",
  AUDITOR: "auditor",
};

export type TAdminRole = valueOf<typeof ADMIN_ROLE>;

export const MASTER_DATA_TYPE = {
  NATIONALITY: "nationality",
  REGULAR_SPECIFIED_INDUSTRY: "regular_specified_industry",
  RESIDENCE_PERIOD: "residence_period",
  LOCAL_GOVERNMENT_COUNTER: "local_government_counter",
  LIVING_RELATIONSHIP: "living_relationShip",
  JOB_TYPE: "job_type",
  RENEW_VISA_SPECIFIED_INDUSTRY: "renew_visa_specified_industry",
  MAIN_OCCUPATION: "main_occupation",
  MAIN_INDUSTRY: "main_industry",
  SKILL_TEST: "skill_test",
  LANGUAGE_TEST: "language_test",
  COMPANY_SPECIFIC_INDUSTRY: "company_specific_industry",
};

export type TMasterDataType = valueOf<typeof MASTER_DATA_TYPE>;

export enum ECompanyContractStatus {
  DURING_CONTRACT = "during_contract",
  EXPIRED_CONTRACT = "expired_contract",
  TRIAL = "trial",
}

export const SUPPORT_IMPLEMENTATION_STATUS = {
  SUPPORTED: "supported",
  NOT_SUPPORTED: "not_supported",
  NO_PARTICIPANT: "no_participant",
};

export const EMPLOYMENT_STATUS = {
  OFFERED: "offered",
  WORKING: "working",
  LEFT: "left",
};

export type TEmploymentStatus = valueOf<typeof EMPLOYMENT_STATUS>;

export const RETIREMENT_STATUS = {
  ENROLLMENT: "enrollment",
  PROCESSING: "processing",
  RETIRED: "retired",
};

export type TRetirementStatus = valueOf<typeof RETIREMENT_STATUS>;
