import { TGetConversationListOutputDto } from "@/api/services/admin/conversation/dtos/get-conversation-list.dto";
import { TGetConversationDto } from "@/api/services/admin/conversation/dtos/get-conversation.dto";
import { TRoom } from "@/features/chat/types/room";
import { formatMessageListDto } from "@/features/chat/utils/message";

export const formatConversationDto = (data: TGetConversationDto): TRoom => {
  const room = {} as TRoom;
  room.id = data.id;
  room.companyId = data.companyId;
  room.createdAt = data.createdAt;
  room.creator = data.creator;
  room.company = data.company;
  room.messages = null;
  return room;
};

export const formatConversationListDto = (data: TGetConversationListOutputDto[]): TRoom[] => {
  return data.map((item) => {
    const room = {} as TRoom;
    if (item.messages) room.messages = formatMessageListDto(item.messages);
    room.id = item.id;
    room.company = item.company;
    room.companyId = item.companyId;
    room.createdAt = item.createdAt;
    room.creator = item.creator;
    room.messages = item.messages;
    return room;
  });
};
