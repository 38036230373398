import { useEnum } from "@/api/services/global/enum";
import { SUPPORT_IMPLEMENTATION_STATUS } from "@/types/enum";
import { InnerBox } from "@/components/elements/InnerBox";
import { EMPTY_STR } from "@/constants/string";
import { TRegularLifeSupportData } from "@/features/convert/types/documents/regular-document/lifeSupport";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertLifeSupportInfoProps = {
  data: TRegularLifeSupportData;
};

export const ConvertLifeSupportInfo = ({ data }: TConvertLifeSupportInfoProps) => {
  const { data: supportImplementationStatus } = useEnum("ESupportImplementationStatus");
  const { documentInfo } = data;

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">支援実施状況に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">空港などへの出迎え</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.airportPickup) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.airportPickup === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">空港などへの出迎え(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.airportPickupReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">空港への見送り</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.seeOffAirport) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.seeOffAirport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">空港への見送り(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.seeOffAirportReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">生活の確保・生活に必要な契約に関する支援</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.supportLivelihood) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.supportLivelihood === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">生活の確保・生活に必要な契約に関する支援(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.supportLivelihoodReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">生活オリエンテーション</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.supportLifeOrientation) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.supportLifeOrientation === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">生活オリエンテーション(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.supportLifeOrientationReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">関係機関への同行その他必要な支援</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.accompanyToRelevantInstitutionsAndOtherSupport) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.accompanyToRelevantInstitutionsAndOtherSupport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">関係機関への同行その他必要な支援(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.accompanyToRelevantInstitutionsAndOtherSupportReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">日本語学習の機会の提供</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.supportLearnJapanese) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.supportLearnJapanese === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">日本語学習の機会の提供(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.supportLearnJapaneseReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">相談・苦情対応</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.handleConsultationAndComplaint) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.handleConsultationAndComplaint === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">相談・苦情対応(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.handleConsultationAndComplaintReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">日本人との交流促進</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.supportExchangeWithJapanesePeople) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.supportExchangeWithJapanesePeople === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">日本人との交流促進(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.supportExchangeWithJapanesePeopleReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">非自発的離職時の転職支援</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.supportFindJob) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.supportFindJob === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">非自発的離職時の転職支援(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.supportFindJobReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">支援実施状況</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body14">定期的な面談の実施</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(supportImplementationStatus, documentInfo.conductRegularInterview) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {documentInfo.conductRegularInterview === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && (
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">支援実施状況</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body14">定期的な面談の実施(未実施の理由)</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body14">{documentInfo.conductRegularInterviewReason}</Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Box>
      </Stack>
    </InnerBox>
  );
};
