import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmResetDataProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmResetData: FC<TConfirmResetDataProps> = ({ open, onClose, onOk }) => {
  return (
    <Modal isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい" width={500}>
      <Stack mb={2}>
        <Typography>
          「定期届出の開始年度」もしくは 「定期届出の開始四半期」が変更する場合は、全ての定期届出のデータがなくなりますが、よろしいですか。
        </Typography>
      </Stack>
    </Modal>
  );
};
