import { TMasterCompanySpecificBusinessClassification } from "@/api/entities/master-company-specific-business-classification.entity";
import { createCompanySpecificBusinessClassificationApi, updateCompanySpecificBusinessClassificationApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { companySpecificBusinessClassificationSchema } from "@/features/master-data/schema/masterDataCompanySpecificIndustrySchema";
import { TCompanySpecificBusinessClassificationForm } from "@/features/master-data/types/masterDataCompanySpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TCompanySpecificBusinessClassificationFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedCompanySpecificBusinessClassification?: TMasterCompanySpecificBusinessClassification;
  existingCompanySpecificIndustry: string;
  existingCompanySpecificIndustryId: number;
  setSelectedCompanySpecificBusinessClassificationRows: React.Dispatch<React.SetStateAction<TMasterCompanySpecificBusinessClassification[]>>;
};

export const CompanySpecificBusinessClassificationFormModal: FC<TCompanySpecificBusinessClassificationFormModalProps> = ({
  onClose,
  isOpen,
  selectedCompanySpecificBusinessClassification,
  existingCompanySpecificIndustry,
  existingCompanySpecificIndustryId,
  setSelectedCompanySpecificBusinessClassificationRows,
}) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      companySpecificBusinessClassificationName: selectedCompanySpecificBusinessClassification
        ? selectedCompanySpecificBusinessClassification.companySpecificBusinessClassificationName
        : undefined,
    }),
    [selectedCompanySpecificBusinessClassification],
  );

  const methods = useForm<TCompanySpecificBusinessClassificationForm>({
    resolver: yupResolver(companySpecificBusinessClassificationSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TCompanySpecificBusinessClassificationForm) => {
    if (!existingCompanySpecificIndustryId || !existingCompanySpecificIndustry) return;
    try {
      if (selectedCompanySpecificBusinessClassification) {
        const rs = await updateCompanySpecificBusinessClassificationApi(selectedCompanySpecificBusinessClassification.id, {
          companySpecificIndustryId: toNumber(existingCompanySpecificIndustryId),
          companySpecificBusinessClassificationName: data.companySpecificBusinessClassificationName,
        });
        setSelectedCompanySpecificBusinessClassificationRows((prev) => {
          const arr = prev.map((item) => (toNumber(item.id) === toNumber(rs.data.id) ? rs.data : item));
          return arr;
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createCompanySpecificBusinessClassificationApi({
          companySpecificBusinessClassificationName: data.companySpecificBusinessClassificationName,
          companySpecificIndustryId: existingCompanySpecificIndustryId,
        });
        setSelectedCompanySpecificBusinessClassificationRows((prev) => [...prev, rs.data]);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      onClose();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal
      title={selectedCompanySpecificBusinessClassification ? "編集" : "追加"}
      isOpen={isOpen}
      enableFooter={false}
      onClose={onClose}
      enableCloseIcon={false}
    >
      <Form methods={methods} onSubmit={onSubmit} schema={companySpecificBusinessClassificationSchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField label="特定産業分野（企業）">
              <Typography variant="body14">{existingCompanySpecificIndustry}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <FormField control={control} name="companySpecificBusinessClassificationName">
              <Input />
            </FormField>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
