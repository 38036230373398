import { useContactType } from "@/api/services/admin/contact-type";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ContactTypeForm } from "@/features/contact-type/components/ContactTypeForm";
import { convertContactTypeResToForm } from "@/features/contact-type/utils/convert";
import { Stack } from "@mui/material";
import { FC } from "react";

type TContactTypeEditPageProps = {};

export const ContactTypeEditPage: FC<TContactTypeEditPageProps> = () => {
  const { data: contactTypeResult, isLoading } = useContactType();
  const contactTypeData = contactTypeResult?.data;

  if (isLoading || !contactTypeData) return <LoadingPage />;
  return (
    <Stack gap={4}>
      <ContactTypeForm previousData={convertContactTypeResToForm(contactTypeData)} />
    </Stack>
  );
};
