import { COMPANY_CONTAINER } from "@/constants/ui";
import { ContactInfo } from "@/features/contact/components/ContactInfo";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TContactDetailPageProps = {};

export const ContactDetailPage: FC<TContactDetailPageProps> = () => {
  return (
    <Stack maxWidth={COMPANY_CONTAINER} gap={2}>
      <Typography variant="head20Semi" fontWeight={100}>
        お問い合わせフォーム
      </Typography>
      <ContactInfo />
    </Stack>
  );
};
