import { INVALID_PASSWORD_CONFIRM } from "@/constants/invalids";
import { validator } from "@/utils/validator";

export const resetPasswordSchema = validator.object().shape({
  email: validator.string().emptyToNull().max(60).label("メールアドレス").required(),
  newPassword: validator.string().emptyToNull().strongPassword().label("新パスワード").required(),
  confirmNewPassword: validator
    .string()
    .emptyToNull()
    .trim()
    .oneOf([validator.ref("newPassword")], INVALID_PASSWORD_CONFIRM)
    .label("新パスワードの認証")
    .required(),
});
