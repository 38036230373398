import { background, text } from "@/theme/colors";
import { IconButton, IconButtonProps, styled } from "@mui/material";
import { FC } from "react";

type TIconBoxProps = {
  size?: number;
  to?: string;
} & Omit<IconButtonProps, "size">;

export const IconBox: FC<TIconBoxProps> = ({ size = 24, ...rest }) => {
  return <IconBoxWrap iconSize={size} tabIndex={-1} {...rest} />;
};

const options = { shouldForwardProp: (propName: string) => !["iconSize"].includes(propName) };

const IconBoxWrap = styled(IconButton, options)<{ iconSize: number }>`
  width: ${({ iconSize }) => iconSize}px;
  height: ${({ iconSize }) => iconSize}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  color: ${text.primary};
  svg {
    font-size: ${({ iconSize }) => iconSize}px;
  }
  :hover {
    background-color: ${background.disable};
  }
`;
