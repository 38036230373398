import { background, button, divider, lightBlue, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, ButtonProps, CircularProgress, Button as MuiButton, css, styled } from "@mui/material";
import { MouseEvent, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export type TButtonVariant = "primary" | "secondary" | "outline" | "ghost" | "warning";
export type TButtonSize = "lg" | "md" | "sm" | "xs";

export type TButtonProps = {
  variant?: TButtonVariant;
  size?: TButtonSize;
  to?: string;
  isLoading?: boolean;
} & Omit<ButtonProps, "variant" | "size">;

export const Button: FCC<TButtonProps> = ({
  variant = "primary",
  size = "md",
  fullWidth,
  children,
  isLoading: isExternalLoading,
  onClick,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const methods = useFormContext();

  const handleOnclick = async (e: MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    setLoading(true);
    await onClick(e);
    setLoading(false);
  };

  const isLoading = useMemo(() => {
    if (rest.type === "submit" && !!methods) return methods.formState.isSubmitting;
    return loading || isExternalLoading;
  }, [loading, methods, rest.type, isExternalLoading]);

  return (
    <StyledButton
      btnVariant={variant}
      btnSize={size}
      onClick={handleOnclick}
      sx={{ width: fullWidth ? "100%" : "fit-content" }}
      disabled={isLoading}
      {...rest}
    >
      {children}
      {isLoading && (
        <LoadingButton>
          <CircularProgress color="inherit" />
        </LoadingButton>
      )}
    </StyledButton>
  );
};

const options = { shouldForwardProp: (propName: string) => !["btnVariant", "btnSize"].includes(propName) };

const StyledButton = styled(MuiButton, options)<{ btnVariant: TButtonVariant; btnSize: TButtonSize }>`
  &.MuiButtonBase-root {
    line-height: 1;
    text-transform: none;
    position: relative;
    overflow: hidden;
    color: ${text.white};
    white-space: nowrap;
    cursor: pointer;
    ${({ btnVariant, btnSize }) => css`
      // variants
      ${PrimaryButtonCSS}
      ${btnVariant === "secondary" && SecondaryButtonCSS};
      ${btnVariant === "outline" && OutlineButtonCSS};
      ${btnVariant === "ghost" && GhostButtonCSS};
      ${btnVariant === "warning" && WarningButtonCSS};

      // sizes
      ${MdButtonCSS}
      ${btnSize === "lg" && LgButtonCSS}
    ${btnSize === "sm" && SmButtonCSS}
    ${btnSize === "xs" && XsButtonCSS}
    `}
  }
`;

const PrimaryButtonCSS = css`
  background: ${button.primary};
  border: 2px solid ${button.primary};
  color: ${text.white};
  :hover {
    background: ${lightBlue[99]};
    color: ${button.primary};
  }
  :focus {
    background: ${button.hover_02};
    color: ${button.primary};
  }
  :active {
    background: ${button.pressed};
    color: ${text.white};
    border: 2px solid ${button.pressed};
  }
  :disabled,
  &.Mui-disabled {
    background: ${button.disable};
    border: 2px solid ${button.disable};
    color: ${text.white};
  }
`;

const SecondaryButtonCSS = css`
  background: ${background.white};
  border: 2px solid ${button.primary};
  color: ${button.primary};
  :hover {
    background: ${lightBlue[99]};
  }
  :focus {
    background: ${button.hover_02};
  }
  :active {
    background: ${button.pressed};
    border: 2px solid ${button.pressed};
    color: ${text.white};
  }
  :disabled,
  &.Mui-disabled {
    background: ${button.disable};
    border: 2px solid ${button.disable};
    color: ${text.white};
  }
`;

const OutlineButtonCSS = css`
  background: ${background.white};
  color: ${button.primary};
  border: 2px solid ${button.primary};
  :hover {
    background: ${button.hover_02};
  }
  :focus {
    background: ${button.hover_02};
  }
  :active {
    background: ${button.hover_01};
    color: ${text.white};
    border: 2px solid ${button.pressed};
  }
  :disabled,
  &.Mui-disabled {
    background: ${background.white};
    border: 2px solid ${button.disable};
    color: ${text.tertiary};
  }
`;

const GhostButtonCSS = css`
  background-color: transparent;
  color: ${text.primary};
  border: none !important;
  :hover {
    background: ${divider.low};
    color: ${text.primary};
  }
  :focus {
    background: ${divider.middle};
    color: ${text.primary};
  }
  :active {
    background: ${divider.high};
    color: ${text.primary};
  }
  :disabled,
  &.Mui-disabled {
    background: ${button.disable};
    color: ${text.white};
  }
`;

const WarningButtonCSS = css`
  background: ${button.secondary};
  border: 2px solid ${button.secondary};
  color: ${text.white};
  :hover {
    background: ${text.white};
    color: ${button.secondary};
  }
  :focus {
    background: ${text.white};
    color: ${button.secondary};
  }
  :active {
    background: ${text.white};
    color: ${button.secondary};
  }
  :disabled,
  &.Mui-disabled {
    background: ${button.disable};
    border: 2px solid ${button.disable};
    color: ${text.white};
  }
`;

const LgButtonCSS = css`
  height: 48px;
  padding: 0 30px;
  border-radius: ${rounded.sm};
  ${typography.head20Semi}
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
  }
`;

const MdButtonCSS = css`
  height: 40px;
  padding: 0 22px;
  border-radius: ${rounded.sm};
  ${typography.sub16Semi}
  .MuiCircularProgress-root {
    width: 16px !important;
    height: 16px !important;
  }
`;

const SmButtonCSS = css`
  height: 32px;
  padding: 0 22px;
  border-radius: ${rounded.lg};
  ${typography.cap12Bold}
  .MuiCircularProgress-root {
    width: 12px !important;
    height: 12px !important;
  }
`;

const XsButtonCSS = css`
  height: 25px;
  padding: 0 6px;
  border-radius: ${rounded.lg};
  ${typography.cap12Bold}
  .MuiCircularProgress-root {
    width: 12px !important;
    height: 12px !important;
  }
`;

const LoadingButton = styled(Box)`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
