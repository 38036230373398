import { useGetProvincesAndMunicipalities } from "@/api/services/global/constant";
import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { EMPTY_STR } from "@/constants/string";
import { TRegularAcceptanceActivityData } from "@/features/convert/types/documents/regular-document/acceptanceActivity";
import { convertNumberToZipCode } from "@/utils/input";
import { toNumber } from "@/utils/number";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertAcceptanceActivityInfoProps = {
  data: TRegularAcceptanceActivityData;
};

export const ConvertAcceptanceActivityInfo = ({ data }: TConvertAcceptanceActivityInfoProps) => {
  const { provinceOptions } = useGetProvincesAndMunicipalities();
  const { companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();

  const { documentInfo } = data;

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">受入れ・活動状況に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">小項目</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">対象期間</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">年</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.year || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">対象期間</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">対象4半期</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.quarter ? `第${documentInfo.quarter}四半期` : EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">氏名又は名称</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">フリガナ</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyFuriganaName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">法人番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyIdentificationNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定産業分野</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {findLabelByValue(companySpecificIndustryOptions, toNumber(data?.documentInfo.companySpecificIndustryId)) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">郵便番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">
                  {documentInfo.companyZipCode ? convertNumberToZipCode(documentInfo.companyZipCode) : EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">都道府県</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyProvince || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">住所（都道府県以外）</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyAddress || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">電話番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.companyTelNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalRegisteredEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalNewEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalVoluntaryResignedEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalLayoffEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">行方不明者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalMissingEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanRegisteredEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanNewEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanVoluntaryResignedEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanVoluntaryLayoffEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignRegisteredEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignNewEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignVoluntaryResignedEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能1号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignVoluntaryLayoffEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalRegisteredEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalNewEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalVoluntaryResignedEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalLayoffEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">行方不明者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillTotalMissingEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanRegisteredEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanNewEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanVoluntaryResignedEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する日本人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillJapanVoluntaryLayoffEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignRegisteredEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignNewEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">自己都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignVoluntaryResignedEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能2号と同一の業務に従事する外国人従業員</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">解雇等会社都合退職者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.specificSkillForeignVoluntaryLayoffEmployeeTwoCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">上記以外の従業員（日本人＋外国人）</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">在籍者数（対象期間末日）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.nonSpecificSkillRegisteredEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">雇用状況</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">上記以外の従業員（日本人＋外国人）</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">新規雇用者数（対象期間中）</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.nonSpecificSkillNewEmployeeCount || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能外国人の受入れに要した費用</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">支援計画の実施費用</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.acceptanceActivityImplementationCost || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack paddingX={2} paddingY={1}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="body14">特定技能外国人の受入れに要した費用</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">受入準備費用</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body14">{documentInfo.acceptanceActivityPrepareCost || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </InnerBox>
  );
};
