import { InternetStatus } from "@/components/commons/InternetStatus";
import { Toast } from "@/components/elements/Toast";
import theme from "@/theme";
import { FCC } from "@/types/common";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jaJP } from "@mui/x-date-pickers/locales";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "dayjs/locale/ja";

const jpLocale = jaJP.components.MuiLocalizationProvider.defaultProps.localeText;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: 5000, // After 5 seconds have elapsed since the data was retrieved
      gcTime: 5000, //  Data in cache is retained until 5 seconds has passed
      retry: 0,
    },
  },
});

export const AppProvider: FCC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toast />
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={jpLocale} adapterLocale="ja">
          {children}
        </LocalizationProvider>
        <InternetStatus />
      </ThemeProvider>
    </QueryClientProvider>
  );
};
