import { clientApi } from "@/utils/api";

import { TGetMeResDto } from "@/api/services/admin/auth/dtos/get-me.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { TRefreshTokenReqDto, TRefreshTokenResDto } from "@/api/services/admin/auth/dtos/refresh-token.dto";
import { setToken } from "@/utils/token";

export const fetchMeApi = async (): Promise<TGetMeResDto> => {
  const { data } = await clientApi.get<TGetMeResDto>("/get-me");
  return data;
};

export const useMe = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER.FETCH_ME],
    queryFn: fetchMeApi,
  });
};

export const refreshTokenApi = async (payload: TRefreshTokenReqDto): Promise<TRefreshTokenResDto> => {
  const rs = await clientApi.post<TRefreshTokenResDto>("/refresh-token", payload);
  const { data } = rs.data;
  setToken(data.accessToken, data.refreshToken);
  return rs.data;
};
