import { TUpdateEmailSettingReqDto, TUpdateEmailSettingResDto } from "@/api/services/admin/system-setting/dtos/update-email-setting.dto";
import { TGetSystemSettingResDto } from "@/api/services/admin/system-setting/dtos/get-system-setting.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { clientApi } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import {
  TUpdateMaintenanceSettingReqDto,
  TUpdateMaintenanceSettingResDto,
} from "@/api/services/admin/system-setting/dtos/update-maintenance-setting.dto";

export const fetchSystemSettingApi = async (): Promise<TGetSystemSettingResDto> => {
  const rs = await clientApi.get<TGetSystemSettingResDto>("/system-settings");
  return rs.data;
};

export const useSystemSetting = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.SYSTEM_SETTING.FETCH_SYSTEM_DETAIL],
    queryFn: () => fetchSystemSettingApi(),
  });
};

export const updateEmailSetting = async (payload: TUpdateEmailSettingReqDto): Promise<TUpdateEmailSettingResDto> => {
  const rs = await clientApi.put<TUpdateEmailSettingResDto>("/system-settings/emails", payload);
  return rs.data;
};

export const updateMaintenanceSetting = async (payload: TUpdateMaintenanceSettingReqDto): Promise<TUpdateMaintenanceSettingResDto> => {
  const rs = await clientApi.put<TUpdateMaintenanceSettingResDto>("/system-settings/maintenance", payload);
  return rs.data;
};
