import { TCreateContactTypeReqDto } from "@/api/services/contact-type/dtos/create-contact-type.dto";
import { TGetContactTypeDto } from "@/api/services/contact-type/dtos/get-contact-type.dto";
import { TUpdateContactTypeReqDto } from "@/api/services/contact-type/dtos/update-contact-type.dto";
import { TContactTypeForm } from "@/features/contact-type/types/contactTypeForm";

export const convertContactTypeFormToCreateReq = (data: TContactTypeForm): TCreateContactTypeReqDto => {
  return {
    ...data,
    name: data.name,
    template: data.template ?? "",
    email: data.email,
  };
};

export const convertContactTypeFormToUpdateReq = (data: TContactTypeForm): TUpdateContactTypeReqDto => {
  return {
    ...data,
    name: data.name,
    template: data.template,
    email: data.email,
  };
};

export const convertContactTypeResToForm = (data: TGetContactTypeDto): TContactTypeForm => {
  return {
    name: data.name,
    template: data.template,
    email: data.email,
  };
};
