import { useFormField } from "@/components/elements/FormField/FormField";
import { button, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { Radio as MuiRadio, RadioProps, styled, Stack, FormControlLabel, Typography, Box } from "@mui/material";
import React, { FC } from "react";

type TInputDateProps = {
  label?: string;
  subLabel?: string;
  nonControl?: boolean;
} & RadioProps;

export const Radio: FC<TInputDateProps> = ({ label, subLabel, nonControl, ...rest }) => {
  const { field } = useFormField(nonControl);
  return (
    <RadioWrap>
      <FormControlLabel
        label={
          <Stack gap={0.5}>
            <Typography variant="body14" color={text.primary}>
              {label}
            </Typography>
            <Typography variant="cap12" color={text.secondary}>
              {subLabel}
            </Typography>
          </Stack>
        }
        control={<StyledRadio {...rest} {...field} />}
      />
    </RadioWrap>
  );
};

const RadioWrap = styled(Box)`
  .MuiFormControlLabel-root {
    align-items: flex-start;
    gap: 12px;
    margin: 0;
  }
`;

const StyledRadio = styled(MuiRadio)`
  position: relative;
  width: 18px;
  height: 18px;
  color: ${button.primary}!important;
  &.Mui-disabled {
    color: ${button.disable}!important;
  }

  &.Mui-checked .MuiSvgIcon-root:nth-of-type(2) {
    transform: scale(1.2);
  }
  &.Mui-focused {
    &::after {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      width: 100%;
      height: 100%;
      padding: 2px;
      border: 2px solid ${button.secondary};
      box-sizing: content-box;
      border-radius: ${rounded.sm};
      z-index: -1;
    }
  }
`;
