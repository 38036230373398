import { MAX_INTEGER } from "@/constants/common";
import { validator } from "@/utils/validator";

export const companySchema = validator.object().shape({
  companyName: validator.string().trim().emptyToNull().max(60).label("企業名").required(),
  contractStatus: validator.string().isSelection().emptyToNull().label("ステータス").required(),
  specificIndustryId: validator.string().isSelection().emptyToNull().label("特定産業分野").required(),
  companyDescription: validator.string().trim().emptyToNull().label("企業情報").notRequired(),
  contractStartDate: validator
    .mixed()
    .isSelection()
    .isDayjs()
    .label("トライアル / 契約開始日")
    .when("contractStatus", ([value], schema) => {
      switch (value) {
        case "during_contract":
          return schema.isPastOrToday();
        case "expired_contract":
          return schema.isPast();
        case "trial":
          return schema.isPastOrToday();
      }
      return schema;
    })
    .required(),
  contractEndDate: validator
    .mixed()
    .isSelection()
    .isDayjs()
    .isAfterDate("contractStartDate", { startLabel: "トライアル / 契約開始日", endLabel: "解約日" })
    .label("解約日")
    .notRequired(),
  startReportYear: validator.mixed().isSelection().isDayjs().label("定期届出の開始年度").required(),
  startReportQuarter: validator.number().isSelection().emptyToNull().label("定期届出の開始四半期").limit(MAX_INTEGER).required(),
  pricePerEmployee: validator.number().decimal().emptyToNull().positive().integer().label("単価設定").limit(MAX_INTEGER).notRequired(),
  note: validator.string().trim().emptyToNull().label("備考").notRequired(),
});
