import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { useToast } from "@/hooks/useToast";
import { setErrorForm, showError } from "@/utils/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TSettingForm } from "@/features/system-setting/types/settingForm";
import { settingSchema } from "@/features/system-setting/schema/settingSchema";
import { divider } from "@/theme/colors";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { AppRoutes } from "@/routes/config";
import { convertEmailSettingFormToUpdateReq } from "@/features/system-setting/utils/converts";
import { updateEmailSetting } from "@/api/services/admin/system-setting";

const initForm: TSettingForm = {
  irohanaSystemNotificationEmail: "",
  clientSystemNotificationEmail: "",
  otherNotificationEmail: "",
  conversionNotificationEmail: "",
  chatNotificationEmail: "",
};

type TSettingFormProps = {
  readonly?: boolean;
  previousData?: Partial<TSettingForm>;
};

export const SettingForm: FC<TSettingFormProps> = ({ readonly, previousData }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TSettingForm>({
    defaultValues: previousData ?? initForm,
    resolver: yupResolver(settingSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TSettingForm) => {
    try {
      const payload = convertEmailSettingFormToUpdateReq(data);
      const rs = await updateEmailSetting(payload);
      navigate(AppRoutes.emailSetting);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SYSTEM_SETTING.FETCH_SYSTEM_DETAIL] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={settingSchema}>
      <Stack gap={3} paddingBottom={3} borderBottom={`1px solid ${divider.high}`}>
        <Typography variant="sub16Semi">通知元メールアドレスの設定</Typography>
        <Typography variant="body14">いろはなシステムから送信されるメールの送り元として使用されます。返信不可として扱われます。</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="irohanaSystemNotificationEmail">
                <Input readonly={readonly} />
              </FormField>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="clientSystemNotificationEmail">
                <Input readonly={readonly} />
              </FormField>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack paddingTop={3} gap={3}>
        <Typography variant="sub16Semi">通知先メールアドレスの設定</Typography>
        <Typography variant="body14">目的別にシステム通知の送り先を設定します。MLを指定し、各担当者に通知されるようにしてください。</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="otherNotificationEmail">
                <Input readonly={readonly} />
              </FormField>
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="conversionNotificationEmail">
                <Input readonly={readonly} />
              </FormField>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="chatNotificationEmail">
                <Input readonly={readonly} />
              </FormField>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {!readonly && (
        <Box textAlign="center" mt={6}>
          <Button type="submit">設定</Button>
        </Box>
      )}
    </Form>
  );
};
