import { IconFilterList } from "@/assets/icons";
import { Popover } from "@/components/elements/Popover";
import { background, blue } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { FCC, TOption } from "@/types/common";
import { MenuItem, MenuList, Stack, css, styled } from "@mui/material";
import { Key, useEffect, useState } from "react";

export type TFilterProps = {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  options: TOption[];
  disabledValues?: Key[];
  multiple?: boolean;
};

export const Filter: FCC<TFilterProps> = ({ value, onChange, options, disabledValues, multiple = true, children }) => {
  const [selected, setSelected] = useState<Key[]>(value ?? []);

  useEffect(() => {
    setSelected(value ?? []);
  }, [value]);

  const toggleSelectItem = (v: Key) => {
    if (multiple) {
      let newSelected = [...selected];

      const found = newSelected.find((curV) => curV === v);
      if (found) newSelected = newSelected.filter((curV) => curV !== v);
      else newSelected = [v, newSelected].flat();

      setSelected(newSelected);
      onChange?.(newSelected);
      return;
    }
    setSelected([v]);
    onChange?.([v]);
  };

  return (
    <Popover
      anchor={
        <Stack direction="row" gap={1.25}>
          {children}
          <IconFilterList fontSize={24} />
        </Stack>
      }
      closeClickInside
    >
      <StyledMenuList>
        {options.map(({ label, value }) => (
          <StyledMenuItem
            key={value}
            disabled={disabledValues?.includes(value)}
            onClick={() => toggleSelectItem(value)}
            active={selected.includes(value)}
          >
            {label}
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    </Popover>
  );
};

const StyledMenuList = styled(MenuList)`
  padding: 0;
  min-width: 320px;
`;

const options = { shouldForwardProp: (propName: string) => !["active"].includes(propName) };
const StyledMenuItem = styled(MenuItem, options)<{ active: boolean }>`
  ${({ active }) => active && ActiveMenu}
  padding: 8px;
  ${css(typography.body14)}
  &:hover {
    background-color: ${background.secondary};
  }
  &:focus {
    background-color: ${blue[95]};
  }
  &.Mui-disabled {
    background: ${background.disable};
  }
`;

const ActiveMenu = css`
  background: ${blue[80]}!important;
`;
