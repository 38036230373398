import { useGetCompanySpecificIndustryList } from "@/api/services/global/master";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { Table } from "@/components/elements/Table";
import { TBusinessClassificationForm, TMasterSpecificIndustryCreateForm } from "@/features/master-data/types/masterDataSpecificIndustryForm";
import { useFormTable } from "@/hooks/useFormTable";
import { MRT_ColumnDef } from "material-react-table";
import { useFormContext, useWatch } from "react-hook-form";

export const CreateSpecificIndustryFormTable = () => {
  const { control } = useFormContext<TMasterSpecificIndustryCreateForm>();

  const companySpecificIndustryIdWatchField = useWatch({ control, name: "companySpecificIndustryId" });

  const { isLoading: isLoadingCompanySpecificIndustry, getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry } =
    useGetCompanySpecificIndustryList();

  const formColumns: MRT_ColumnDef<TBusinessClassificationForm>[] = [
    {
      header: "業務区分（会社情報用）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`specificBusinessClassifications.${row.index}.companySpecificBusinessClassificationId`}>
            <Select
              loading={isLoadingCompanySpecificIndustry}
              options={getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry(companySpecificIndustryIdWatchField)}
            />
          </FormField>
        );
      },
    },
    {
      header: "業務区分（外国人用、期間更新の表記）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`specificBusinessClassifications.${row.index}.specificBusinessClassificationName`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "期間更新CSVのコード（列FH）",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`specificBusinessClassifications.${row.index}.fhSpecificBusinessClassificationRenewVisaCsvCode`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "業務区分（随時届出)",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`specificBusinessClassifications.${row.index}.anyTimeSpecificBusinessClassificationName`}>
            <Input />
          </FormField>
        );
      },
    },
    {
      header: "随時届出CSVのコード",
      Cell: ({ row }) => {
        return (
          <FormField control={control} name={`specificBusinessClassifications.${row.index}.specificBusinessClassificationAnyTimeCsvCode`}>
            <Input />
          </FormField>
        );
      },
    },
  ];

  const { table } = useFormTable({
    columns: formColumns,
    control,
    name: "specificBusinessClassifications",
    initFieldData: {
      fhSpecificBusinessClassificationRenewVisaCsvCode: "",
      specificBusinessClassificationName: "",
      anyTimeSpecificBusinessClassificationName: "",
      specificBusinessClassificationAnyTimeCsvCode: "",
      companySpecificBusinessClassificationId: "",
    },
    primaryKey: "id",
  });

  return <Table table={table} maxHeight={350} />;
};
