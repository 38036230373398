export const AppRoutes = {
  // Login
  login: "/login",
  logout: "/logout",
  recover: "/recover",
  resetPassword: "/reset-password",
  register: "/register",

  // Home
  home: "/",

  // Company
  company: "/company",
  companyCreate: "/company/create",
  companyDetail: "/company/:id/detail",
  companyEdit: "/company/:id/edit",
  companyUserCreate: "/company/:id/user/create",
  companyUserEdit: "/company/:id/user/:userId/edit",

  // Admin user
  adminUser: "/admin-user",
  adminUserCreate: "/admin-user/create",
  adminUserDetail: "/admin-user/:id",
  adminUserEdit: "/admin-user/:id/edit",

  // information
  information: "/information",
  informationCreate: "/information/create",
  informationEdit: "/information/:id/edit",

  // Convert
  convert: "/convert",
  convertHistory: "/convert/history",
  convertDetail: "/convert/:id",

  // Master data
  masterData: "/master-data",
  masterDataDetail: "/master-data/:type",

  // Contact
  unresolvedContact: "/contact/unresolved",
  resolvedContact: "/contact/resolved",
  unresolvedContactDetail: "/contact/unresolved/:id",
  resolvedContactDetail: "/contact/resolved/:id",

  // Contact type
  contactType: "/contact-type",
  contactTypeCreate: "/contact-type/create",
  contactTypeEdit: "/contact-type/:id/edit",

  // System setting
  emailSetting: "/email-setting",
  maintenanceSetting: "/maintenance-setting",

  // page not found
  pageNotFound: "/page-not-found",
  serverErrorPage: "/server-error",

  // Chat
  chat: "/chat",
  chatDetail: "/chat/:id",
};

export const CompanyRoutes = [
  AppRoutes.company,
  AppRoutes.companyCreate,
  AppRoutes.companyDetail,
  AppRoutes.companyEdit,
  AppRoutes.companyUserCreate,
  AppRoutes.companyUserEdit,
];

export const AdminUserRoutes = [AppRoutes.adminUser, AppRoutes.adminUserCreate, AppRoutes.adminUserDetail, AppRoutes.adminUserEdit];

export const informationRoutes = [AppRoutes.information, AppRoutes.informationCreate, AppRoutes.informationEdit];

export const ConvertRoutes = [AppRoutes.convert, AppRoutes.convertHistory, AppRoutes.convertDetail];

export const MasterDataRoutes = [AppRoutes.masterData, AppRoutes.masterDataDetail];

export const ContactRoutes = [
  AppRoutes.unresolvedContact,
  AppRoutes.resolvedContact,
  AppRoutes.unresolvedContactDetail,
  AppRoutes.resolvedContactDetail,
  AppRoutes.contactType,
  AppRoutes.contactTypeCreate,
  AppRoutes.contactTypeEdit,
];

export const SystemSettingRoutes = [AppRoutes.emailSetting, AppRoutes.maintenanceSetting];

export const ChatRoutes = [AppRoutes.chat, AppRoutes.chatDetail];

export const LoginRoutes = [AppRoutes.login, AppRoutes.recover, AppRoutes.resetPassword, AppRoutes.register];

export const AdminRoutes = [CompanyRoutes, AdminUserRoutes, informationRoutes, ConvertRoutes, MasterDataRoutes].flat();
