import { InputMsg } from "@/features/chat/components/InputMsg";
import { NoneSelectedRoom } from "@/features/chat/components/NoneSelectedRoom";
import { Room } from "@/features/chat/components/Room";
import { RoomHead } from "@/features/chat/components/RoomHead";
import { RoomList } from "@/features/chat/components/RoomList";
import { ChatProvider } from "@/features/chat/providers/ChatProvider";
import { SocketProvider } from "@/providers/SocketProvider";
import { Box, styled } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";

type TChatPageProps = {};

export const ChatPage: FC<TChatPageProps> = () => {
  const { id } = useParams();

  return (
    <SocketProvider>
      <ChatProvider>
        <ChatPageContainer>
          <RoomListContainer>
            <RoomList />
          </RoomListContainer>
          <ChatContainer>
            {id ? (
              <>
                <RoomHead />
                <Room key={id} />
                <InputMsg />
              </>
            ) : (
              <NoneSelectedRoom />
            )}
          </ChatContainer>
        </ChatPageContainer>
      </ChatProvider>
    </SocketProvider>
  );
};

const ChatPageContainer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 8px;
  width: 100%;
  color: black;
  height: calc(100vh - 100px);
`;

const RoomListContainer = styled(Box)`
  width: 300px;
  height: calc(100% - 16px);
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

const ChatContainer = styled(Box)`
  padding: 8px;
  flex: 1;
  display: flex;
  gap: 16px;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`;
