import { TGetContactDetailResDto } from "@/api/services/admin/contact/dtos/get-contact-detail.dto";
import { TGetContactListReqDto, TGetContactListResDto } from "@/api/services/admin/contact/dtos/get-contact-list.dto";
import { TUpdateContactResDto } from "@/api/services/admin/contact/dtos/update-contact.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { usePagination } from "@/hooks/usePagination";
import { TApiSortsReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchContactListApi = async (req: TGetContactListReqDto): Promise<TGetContactListResDto> => {
  const url = createUrlWithParams("/contacts", req);
  const { data } = await clientApi.get<TGetContactListResDto>(url);
  return data;
};

export const useContactList = (isResolved: boolean | boolean[], sorts?: TApiSortsReq[]) => {
  const pagReq = usePagination();
  const contactReq = { ...pagReq, isResolved, sorts };

  return useQuery({
    queryKey: [QUERY_KEYS.CONTACT.FETCH_LIST, contactReq],
    queryFn: () => fetchContactListApi(contactReq),
    enabled: Boolean(isResolved),
  });
};

export const fetchContactDetailApi = async (contactId: string): Promise<TGetContactDetailResDto> => {
  const rs = await clientApi.get<TGetContactDetailResDto>(`/contacts/${contactId}`);
  return rs.data;
};

export const useContact = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.CONTACT.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchContactDetailApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const updateContact = async (contactId: number): Promise<TUpdateContactResDto> => {
  const rs = await clientApi.put<TUpdateContactResDto>(`/contacts/${contactId}`);
  return rs.data;
};
