import { refreshTokenApi } from "@/api/services/admin/auth";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { ERROR_STATUS_CODES } from "@/constants/error";
import { EVENTS } from "@/constants/socket";
import { logout } from "@/utils/api";
import { toast } from "@/utils/toast";
import Cookies from "js-cookie";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_API_HOST || "http://localhost:3000";

interface ISocketContext {
  socket: Socket | null;
}

interface SocketProviderProps {
  children: ReactNode;
}

// Create a context
const SocketContext = createContext<ISocketContext | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }

  return context;
};
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connected, setConnected] = useState(false);

  const connectSocket = () => {
    const token = Cookies.get("admin-token");
    if (!token) return;
    const socketInstance = io(SOCKET_SERVER_URL, { extraHeaders: { Authorization: `Bearer ${token}` } });
    setSocket(socketInstance);
  };

  async function handleUnauthorized() {
    try {
      const email = Cookies.get("email");
      const refreshToken = Cookies.get("admin-refresh-token");
      if (!email || !refreshToken) {
        logout();
        return;
      }
      await refreshTokenApi({ email, refreshToken });
      connectSocket();
    } catch (error) {
      logout();
    }
  }

  useEffect(() => {
    connectSocket();
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.on(EVENTS.GLOBAL.ERROR, (message) => {
      switch (message) {
        case ERROR_STATUS_CODES.UNAUTHORIZED:
          handleUnauthorized();
          break;
        default:
          toast.error(message);
      }
    });
    socket.on(EVENTS.GLOBAL.CONNECTED, () => {
      setConnected(true);
    });
  }, [socket]);

  if (!connected) return <LoadingPage />;

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};
