import * as React from "react";

export const IconOrderTable = (props: React.SVGProps<SVGSVGElement>): React.JSX.Element => (
  <svg width="1em" height="1em" viewBox="0 0 11 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="2" cy="2" r="2" fill="currentColor" />
    <circle cx="9" cy="2" r="2" fill="currentColor" />
    <circle cx="2" cy="9" r="2" fill="currentColor" />
    <circle cx="9" cy="9" r="2" fill="currentColor" />
    <circle cx="2" cy="16" r="2" fill="currentColor" />
    <circle cx="9" cy="16" r="2" fill="currentColor" />
  </svg>
);
