export const PLAIN_TEXT = {
  numberOrder: "番号",
  employeeFullName: "氏名",
  employeeNationality: "国籍",
  employeeBirthday: "生年月日",
  employeeGender: "性別",
  employeeResidenceCardNumber: "在留カード番号",
  employeeZipCode: "郵便番号",
  employeeAddress: "住所",
  isChangeWorkingLocation: "活動場所",
  isChangeWorkingDetail: "活動内容",
  isChangeDispatchPlace: "派遣先名称等",
  month: "該当月",
  workingDay: "活動日数",
  totalAmountPaid: "支給総額",
  netAmountPaid: "差引支給額",
  legalDeductionAmount: "法定控除額",
  hasComparisonEmployee: "比較対象とした従業員",
  noChange: "変更なし",
  change: "変更あり",
  yes: "いる",
  no: "いない",
};
