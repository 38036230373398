import { Tag } from "@/components/elements/Tag";
import { background, blue, text } from "@/theme/colors";
import { FCC } from "@/types/common";
import { Stack, Typography } from "@mui/material";

export type TNotificationProps = {
  title: string;
  tag: string;
};

export const Notification: FCC<TNotificationProps> = ({ title, tag, children }) => {
  return (
    <Stack p={3} gap={3} bgcolor={background.white}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="sub16Semi">{title}</Typography>
        <Tag color={blue[60]} variant="bold" title={tag} sx={{ color: text.white }} />
      </Stack>
      {children}
    </Stack>
  );
};
