import { validator } from "@/utils/validator";

export const informationSchema = validator.object().shape({
  type: validator.string().emptyToNull().label("タグ").required(),
  title: validator.string().emptyToNull().label("タイトル").required(),
  content: validator.string().emptyToNull().label("本文").required(),
  startDate: validator
    .mixed()
    .isSelection()
    .isDayjs()
    .when("enableStartDate", ([enableStartDate], schema) => {
      if (enableStartDate) return schema.required();
      return schema.nullable();
    })
    .label("公開開始日時")
    .required()
    .nullable(),
  endDate: validator
    .mixed()
    .isSelection()
    .isDayjs()
    .isSameOrAfterDate("startDate", { startLabel: "公開開始日時", endLabel: "公開終了日時" })
    .when("enableEndDate", ([enableStartDate], schema) => {
      if (enableStartDate) return schema.required();
      return schema.nullable();
    })
    .label("公開終了日時")
    .required()
    .nullable(),
  enableStartDate: validator.boolean().required(),
  enableEndDate: validator.boolean().required(),
  filePath: validator.string().emptyToNull().label("ファイル添付").required().nullable(),
});
