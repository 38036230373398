import { useFormField } from "@/components/elements/FormField";
import { Radio } from "@/components/elements/Radio";
import { Stack } from "@mui/material";
import { ChangeEvent, FC, Key, useEffect, useState } from "react";

export type TRadioOption = {
  value: Key;
  label: string;
  subLabel?: string;
};

type TRadioGroupProps = {
  options: TRadioOption[];
  nonControl?: boolean;
  direction?: "row" | "column";
  value?: Key;
  onChange?: (value: Key) => void;
};

export const RadioGroup: FC<TRadioGroupProps> = ({ options, nonControl = false, value, direction = "row", onChange }) => {
  const [checkedValue, setCheckedValue] = useState<Key>();
  const { field } = useFormField(nonControl);

  useEffect(() => {
    setCheckedValue(field?.value ?? value ?? []);
  }, [field?.value, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedValue(value);
      onChange?.(value);
      field?.onChange(value);
      field?.onBlur();
    }
  };

  return (
    <Stack direction={direction} gap={direction === "row" ? 3 : 1}>
      {options.map(({ label, subLabel, value }, index) => {
        return (
          <Radio key={index} label={label} subLabel={subLabel} value={value} checked={checkedValue === value} onChange={handleChange} nonControl />
        );
      })}
    </Stack>
  );
};
