import { createContactTypeApi, updateContactTypeApi } from "@/api/services/admin/contact-type";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Textarea } from "@/components/elements/Textarea";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { COMPANY_CONTAINER } from "@/constants/ui";
import { contactTypeSchema } from "@/features/contact-type/schema/contactTypeSchema";
import { TContactTypeForm } from "@/features/contact-type/types/contactTypeForm";
import { convertContactTypeFormToCreateReq, convertContactTypeFormToUpdateReq } from "@/features/contact-type/utils/convert";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { setErrorForm, showError } from "@/utils/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
type TContactTypeFormProps = {
  previousData?: Partial<TContactTypeForm>;
};
const initForm: TContactTypeForm = {
  name: "",
  template: "",
  email: "",
};
export const ContactTypeForm: FC<TContactTypeFormProps> = ({ previousData }) => {
  const { id } = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TContactTypeForm>({
    defaultValues: previousData ?? initForm,
    resolver: yupResolver(contactTypeSchema),
    mode: "onBlur",
  });
  const { control } = methods;
  const onSubmit = async (data: TContactTypeForm) => {
    try {
      if (id) {
        const payload = convertContactTypeFormToUpdateReq(data);
        const rs = await updateContactTypeApi(id, payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_DETAIL, id] });
      } else {
        const payload = convertContactTypeFormToCreateReq(data);
        const rs = await createContactTypeApi(payload);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      navigate(AppRoutes.contactType);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONTACT_TYPE.FETCH_LIST] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };
  return (
    <Stack maxWidth={COMPANY_CONTAINER} gap={2}>
      <Typography variant="head20Semi" fontWeight={100}>
        {previousData ? "お問い合わせ種類の編集" : "新しいお問い合わせの種類を作成する"}
      </Typography>
      <Form methods={methods} onSubmit={onSubmit} schema={contactTypeSchema}>
        <Stack width="100%" maxWidth={1045} mx="auto">
          <Stack gap={2}>
            <Typography variant="sub16Bold">お問い合わせ内容</Typography>
            <FormField control={control} name="name">
              <Input size="lg" />
            </FormField>
            <FormField control={control} name="template">
              <Textarea />
            </FormField>
            <FormField control={control} name="email">
              <Input size="lg" />
            </FormField>
          </Stack>
          <Stack alignItems="end" paddingTop={3}>
            <Button type="submit">更新</Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
