import { createMainOccupationApi, updateMainOccupationApi } from "@/api/services/global/master";
import { TGetMainOccupationListOutputDto } from "@/api/services/global/master/dtos/get-master-main-occupation-list.dto";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { masterDataMainOccupationSchema } from "@/features/master-data/schema/masterDataMainOccupationSchema";
import { TMasterDataMainOccupationForm } from "@/features/master-data/types/masterDataMainOccupationForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";

type TMainOccupationFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedMainOccupation?: TGetMainOccupationListOutputDto;
};

export const MainOccupationFormModal: FC<TMainOccupationFormModalProps> = ({ onClose, isOpen, selectedMainOccupation }) => {
  const queryClient = useQueryClient();

  const defaultValues = useMemo(
    () => ({
      mainOccupation: selectedMainOccupation ? selectedMainOccupation.mainOccupation : undefined,
      fmMainOccupationRenewVisaCsvCode: selectedMainOccupation ? selectedMainOccupation.fmMainOccupationRenewVisaCsvCode : undefined,
    }),
    [selectedMainOccupation],
  );

  const methods = useForm<TMasterDataMainOccupationForm>({
    resolver: yupResolver(masterDataMainOccupationSchema),
    mode: "onBlur",
    defaultValues,
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterDataMainOccupationForm) => {
    try {
      if (selectedMainOccupation) {
        const rs = await updateMainOccupationApi({
          mainOccupations: [
            {
              id: toNumber(selectedMainOccupation.id),
              mainOccupation: data.mainOccupation,
              fmMainOccupationRenewVisaCsvCode: data.fmMainOccupationRenewVisaCsvCode,
            },
          ],
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      } else {
        const rs = await createMainOccupationApi({
          mainOccupation: data.mainOccupation,
          fmMainOccupationRenewVisaCsvCode: data.fmMainOccupationRenewVisaCsvCode,
        });
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_OCCUPATION] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title={selectedMainOccupation ? "編集" : "追加"} isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataMainOccupationSchema}>
        <Stack gap={3} my={3}>
          <Grid item xs={6}>
            <FormField control={control} name="mainOccupation">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="fmMainOccupationRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
        </Stack>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </Stack>
      </Form>
    </Modal>
  );
};
