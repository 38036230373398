import { validator } from "@/utils/validator";

export const specificBusinessClassificationSchema = validator.object().shape({
  specificBusinessClassificationName: validator.string().emptyToNull().label("業務区分（外国人用、期間更新の表記）").required(),
  fhSpecificBusinessClassificationRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列FH）").required(),
  companySpecificBusinessClassificationId: validator.string().isSelection().emptyToNull().label("業務区分（会社情報用）)").required(),
  anyTimeSpecificBusinessClassificationName: validator.string().emptyToNull().label("業務区分（随時届出)").required(),
  specificBusinessClassificationAnyTimeCsvCode: validator.string().emptyToNull().label("随時届出CSVのコード").required(),
});

export const masterDataCreateSpecificIndustrySchema = validator.object().shape({
  regularAnyTimeSpecificIndustry: validator.string().emptyToNull().label("特定産業分野（定期届出&随時届出の表記）").required(),
  renewVisaSpecificIndustry: validator.string().emptyToNull().label("特定産業分野（期間更新の表記）").required(),
  companySpecificIndustryId: validator.string().isSelection().emptyToNull().label("特定産業分野（会社情報用）").required(),
  residenceStatusId: validator.string().isSelection().emptyToNull().label("在留資格（外国人）").required(),
  fgSpecificIndustryRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列FG）").required(),
  specificBusinessClassifications: validator.array().of(specificBusinessClassificationSchema).label("occupations").required(),
});

export const masterDataUpdateSpecificIndustrySchema = validator.object().shape({
  regularAnyTimeSpecificIndustry: validator.string().emptyToNull().label("特定産業分野（定期届出&随時届出の表記）").required(),
  renewVisaSpecificIndustry: validator.string().emptyToNull().label("特定産業分野（期間更新の表記）").required(),
  companySpecificIndustryId: validator.string().isSelection().emptyToNull().label("特定産業分野（会社情報用）").required(),
  residenceStatusId: validator.string().isSelection().emptyToNull().label("在留資格（外国人）").required(),
  fgSpecificIndustryRenewVisaCsvCode: validator.string().emptyToNull().label("期間更新CSVのコード（列FG）").required(),
});
