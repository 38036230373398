import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useSorting = () => {
  const { pathname, search } = useLocation();

  const sorts = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const data = query.getAll("sorts");

    if (!data) return [];
    return [data].flat();
  }, [search, pathname]);

  return { sorts };
};
