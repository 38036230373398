import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { AdminUserForm } from "@/features/admin-user/components/AdminUserForm";
import { useAdminUser } from "@/api/services/admin/user";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { convertUserDetailToForm } from "@/features/admin-user/utils/converts";

export const AdminUserEditPage: FC = () => {
  const { id } = useParams();
  const { data: adminUserResult, isLoading } = useAdminUser();
  const adminUserData = adminUserResult?.data;

  if (isLoading || !adminUserData) return <LoadingPage />;

  return (
    <Stack gap={4}>
      <TopPage title="irohana担当者の編集" backUrl={getDynamicRoute(AppRoutes.adminUserDetail, { id })} />
      <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
        <AdminUserForm previousData={convertUserDetailToForm(adminUserData)} />
      </InnerBox>
    </Stack>
  );
};
