import { TConvertRequestExtends } from "@/api/entities/convert-request-extend.entity";
import { PAYROLL_STATUS } from "@/features/convert/constants/payrollStatus";
import { TConvertDataRecord } from "@/features/convert/types/convertDataRecord";
import { TPayrollRecord } from "@/features/convert/types/payrollRecord";
import { TPayrollTableRow } from "@/features/convert/types/payrollTableRow";

export const convertConvertDataToTableRecord = (data: TConvertRequestExtends[]): TConvertDataRecord[] => {
  return data.map((item) => ({
    id: item.id,
    content: item.content,
    majorItem: item.majorItem,
    mediumItem: item.mediumItem,
    minorItem: item.minorItem ?? "",
  }));
};

export const convertToDataTableRow = (payrolls: TPayrollRecord[]): TPayrollTableRow[] => {
  const dataError: TPayrollTableRow[] = [];
  const dataGroupByNumberOrder: Record<string, TPayrollRecord[]> = {};

  const dataRows: TPayrollRecord[] = [];

  payrolls.forEach((payroll) => {
    if (payroll.status === PAYROLL_STATUS.ERROR) {
      dataError.push(payroll);
      return;
    }
    dataRows.push(payroll);
    if (dataGroupByNumberOrder[payroll.no]) {
      dataGroupByNumberOrder[payroll.no].push(payroll);
    } else {
      dataGroupByNumberOrder[payroll.no] = [payroll];
    }
  });

  const data: TPayrollTableRow[] = Object.values(dataGroupByNumberOrder).map((items) => ({ ...items[0], children: items }));

  return [dataError, data].flat();
};
