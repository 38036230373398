import { useEnum } from "@/api/services/global/enum";
import { useGetRegionList } from "@/api/services/global/master";
import { InnerBox } from "@/components/elements/InnerBox";
import { Table } from "@/components/elements/Table";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { TRetirementEmployeeLite } from "@/features/convert/types/retirementEmployeeLite";
import { useTable } from "@/hooks/useTable";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { getFullName, getFullAddress } from "@/utils/string";
import { Box, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { useMemo } from "react";

type TConvertRetirementEmployeeTableProps = {
  employee: TRetirementEmployeeLite;
};

export const ConvertRetirementEmployeeTable = ({ employee }: TConvertRetirementEmployeeTableProps) => {
  const { data: genderOptions } = useEnum("EGender");
  const { nationalityOptions } = useGetRegionList();

  const columns = useMemo<MRT_ColumnDef<TRetirementEmployeeLite>[]>(
    () => [
      {
        header: "氏名",
        Cell: ({ row }) =>
          getFullName({ lastName: row.original?.lastName, middleName: row.original?.middleName, firstName: row.original?.firstName }),
        size: 120,
      },
      {
        header: "性別",
        Cell: ({ row }) => findLabelByValue(genderOptions, row.original?.gender) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "生年月日",
        Cell: ({ row }) => (row.original?.birthday ? dayjs(row.original?.birthday).format(COMPACT_DATE_FORMAT) : EMPTY_STR.DATE),
        size: 120,
      },
      {
        header: "国籍",
        id: "employeeNationality",
        Cell: ({ row }) => findLabelByValue(nationalityOptions, row.original?.nationalityId) || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "在留カード番号",
        Cell: ({ row }) => row.original?.residenceCode || EMPTY_STR.TEXT,
        size: 120,
      },
      {
        header: "郵便番号",
        Cell: ({ row }) => (row.original?.zipCode ? convertNumberToZipCode(row.original?.zipCode) : EMPTY_STR.TEXT),
        size: 120,
      },
      {
        header: "住所",
        size: 120,
        Cell: ({ row }) => {
          const { province, address, municipality } = row.original;
          const fullAddress = getFullAddress({ province: province, municipality: municipality, address: address });
          return fullAddress || EMPTY_STR.TEXT;
        },
      },
      {
        header: "電話番号",
        size: 120,
        Cell: ({ row }) => {
          return row.original.telNumber || EMPTY_STR.TEXT;
        },
      },
      {
        header: "特定産業分野",
        size: 120,
        Cell: ({ row }) => {
          return row.original.specificIndustry || EMPTY_STR.TEXT;
        },
      },
      {
        header: "業務区分",
        size: 120,
        Cell: ({ row }) => {
          return row.original.contractSpecificBusinessClassificationOne || EMPTY_STR.TEXT;
        },
      },
    ],
    [genderOptions, nationalityOptions],
  );

  const { table } = useTable({ columns, rows: [employee] ?? [] });
  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">対象の外国人</Typography>
        <Box maxHeight={586}>
          <Table table={table} />
        </Box>
      </Stack>
    </InnerBox>
  );
};
