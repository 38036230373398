import { IconClose } from "@/assets/icons";
import sidebarLogo from "@/assets/images/sidebar_logo.png";
import { Linkable } from "@/components/elements/Linkable";
import { menus } from "@/constants/menu";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { background, black, divider, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { TMenu } from "@/types/menu";
import { hexToRgba } from "@/utils/pieces";
import { Drawer, Stack, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import zIndex from "@mui/material/styles/zIndex";
import { FC, useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

type TAppDrawerProps = {
  isOpenDrawer: boolean;
  toggleDrawer: () => void;
};

export const AppDrawer: FC<TAppDrawerProps> = ({ isOpenDrawer, toggleDrawer }) => {
  const { isLaptop } = useMediaQuery();
  const { pathname } = useLocation();

  const DrawerContent = useMemo(
    () => (
      <ListWrapper gap={1}>
        <Stack gap={2}>
          {menus.map((item) => (
            <MenuItem key={item.text} {...item} />
          ))}
        </Stack>
        <Logo>
          <img src={sidebarLogo} alt="logo" />
        </Logo>
      </ListWrapper>
    ),
    [pathname],
  );

  if (!isLaptop) return DrawerContent;
  return (
    <Drawer
      open={isOpenDrawer}
      onClose={toggleDrawer}
      hideBackdrop
      ModalProps={{ sx: { width: "fit-content" } }}
      PaperProps={{ sx: { bgcolor: background.main } }}
    >
      <IconCloseWrapper>
        <IconCloseBtn onClick={toggleDrawer} />
      </IconCloseWrapper>
      {DrawerContent}
    </Drawer>
  );
};

const MenuItem: FC<TMenu> = (props) => {
  const { pathname } = useLocation();

  const isActive = props.relatedRoutes?.some((route) => Boolean(matchRoutes([{ path: route }], pathname)));

  const childrenContent = useMemo(() => {
    return props.children?.map((item) => <MenuItem key={item.text} {...item} />);
  }, [props.children]);

  const content = useMemo(() => {
    const Icon = props.icon;
    return (
      <Stack>
        <Stack p={1} direction="row" alignItems="center" gap={1}>
          {Icon && <Icon fontSize={16} color={text.white} />}
          {!props.children && <Box width={10} />}
          <Typography variant="body14Bold" color={props.path ? text.white : text.tertiary}>
            {props.text}
          </Typography>
        </Stack>
        <Stack gap={1}>{childrenContent}</Stack>
      </Stack>
    );
  }, [props.text, props.icon]);

  if (!props.path) return content;
  return (
    <ListItemWrapper className={isActive ? "active" : ""} href={props.path}>
      {content}
    </ListItemWrapper>
  );
};

const ListWrapper = styled(Stack)`
  padding: ${spacing.md} ${spacing.sm};
  min-width: 242px;
  background-color: ${black[30]};
  color: ${text.white};
  min-height: calc(100vh - 57px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 57px;
  z-index: ${zIndex.drawer};
`;

const ListItemWrapper = styled(Linkable)`
  transition: all 0.2s ease-in-out;
  border-radius: ${rounded.sm};
  &.active {
    background-color: ${hexToRgba(background.white, 0.3)} !important;
  }

  &:hover {
    background-color: ${hexToRgba(background.white, 0.1)};
  }
`;

const IconCloseWrapper = styled(Box)`
  background-color: ${background.dark};
  height: 54px;
  border-bottom: 1px solid ${divider.lower};
  display: flex;
  align-items: center;
  padding: 16px;
`;

const IconCloseBtn = styled(IconClose)`
  font-size: 32px;
  color: ${text.white};
  transition: all 0.2s ease-in-out;
  border-radius: 32px;
  &:hover {
    cursor: pointer;
    background-color: ${hexToRgba(background.white, 0.3)};
  }
`;

const Logo = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`;
