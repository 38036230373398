import { TGetConvertRequestListResDto } from "@/api/services/admin/convert-request/dtos/get-convert-request-list.dto";
import { Table } from "@/components/elements/Table";
import { useConvertColumns } from "@/features/convert/hooks/useConvertColumns";
import { useTable } from "@/hooks/useTable";
import { AppRoutes } from "@/routes/config";
import { getDynamicRoute } from "@/utils/url";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type TConvertTableProps = {
  isLoading: boolean;
  data: TGetConvertRequestListResDto;
};

export const ConvertTable: FC<TConvertTableProps> = ({ isLoading, data }) => {
  const navigate = useNavigate();
  const columns = useConvertColumns({ hightLightOverdue: true });
  if (!columns) return;

  const { table } = useTable({
    columns,
    rows: data.data,
    onClickRow: (row) => navigate(getDynamicRoute(AppRoutes.convertDetail, { id: row.id })),
    pinningColumns: ["action"],
  });

  return <Table table={table} isLoading={isLoading} />;
};
