import { useInformationList } from "@/api/services/admin/information";
import { Button } from "@/components/elements/Button";
import { Pagination } from "@/components/elements/Pagination";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

type TInformationListTopProps = {};

export const InformationListTop: FC<TInformationListTopProps> = () => {
  const { data } = useInformationList();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={data?.pagination.totalPage} />
      <Button component={Link} size="sm" variant="outline" to={AppRoutes.informationCreate}>
        新規作成
      </Button>
    </Stack>
  );
};
