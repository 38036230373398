import { TCreateMessageReqDto, TCreateMessageResDto } from "@/api/services/admin/conversation/dtos/create-message.dto";
import { TGetConversationListReqDto, TGetConversationListResDto } from "@/api/services/admin/conversation/dtos/get-conversation-list.dto";
import { TGetConversationResDto } from "@/api/services/admin/conversation/dtos/get-conversation.dto";
import { TGetMessageListByConversationResDto } from "@/api/services/admin/conversation/dtos/get-message-list-by-conversation.dto";
import { TSetReadMessageResDto } from "@/api/services/admin/conversation/dtos/set-read-message.dto";
import { DEFAULT_PAGINATION_REQUEST } from "@/constants/pagination";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { TApiPagReq } from "@/types/api";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

type TMessageListByConversationProps = {
  enabled?: boolean;
};

type TSetReadMessageProps = {
  conversationId: string;
  messageId: string;
};

export const fetchConversationListApi = async (req: TGetConversationListReqDto): Promise<TGetConversationListResDto> => {
  const url = createUrlWithParams("/conversations", req);
  const { data } = await clientApi.get<TGetConversationListResDto>(url);
  return data;
};

export const useConversationList = (req?: TGetConversationListReqDto) => {
  const reqs = {
    ...DEFAULT_PAGINATION_REQUEST,
    ...req,
  };
  return useInfiniteScroll([QUERY_KEYS.CONVERSATION.FETCH_LIST, req], (page) => fetchConversationListApi({ ...reqs, page }));
};

export const fetchConversationApi = async (conversationId: string): Promise<TGetConversationResDto> => {
  const rs = await clientApi.get<TGetConversationResDto>(`/conversations/${conversationId}`);
  return rs.data;
};

export const useConversation = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.CONVERSATION.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchConversationApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const fetchMessageListByConversationApi = async (conversationId: string, req: TApiPagReq): Promise<TGetMessageListByConversationResDto> => {
  const url = createUrlWithParams(`/conversations/${conversationId}/messages`, req);
  const { data } = await clientApi.get<TGetMessageListByConversationResDto>(url);
  return data;
};

export const useMessageListByConversation = ({ enabled = true }: TMessageListByConversationProps) => {
  const { id } = useParams();

  return useInfiniteScroll(
    [QUERY_KEYS.CONVERSATION.FETCH_MESSAGE_LIST, id],
    (page) => fetchMessageListByConversationApi(id ?? "", { ...DEFAULT_PAGINATION_REQUEST, page, perPage: 20 }),
    { enabled: enabled },
  );
};

export const createMessageApi = async (conversationId: string, payload: TCreateMessageReqDto): Promise<TCreateMessageResDto> => {
  const { data } = await clientApi.post<TCreateMessageResDto>(`/conversations/${conversationId}/messages`, payload);
  return data;
};

export const setReadMessageApi = async ({ conversationId, messageId }: TSetReadMessageProps): Promise<TSetReadMessageResDto> => {
  const { data } = await clientApi.post<TSetReadMessageResDto>(`/conversations/${conversationId}/messages/${messageId}/set-read`);
  return data;
};
