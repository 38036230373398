import { TEnumName, useEnum } from "@/api/services/global/enum";
import { IconClose, IconExpandMore } from "@/assets/icons";
import { useFormField } from "@/components/elements/FormField/FormField";
import { IconBox } from "@/components/elements/IconBox";
import { background, blue, button, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { TOption, TRequiredOne } from "@/types/common";
import {
  Box,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
  Typography,
  css,
  styled,
} from "@mui/material";
import { FC, ReactNode, useMemo } from "react";

export type TSelectMultiSize = "md" | "lg";

export type TSelectMultiProps = {
  size?: TSelectMultiSize;
  readonly?: boolean;
  previousValue?: string | number;
  helperText?: string;
  nonControl?: boolean;
  loading?: boolean;
  onClickCloseIcon?: () => void;
} & Omit<SelectProps, "size" | "multiple" | "children" | "readOnly"> &
  TRequiredOne<{ options: TOption[]; enumName: TEnumName }>;

export const SelectMulti: FC<TSelectMultiProps> = ({
  size = "md",
  readonly = false,
  previousValue = false,
  nonControl = false,
  placeholder = "選択してください",
  helperText,
  options,
  enumName,
  loading = false,
  disabled,
  onClickCloseIcon,
  ...rest
}) => {
  const { field, fieldState } = useFormField(nonControl);
  const { data, isLoading } = useEnum(enumName);
  const value = field ? field.value : rest.value;

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(previousValue) && value === previousValue;
  }, [fieldState?.isDirty, previousValue, value]);

  const selectOptions = useMemo(() => options ?? data ?? [], [options, data]);

  const selectedOptions = useMemo(() => selectOptions.map((item) => (value?.includes(item.value) ? item : undefined)), [value, selectOptions]).filter(
    (item) => !!item,
  );

  const IconComponent = useMemo(() => {
    if (selectedOptions.length) {
      return () => (
        <IconBox
          sx={{ mr: 1 }}
          onClick={() => {
            field?.onChange([]);
            field?.onBlur();
            onClickCloseIcon && onClickCloseIcon();
          }}
        >
          {!readonly && !disabled && <IconClose fontSize={20} color={text.primary} />}
        </IconBox>
      );
    }
    return isLoading || loading ? () => null : IconExpandMore;
  }, [isLoading, loading, selectedOptions]);

  const onChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
    rest.onChange?.(e, child);
    field?.onChange(e);
    field?.onBlur();
  };

  return (
    <Box position="relative">
      <StyledSelect
        error={Boolean(fieldState?.error)}
        selectSize={size}
        readonly={readonly}
        previous={isPrevious}
        multiple
        displayEmpty
        IconComponent={IconComponent}
        {...rest}
        {...field}
        disabled={disabled}
        value={value ?? []}
        onChange={onChange}
        renderValue={() => {
          if (selectedOptions.length === 0) {
            return (
              <Typography variant="body14" color={`${text.tertiary}!important`}>
                {placeholder}
              </Typography>
            );
          }

          return selectedOptions.map((item) => item?.label).join(", ");
        }}
      >
        {selectOptions.map((option) => {
          const isDisable = "hidden" in option && option.hidden === true;
          const isHidden = isDisable && !value?.includes(option.value);
          return (
            !isHidden && (
              <MenuItem key={option.value} value={option.value} disabled={isDisable}>
                {option.label}
              </MenuItem>
            )
          );
        })}
      </StyledSelect>
      {(isLoading || loading) && (
        <LoadingWrap>
          <CircularProgress color="inherit" size={14} />
        </LoadingWrap>
      )}
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};

const options = { shouldForwardProp: (propName: string) => !["selectSize", "previous", "readonly"].includes(propName) };

const StyledSelect = styled(MuiSelect, options)<{ selectSize: TSelectMultiSize; readonly: boolean; previous: boolean }>`
  width: 100%;
  &.MuiInputBase-root {
    position: relative;
    background: ${background.white};
    .MuiSelect-select {
      color: ${text.primary};
    }
    &::placeholder {
      color: ${text.tertiary};
    }
    fieldset {
      border-color: ${divider.middle};
      border-radius: ${rounded.xs};
      border-width: 1px !important;
    }
    &:hover,
    &.Mui-focused {
      fieldset {
        border-color: ${blue[70]};
      }
    }
    &.Mui-focused {
      &::after {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: 100%;
        height: 100%;
        padding: 2px;
        border: 2px solid ${button.secondary};
        box-sizing: content-box;
        border-radius: ${rounded.sm};
        z-index: -1;
      }
    }

    &.Mui-error {
      background: ${background.error};
      fieldset {
        border-color: ${state.error_1}!important;
      }
    }
    &.Mui-disabled {
      background: ${background.disable};
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    .MuiSelect-icon {
      transform: none !important;
      color: ${text.primary};
    }

    ${({ selectSize }) => {
      if (selectSize === "md") return MediumInput;
      if (selectSize === "lg") return LargeInput;
      return MediumInput;
    }};
    ${({ readonly }) => readonly && readonlyInput};
    ${({ previous }) => previous && PreviousInput};
  }
`;

const readonlyInput = css`
  background: ${background.disable};
  pointer-events: none;
  .MuiSelect-select {
    color: ${text.primary} !important;
  }
  fieldset {
    border-color: ${divider.middle}!important;
  }
`;

const PreviousInput = css`
  .MuiSelect-select {
    color: ${lightBlue[60]};
  }
`;

const MediumInput = css`
  .MuiInputBase-input {
    padding: 6px 8px;
    ${css(typography.body14)};
  }
`;

const LargeInput = css`
  .MuiInputBase-input {
    padding: 12px 8px;
    ${css(typography.body14)};
  }
`;

const LoadingWrap = styled(Box)`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30px;
`;
