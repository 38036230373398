import { useCallback, useState } from "react";

export type UseVisibleReturn = {
  visible: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  set: (visible: boolean) => void;
};

/**  custom hook to show or hide */
export const useVisible = (initialState = false): UseVisibleReturn => {
  const [visible, setVisible] = useState(initialState);

  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);
  const toggle = useCallback(() => setVisible((prev) => !prev), []);

  return { visible, open, close, toggle, set: setVisible };
};
