import { Modal } from "@/components/elements/Modal";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TConfirmDeleteModalProps = {
  message: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
};

export const ConfirmDeleteModal: FC<TConfirmDeleteModalProps> = ({ open, onClose, onOk, message }) => {
  return (
    <Modal isOpen={open} onClose={onClose} onOk={onOk} labelClose="いいえ" labelOk="はい" width={500} title="削除の確認">
      <Stack mb={2}>
        <Typography>{message}</Typography>
      </Stack>
    </Modal>
  );
};
