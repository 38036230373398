import { deleteCompanyUserApi, useCompanyUser } from "@/api/services/admin/company/user";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";
import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CompanyUserForm } from "@/features/company/components/CompanyUserForm";
import { convertCompanyUserResToForm } from "@/features/company/utils/converts";
import { useDialog } from "@/hooks/useDialog";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TCompanyUserEditPageProps = {};

export const CompanyUserEditPage: FC<TCompanyUserEditPageProps> = () => {
  const { id } = useParams();
  const { data: companyUserResult, isLoading } = useCompanyUser();
  const companyUserData = companyUserResult?.data;
  const confirmDelete = useDialog();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      if (!companyUserData?.id) throw new Error();
      if (!companyUserData?.companyId) throw new Error();
      const agreeToDelete = await confirmDelete.show();
      if (!agreeToDelete) return;
      const rs = await deleteCompanyUserApi(companyUserData.companyId, companyUserData.id);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      navigate(getDynamicRoute(AppRoutes.companyDetail, { id: companyUserData.companyId }));

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST] });
    } catch (error) {
      showError(error);
    }
  };

  if (isLoading || !companyUserData) return <LoadingPage />;

  return (
    <Stack gap={4}>
      <TopPage
        title="企業ユーザーの編集"
        backUrl={getDynamicRoute(AppRoutes.companyDetail, { id })}
        rightContent={
          <Button variant="outline" size="lg" onClick={handleDelete}>
            削除
          </Button>
        }
      />
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyUserForm previousData={convertCompanyUserResToForm(companyUserData)} />
      </InnerBox>
      <ConfirmDeleteModal
        open={confirmDelete.open}
        onClose={confirmDelete.cancel}
        onOk={confirmDelete.confirm}
        message="企業ユーザーを削除してもよろしいですか？"
      />
    </Stack>
  );
};
