import { useEnum } from "@/api/services/global/enum";
import { FileItem } from "@/components/elements/FileItem";
import { Modal } from "@/components/elements/Modal";
import { Notification } from "@/components/elements/Notification";
import { QuoteScope } from "@/components/elements/QuoteScope";
import { DATE_FORMAT } from "@/constants/datetime";
import { INFORMATION_TYPE } from "@/constants/enum";
import { INVALID_NOT_INPUT_REQUIRE_FIELD } from "@/constants/invalids";
import { EMPTY_STR } from "@/constants/string";
import { informationSchema } from "@/features/information/schema/informationSchema";
import { TInformationForm } from "@/features/information/types/informationForm";
import { useToast } from "@/hooks/useToast";
import { background } from "@/theme/colors";
import { TExtendFile } from "@/types/file";
import { dayjs } from "@/utils/dayjs";
import { findLabelByValue } from "@/utils/object";
import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

type TPreviewInformationModalProps = {
  open: boolean;
  onClose: () => void;
  file?: TExtendFile | null;
};

export const PreviewInformationModal: FC<TPreviewInformationModalProps> = ({ open, onClose, file }) => {
  const { data: informationTypes } = useEnum("EInformationType");
  const toast = useToast();
  const { getValues } = useFormContext<Partial<TInformationForm>>();
  const value = getValues();

  useEffect(() => {
    if (!value.type) {
      toast.error(INVALID_NOT_INPUT_REQUIRE_FIELD);
      onClose();
    }
  }, []);

  const renderContent = useMemo(() => {
    return (
      <Stack gap={1}>
        <Typography variant="sub16Semi">{value.title}</Typography>
        {value.content && <Box dangerouslySetInnerHTML={{ __html: value.content }} />}
        {value.filePath && <FileItem downloadable filePath={value.filePath} width={350} enableFileSize />}
      </Stack>
    );
  }, [value, file]);

  if (!value.type) return null;

  return (
    <Modal isOpen={open} onClose={onClose} title={<Typography variant="head20Bold">プレビュー</Typography>} enableFooter={false} width={1262}>
      <Box p={3} bgcolor={background.disable} mt={3}>
        {value.type === INFORMATION_TYPE.IMPORTANT && <QuoteScope variant="danger">{renderContent}</QuoteScope>}
        {value.type !== INFORMATION_TYPE.IMPORTANT && (
          <Notification
            tag={findLabelByValue(informationTypes, value.type) || EMPTY_STR.TEXT}
            title={(value.startDate ? value.startDate : dayjs()).format(DATE_FORMAT)}
          >
            {renderContent}
          </Notification>
        )}
      </Box>
    </Modal>
  );
};
