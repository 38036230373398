import { DOCUMENT_KEYS } from "@/constants/enum";
import { EAnyTimeDocumentType } from "@/features/convert/enums/anytime";
import { ERetirementDocumentName } from "@/features/convert/enums/retirement";
import { TAnytimeEmploymentContractData } from "@/features/convert/types/documents/anytime/employmentContract";
import { TAnytimeSupportContractData } from "@/features/convert/types/documents/anytime/supportContract";
import { TAnytimeSupportPlanData } from "@/features/convert/types/documents/anytime/supportPlan";
import { TRegularAcceptanceActivityData } from "@/features/convert/types/documents/regular-document/acceptanceActivity";
import { TRegularLifeSupportData } from "@/features/convert/types/documents/regular-document/lifeSupport";
import { TRetirementDocumentData } from "@/features/convert/types/documents/retirement-document/retirementDocument";

type TData =
  | TRegularLifeSupportData
  | TRegularAcceptanceActivityData
  | TAnytimeSupportPlanData
  | TAnytimeSupportContractData
  | TAnytimeEmploymentContractData
  | TRetirementDocumentData;

export const isRegularAcceptanceActivityData = (documentName: string, data: TData): data is TRegularAcceptanceActivityData => {
  if (documentName === DOCUMENT_KEYS.ACCEPTANCE_ACTIVITY) {
    return true;
  }
  return false;
};

export const isRegularLifeSupportData = (documentName: string, data: TData): data is TRegularLifeSupportData => {
  if (documentName === DOCUMENT_KEYS.LIFE_SUPPORT) {
    return true;
  }
  return false;
};

export const isAnytimeSupportPlanData = (data: TData): data is TAnytimeSupportPlanData => {
  if (data?.documentInfo && "documentType" in data.documentInfo) {
    if (data.documentInfo.documentType === EAnyTimeDocumentType.SUPPORT_PLAN_MODIFICATION) return true;
  }
  return false;
};

export const isAnytimeSupportContractData = (data: TData): data is TAnytimeSupportContractData => {
  if (data?.documentInfo && "documentType" in data?.documentInfo) {
    if (data.documentInfo.documentType === EAnyTimeDocumentType.SUPPORT_OUTSOURCING_CONTRACT_MODIFICATION) return true;
  }
  return false;
};

export const isAnytimeEmploymentContractData = (data: TData): data is TAnytimeEmploymentContractData => {
  if (data?.documentInfo && "documentType" in data?.documentInfo) {
    if (data.documentInfo.documentType === EAnyTimeDocumentType.EMPLOYMENT_CONTRACT_MODIFICATION) return true;
  }
  return false;
};

export const isRetirementAcceptingWorkerData = (documentName: string, documentType: string, data: TData): data is TRetirementDocumentData => {
  if (documentType === DOCUMENT_KEYS.RETIREMENT && documentName === ERetirementDocumentName.RETIREMENT_ACCEPTING_WORKERS) {
    return true;
  }
  return false;
};

export const isRetirementContractData = (documentName: string, documentType: string, data: TData): data is TRetirementDocumentData => {
  if (documentType === DOCUMENT_KEYS.RETIREMENT && documentName === ERetirementDocumentName.RETIREMENT_EMPLOYMENT_CONTRACT) {
    return true;
  }
  return false;
};
