import { valueOf } from "@/types/common";

export const SORTING = {
  ASC: "ASC",
  DESC: "DESC",
};

export type TSorting = valueOf<typeof SORTING>;

export const SORTING_LABEL = {
  [SORTING.ASC]: "昇順で並び替え",
  [SORTING.DESC]: "降順の並べ替え",
};
