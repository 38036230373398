import { InnerBox } from "@/components/elements/InnerBox";
import { useCompany } from "@/api/services/admin/company";
import { Grid, Stack, Typography } from "@mui/material";
import { EMPTY_STR } from "@/constants/string";
import { formatNumber } from "@/utils/number";
import { useCompanyEmployeeBillingList } from "@/api/services/admin/company/company-employee-billings";
import { RETIREMENT_STATUS } from "@/types/enum";

export const CompanySummary = () => {
  const { data: companyResult, isLoading: isLoadingCompany } = useCompany();
  const { dataFormatter: billings, isLoading: isLoadingBilling } = useCompanyEmployeeBillingList({ fetchAll: true });

  const companyData = companyResult?.data;
  const totalFirstDayEnabledBillings = billings.filter((item) => item.isFirstDayPayment)?.length || 0;
  const totalCurrentEnabledBillings =
    billings.filter(
      (item) =>
        item.isCurrentPayment === true &&
        item.employee?.retirementStatus !== RETIREMENT_STATUS.RETIRED &&
        [1, 2, 3].includes(Number(item.employee.residenceStatusId)),
    )?.length || 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack gap={2}>
          <InnerBox title={<Typography variant="sub16Bold">企業情報</Typography>} loading={isLoadingCompany || isLoadingBilling}>
            <Typography whiteSpace="pre-line">{companyData?.companyDescription || "----"}</Typography>
          </InnerBox>
          <InnerBox title={<Typography variant="sub16Bold">備考欄（フリーテキスト）</Typography>} loading={isLoadingCompany || isLoadingBilling}>
            <Typography whiteSpace="pre-line">{companyData?.note || "----"}</Typography>
          </InnerBox>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <InnerBox loading={isLoadingCompany || isLoadingBilling} height="100%" justifyContent="center">
          <Stack gap={3}>
            <Stack direction="row" height="100%" gap={4} justifyContent="center" alignItems="center">
              <Stack gap={1}>
                <Typography variant="sub16Semi">当月1日時点での課金人数</Typography>
                <Typography variant="head32Bold" textAlign="center">
                  {totalFirstDayEnabledBillings || 0}人
                </Typography>
              </Stack>
              <Stack gap={1}>
                <Typography variant="sub16Semi">現時点の課金人数</Typography>
                <Typography variant="head32Bold" textAlign="center">
                  {totalCurrentEnabledBillings || 0}人
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={1} alignItems="center">
              <Typography variant="sub16Semi">単価設定</Typography>
              <Typography variant="sub16Semi">
                {companyData?.pricePerEmployee ? `${formatNumber(companyData.pricePerEmployee)}円/人` : EMPTY_STR.TEXT}
              </Typography>
            </Stack>
          </Stack>
        </InnerBox>
      </Grid>
    </Grid>
  );
};
