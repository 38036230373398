import { ReactNode } from "react";
import { Bounce, Id, toast as toastify, ToastOptions } from "react-toastify";

export const toastConfig: TToastOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  rtl: false,
  draggable: false,
  theme: "colored",
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  transition: Bounce,
};

export type TToastType = "default" | "success" | "error";
export type TToastOptions = Omit<ToastOptions, "type">;

export const toast = {
  show: (type: TToastType, content: ReactNode, options?: TToastOptions) => {
    const id = toastify(content, { type, ...toastConfig, ...options });
    return id;
  },

  success(message: string, options?: TToastOptions) {
    return this.show("success", message, options);
  },

  error(message: string, options?: TToastOptions) {
    return this.show("error", message, options);
  },

  info(message: string, options?: TToastOptions) {
    return this.show("default", message, options);
  },

  close(toastId: Id) {
    toastify.dismiss(toastId);
  },

  update(toastId: Id, content: ReactNode, options?: TToastOptions) {
    toastify.update(toastId, { ...options, render: content });
    return toastId;
  },
};
