import { useMe } from "@/api/services/admin/auth";
import { deleteAdminUserApi, useAdminUser } from "@/api/services/admin/user";
import { ConfirmDeleteModal } from "@/components/commons/ConfirmDeleteModal";
import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { AdminUserForm } from "@/features/admin-user/components/AdminUserForm";
import { convertUserDetailToForm } from "@/features/admin-user/utils/converts";
import { useDialog } from "@/hooks/useDialog";
import { useToast } from "@/hooks/useToast";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { getDynamicRoute } from "@/utils/url";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

export const AdminUserDetailPage: FC = () => {
  const { id } = useParams();
  const { data: adminUserResult, isLoading } = useAdminUser();
  const confirmDelete = useDialog();
  const adminUserData = adminUserResult?.data;
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const { data: meResult } = useMe();
  const meData = meResult?.data;

  const handleDelete = async () => {
    try {
      if (!adminUserData?.id) throw new Error();
      const agreeToDelete = await confirmDelete.show();
      if (!agreeToDelete) return;
      const rs = await deleteAdminUserApi(adminUserData.id);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      navigate(AppRoutes.adminUser);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_LIST] });
    } catch (error) {
      showError(error);
    }
  };

  if (isLoading || !adminUserData) return <LoadingPage />;

  return (
    <Stack gap={4}>
      <TopPage
        title="irohana担当者詳細"
        rightContent={
          <Stack direction="row" alignItems="center" gap={3}>
            <Button component={Link} variant="primary" size="lg" to={getDynamicRoute(AppRoutes.adminUserEdit, { id })}>
              編集
            </Button>
            <Button variant="primary" size="lg" disabled={adminUserData?.id === meData?.id} onClick={handleDelete}>
              削除
            </Button>
          </Stack>
        }
        backUrl={AppRoutes.adminUser}
      />
      <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
        <AdminUserForm previousData={convertUserDetailToForm(adminUserData)} readonly />
      </InnerBox>
      <ConfirmDeleteModal
        open={confirmDelete.open}
        onClose={confirmDelete.cancel}
        onOk={confirmDelete.confirm}
        message="irohana担当者を削除してもよろしいですか？"
      />
    </Stack>
  );
};
