import { TGetSystemSettingDto } from "@/api/services/admin/system-setting/dtos/get-system-setting.dto";
import { TUpdateEmailSettingReqDto } from "@/api/services/admin/system-setting/dtos/update-email-setting.dto";
import { TUpdateMaintenanceSettingReqDto } from "@/api/services/admin/system-setting/dtos/update-maintenance-setting.dto";
import { ISO_FORMAT } from "@/constants/datetime";
import { TMaintenanceForm } from "@/features/system-setting/types/maintenanceForm";
import { TSettingForm } from "@/features/system-setting/types/settingForm";
import { dayjs } from "@/utils/dayjs";

export const convertEmailSettingFormToUpdateReq = (data: TSettingForm): TUpdateEmailSettingReqDto => {
  return {
    ...data,
    irohanaSystemNotificationEmail: data?.irohanaSystemNotificationEmail ?? "",
    clientSystemNotificationEmail: data?.clientSystemNotificationEmail ?? "",
    otherNotificationEmail: data?.otherNotificationEmail ?? "",
    conversionNotificationEmail: data?.conversionNotificationEmail ?? "",
    chatNotificationEmail: data?.chatNotificationEmail ?? "",
  };
};

export const convertEmailSettingResToForm = (data: TGetSystemSettingDto): Partial<TSettingForm> => {
  return {
    irohanaSystemNotificationEmail: data?.irohanaSystemNotificationEmail ?? undefined,
    clientSystemNotificationEmail: data?.clientSystemNotificationEmail ?? undefined,
    otherNotificationEmail: data?.otherNotificationEmail ?? undefined,
    conversionNotificationEmail: data?.conversionNotificationEmail ?? undefined,
    chatNotificationEmail: data?.chatNotificationEmail ?? undefined,
  };
};

export const convertMaintenanceSettingFormToUpdateReq = (data: TMaintenanceForm): TUpdateMaintenanceSettingReqDto => {
  const payload = {
    ...data,
    maintenanceMode: data?.maintenanceMode ?? "",
    modeStartDateTime: data?.modeStartDateTime ? dayjs(data.modeStartDateTime?.toDate()).tz("Asia/Tokyo").toISOString() : undefined,
    modeEndDateTime: data?.modeEndDateTime ? dayjs(data.modeEndDateTime?.toDate()).tz("Asia/Tokyo").toISOString() : undefined,
  };
  return payload;
};

export const convertMaintenanceSettingResToForm = (data: TGetSystemSettingDto): Partial<TMaintenanceForm> => {
  return {
    maintenanceMode: data?.maintenanceMode ?? undefined,
    modeStartDateTime: data?.modeStartDateTime ? dayjs(data.modeStartDateTime) : undefined,
    modeEndDateTime: data?.modeEndDateTime ? dayjs(data.modeEndDateTime) : undefined,
  };
};
