import { TApiPagRes } from "@/types/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const useInfiniteScroll = <T extends object>(queryKey: unknown[], fetchFn: (page: number) => Promise<TApiPagRes<T>>, options?: object) => {
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => fetchFn(pageParam as number),
    initialPageParam: 1,
    getNextPageParam: ({ pagination: { page, totalPage } }) => {
      if (page < totalPage) return page + 1;
      return undefined;
    },
    ...options,
  });

  const dataFormatter = useMemo(() => query.data?.pages.flatMap(({ data }) => data) ?? [], [query.data]);

  return {
    ...query,
    dataFormatter,
    fetchNextPage: async () => {
      await query.fetchNextPage();
    },
  };
};
