import { useConvertList } from "@/api/services/admin/convert-request";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { Pagination } from "@/components/elements/Pagination";
import { TopPage } from "@/components/elements/TopPage";
import { TypoLink } from "@/components/elements/TypoLink";
import { CONVERT_STATUS } from "@/constants/enum";
import { ConvertTable } from "@/features/convert/component/ConvertTable";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";

export const ConvertListPage = () => {
  const { data: convertListResult, isLoading } = useConvertList([CONVERT_STATUS.IN_PROGRESS], ["cr.createdAt|ASC"]);

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <TopPage title="提出形式の変換依頼一覧" enablePageBack={false} />
      <Stack gap={2}>
        <Pagination totalPage={convertListResult?.pagination.totalPage} />
        {convertListResult && <ConvertTable isLoading={isLoading} data={convertListResult ?? []} />}
        <TypoLink to={AppRoutes.convertHistory}>過去処理分の一覧へ</TypoLink>
      </Stack>
    </>
  );
};
