import {
  updateConvertRequestComplete,
  updateConvertRequestError,
  updateConvertRequestFile,
  useConvertDetail,
} from "@/api/services/admin/convert-request";
import { fetchEncryptSecretKeyApi } from "@/api/services/global/constant";
import { useEnum } from "@/api/services/global/enum";
import { IconVisibility, IconVisibilityOff } from "@/assets/icons";
import { Button } from "@/components/elements/Button";
import { FileItem } from "@/components/elements/FileItem";
import { IconBox } from "@/components/elements/IconBox";
import { InnerBox } from "@/components/elements/InnerBox";
import { Popover } from "@/components/elements/Popover";
import { TypoLink } from "@/components/elements/TypoLink";
import { UploadPopup } from "@/components/elements/UploadPopup";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { CONVERT_STATUS } from "@/constants/enum";
import { FILE_UPLOAD_FOR, MIME_TYPES } from "@/constants/file";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { EMPTY_STR } from "@/constants/string";
import { ACCORD_IMMI_URL } from "@/constants/url";
import { divider, text } from "@/theme/colors";
import { TExtendFile } from "@/types/file";
import { showError } from "@/utils/error";
import { findLabelByValue } from "@/utils/object";
import { decrypt } from "@/utils/pieces";
import { toast } from "@/utils/toast";
import { Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ConvertAction = () => {
  const { data: convertStatusOptions } = useEnum("EConvertStatus");
  const { data: convertErrorOptions } = useEnum("EConvertError");
  const { data: convertResult } = useConvertDetail();
  const convertData = convertResult?.data;
  const { id } = useParams();
  const [isErrorSettingLoading, setIsErrorSettingLoading] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPath, setOldPath] = useState<Array<string>>([]);

  const updateFileConvert = async (filePath: Array<string>) => {
    try {
      await updateConvertRequestFile(Number(id), { filePath: filePath });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONVERT.FETCH_DETAIL] });
      toast.success(API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const onFinishUpload = async (value: TExtendFile[]) => {
    const dropFile = [value].flat().flatMap((file) => file.filePath ?? []);
    if (!dropFile?.length) return;

    const newFilePath = dropFile.filter((item) => !oldPath.includes(item));
    setOldPath(dropFile);

    updateFileConvert([...filePaths, ...newFilePath]);
  };

  const removeFile = async (filePathRemove: string) => {
    const filePathCurrents = filePaths.filter(function (filePath) {
      return filePath !== filePathRemove;
    });
    updateFileConvert(filePathCurrents);
  };

  const handleUpdateConvertComplete = async () => {
    try {
      await updateConvertRequestComplete(Number(id));
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONVERT.FETCH_DETAIL] });
      toast.success(API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpdateConvertError = async (value: string) => {
    setIsErrorSettingLoading(true);
    try {
      await updateConvertRequestError(Number(id), { error: value });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONVERT.FETCH_DETAIL] });
      toast.success(API_SUCCESS_MESSAGE);
    } catch (error) {
      showError(error);
    } finally {
      setIsErrorSettingLoading(false);
      // navigate(AppRoutes.convert);
    }
  };

  const handleToggleShowPassword = async () => {
    if (!isShowPassword) {
      setIsLoadingPassword(true);
      try {
        const { data: secretKey } = await fetchEncryptSecretKeyApi();
        if (convertData?.accountPassword) {
          const password = decrypt(convertData.accountPassword, secretKey);
          setPassword(password);
        }
        setIsLoadingPassword(false);
      } catch (error) {
        setIsLoadingPassword(false);
      }
    }
    setIsShowPassword(!isShowPassword);
  };

  const filePaths = useMemo(() => {
    return [convertData?.filePath ?? []].flat();
  }, [convertData]);

  return (
    <InnerBox height="100%">
      <Stack gap={1.5}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant="sub16Bold">電子届出システムのアカウント情報</Typography>
          <Button
            onClick={handleUpdateConvertComplete}
            variant="warning"
            disabled={!filePaths.length || convertData?.status === CONVERT_STATUS.COMPLETE}
          >
            変換完了
          </Button>
        </Stack>
        <Box>
          <Typography>認証ID：{convertData?.accountId || EMPTY_STR.TEXT}</Typography>
          <Stack flexDirection="row" alignItems="center" gap={5}>
            <Typography>
              パスワード：
              {isShowPassword ? password || EMPTY_STR.TEXT : "●●●●●●"}
            </Typography>
            {isLoadingPassword ? (
              <CircularProgress style={{ marginLeft: "10px" }} size={16} />
            ) : (
              <IconBox size={32} onClick={handleToggleShowPassword}>
                {isShowPassword ? <IconVisibilityOff /> : <IconVisibility />}
              </IconBox>
            )}
          </Stack>
        </Box>
        <TypoLink to={ACCORD_IMMI_URL} target="_blank">
          出入国在留管理庁電子届出システムを開く (外部リンク)
        </TypoLink>
        <Typography variant="body14" color={text.accent}>
          一時保存用のXMLを作成後、以下からアップロードして完了してください。
          <br />
          完了後は再アップロードやステータスを変更できないため、ご注意ください。
        </Typography>
        <Stack gap={1.5} direction="row">
          <Stack gap={1}>
            <Button onClick={() => setOpenFile(true)} isLoading={isUploading} disabled={convertData?.status === CONVERT_STATUS.COMPLETE}>
              アップロードして完了
            </Button>
            <UploadPopup
              onDropFiles={() => setIsUploading(true)}
              onFinishUpload={onFinishUpload}
              isOpen={openFile}
              uploadFor={FILE_UPLOAD_FOR.CONVERT_REQUEST_FILE}
              onClose={() => setOpenFile(false)}
              options={{ multiple: true }}
              rules={{ acceptMimeTypes: [...MIME_TYPES.XML, ...MIME_TYPES.CSV] }}
              title={<Typography variant="body14Bold">ファイルをアップロードしてください。</Typography>}
            />
          </Stack>
          <Stack height="fit-content">
            <Popover
              closeClickInside={true}
              disabled={convertData?.status === CONVERT_STATUS.COMPLETE}
              sx={{
                marginTop: 0,
                borderRadius: 0,
                ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation.MuiPopover-paper": {
                  marginTop: "1px",
                },
                ".MuiPopover-paper": {
                  borderRadius: "0 0 8px 8px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                },
              }}
              anchor={
                <>
                  <Stack height="auto" direction="column" gap={1} alignItems="start">
                    <Button
                      sx={{ minWidth: "150px" }}
                      variant="outline"
                      disabled={isErrorSettingLoading || convertData?.status === CONVERT_STATUS.COMPLETE}
                    >
                      {isErrorSettingLoading ? "作成中…" : "変換失敗として完了"}
                    </Button>
                  </Stack>
                </>
              }
            >
              <Stack>
                {convertErrorOptions.map((error, index) => (
                  <ErrorOption
                    key={index}
                    onClick={() => {
                      handleUpdateConvertError(error.value);
                    }}
                  >
                    {error.label}
                  </ErrorOption>
                ))}
              </Stack>
            </Popover>
          </Stack>
        </Stack>
        {!!filePaths.length && (
          <Stack gap={0.5}>
            {filePaths.map((filePath) => (
              <FileItem
                filePath={filePath}
                onRemove={convertData?.status === CONVERT_STATUS.COMPLETE ? undefined : () => removeFile(filePath)}
                downloadable
              />
            ))}
          </Stack>
        )}
        <Typography>
          現在のステータス：
          {convertData?.status != CONVERT_STATUS.ERROR
            ? findLabelByValue(convertStatusOptions, convertData?.status)
            : `${findLabelByValue(convertStatusOptions, convertData?.status) || EMPTY_STR.TEXT} (${findLabelByValue(convertErrorOptions, convertData?.error) || EMPTY_STR.TEXT})`}
        </Typography>
      </Stack>
    </InnerBox>
  );
};

const ErrorOption = styled(Box)`
  font-size: 14px;
  padding: 4px 28px;
  cursor: pointer;
  :hover {
    background: ${divider.low};
    color: ${text.primary};
  }
  :focus {
    background: ${divider.middle};
    color: ${text.primary};
  }
`;
