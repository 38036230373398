import { createSpecificIndustryApi, useGetCompanySpecificIndustryList, useGetResidenceStatusList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CreateSpecificIndustryFormTable } from "@/features/master-data/components/SpecificIndustry/CreateSpecificIndustryFormTable";
import { masterDataCreateSpecificIndustrySchema } from "@/features/master-data/schema/masterDataSpecificIndustrySchema";
import { TMasterSpecificIndustryCreateForm } from "@/features/master-data/types/masterDataSpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";

type TCreateSpecificIndustryFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateSpecificIndustryFormModal: FC<TCreateSpecificIndustryFormModalProps> = ({ onClose, isOpen }) => {
  const queryClient = useQueryClient();

  const methods = useForm<TMasterSpecificIndustryCreateForm>({
    resolver: yupResolver(masterDataCreateSpecificIndustrySchema),
    mode: "onBlur",
    defaultValues: {
      renewVisaSpecificIndustry: "",
      regularAnyTimeSpecificIndustry: "",
      fgSpecificIndustryRenewVisaCsvCode: "",
      specificBusinessClassifications: [
        {
          specificBusinessClassificationName: "",
          fhSpecificBusinessClassificationRenewVisaCsvCode: "",
          anyTimeSpecificBusinessClassificationName: "",
          specificBusinessClassificationAnyTimeCsvCode: "",
        },
      ],
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterSpecificIndustryCreateForm) => {
    try {
      const rs = await createSpecificIndustryApi({
        fgSpecificIndustryRenewVisaCsvCode: data.fgSpecificIndustryRenewVisaCsvCode,
        regularAnyTimeSpecificIndustry: data.regularAnyTimeSpecificIndustry,
        renewVisaSpecificIndustry: data.renewVisaSpecificIndustry,
        companySpecificIndustryId: toNumber(data.companySpecificIndustryId),
        residenceStatusId: toNumber(data.residenceStatusId),
        specificBusinessClassifications: data.specificBusinessClassifications.map((item) => ({
          specificBusinessClassificationName: item.specificBusinessClassificationName,
          anyTimeSpecificBusinessClassificationName: item.anyTimeSpecificBusinessClassificationName,
          companySpecificBusinessClassificationId: toNumber(item.companySpecificBusinessClassificationId),
          specificBusinessClassificationAnyTimeCsvCode: item.specificBusinessClassificationAnyTimeCsvCode,
          fhSpecificBusinessClassificationRenewVisaCsvCode: item.fhSpecificBusinessClassificationRenewVisaCsvCode,
        })),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  const { isLoading: isLoadingResidenceStatus, residenceStatusOptions } = useGetResidenceStatusList();

  const { isLoading: isLoadingCompanySpecificIndustry, companySpecificIndustryOptions } = useGetCompanySpecificIndustryList();

  return (
    <Modal width={1200} title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataCreateSpecificIndustrySchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustryId">
              <Select loading={isLoadingCompanySpecificIndustry} options={companySpecificIndustryOptions} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="residenceStatusId">
              <Select loading={isLoadingResidenceStatus} options={residenceStatusOptions} />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="regularAnyTimeSpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="renewVisaSpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="fgSpecificIndustryRenewVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">業務区分（外国人用、期間更新の表記）</Typography>
              <CreateSpecificIndustryFormTable />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit" disabled={methods.watch("specificBusinessClassifications").length === 0}>
            保存
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};
