export const CHANNELS = {
  CONVERSATION: {
    ROOM_LIST: "CONVERSATION__LIST_{{tenantId}}",
    ROOM: "CONVERSATION__{{tenantId}}_{{conversationId}}",
  },
};

export const EVENTS = {
  GLOBAL: {
    ERROR: "GLOBAL__ERROR",
    CONNECTED: "GLOBAL__CONNECTED",
  },
  CONVERSATION: {
    NEW_ROOM: "CONVERSATION__NEW_ROM",
    JOIN_ROOM: "CONVERSATION__JOIN_ROOM",
    JOIN_ROOM_LIST: "CONVERSATION__JOIN_ROOM_LIST",
    LEAVE: "CONVERSATION__LEAVE",
    NEW_MESSAGE: "CONVERSATION__NEW_MESSAGE",
    READ_MESSAGE: "CONVERSATION__READ_MESSAGE",
  },
};

// export const EVENTS = {
//   GLOBAL: {
//     ERROR: 'GLOBAL__ERROR',
//   },
//   CONVERSATION: {
//     JOIN_ROOM: 'CONVERSATION__JOIN_ROOM',
//     JOIN_LIST: 'CONVERSATION__JOIN_LIST',

//     LEAVE_ROOM: 'CONVERSATION__LEAVE_ROOM',
//     LEAVE_LIST: 'CONVERSATION__LEAVE_LIST',

//     NEW_MESSAGE_ROOM: 'CONVERSATION__NEW_MESSAGE_ROOM',
//     NEW_MESSAGE_LIST: 'CONVERSATION__NEW_MESSAGE_LIST',

//     READ_MESSAGE: 'CONVERSATION__READ_MESSAGE',
//   },
// };
