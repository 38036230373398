/* eslint-disable @typescript-eslint/no-explicit-any */
import { InnerBox } from "@/components/elements/InnerBox";
import { Table } from "@/components/elements/Table";
import { PAYROLL_STATUS } from "@/features/convert/constants/payrollStatus";
import { useMakeConvertColumn } from "@/features/convert/hooks/useMakeConvertColumn";
import { TRegularAcceptanceActivityData } from "@/features/convert/types/documents/regular-document/acceptanceActivity";
import { TPayrollRecord } from "@/features/convert/types/payrollRecord";
import { convertToDataTableRow } from "@/features/convert/utils/convert";
import { useTable } from "@/hooks/useTable";
import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";

type TConvertAcceptanceActivityTableProps = {
  data: TRegularAcceptanceActivityData;
};

export const ConvertAcceptanceActivityTable = ({ data }: TConvertAcceptanceActivityTableProps) => {
  const columns = useMakeConvertColumn();
  const paycheckData: TPayrollRecord[] = data.employeesInfo.map((employeePayroll, i) => ({
    ...employeePayroll,
    recordId: i.toString(),
    status: PAYROLL_STATUS.OK,
  }));

  const paycheckRows = useMemo(
    () =>
      convertToDataTableRow(paycheckData as any).sort((a, b) => {
        if (!a.no || !b.no) return 0;
        if (a.no > b?.no) return 1;
        if (a.no < b.no) return -1;
        return 0;
      }),
    [paycheckData],
  );

  const { table } = useTable({
    rows: paycheckRows ?? [],
    columns,
    getRowId: ({ recordId }) => recordId,
  });

  if (!paycheckData) return null;

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">報酬の支払状況</Typography>
        <Box maxHeight={575}>
          <Table table={table} />
        </Box>
      </Stack>
    </InnerBox>
  );
};
