export const ERROR_STATUS_CODES = {
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  ACCESS_TOKEN_HAS_EXPIRED: "ACCESS_TOKEN_HAS_EXPIRED",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  INCORRECT_ENUM_NAME: "INCORRECT_ENUM_NAME",
  FAILED_TO_EXECUTE_DATABASE_QUERY: "FAILED_TO_EXECUTE_DATABASE_QUERY",
  EMAIL_HAS_BEEN_ALREADY_EXISTS: "EMAIL_HAS_BEEN_ALREADY_EXISTS",
  EMAIL_NOT_EXISTS: "EMAIL_NOT_EXISTS",
  INCORRECT_EMAIL_PASSWORD_OR_COMPANY: "INCORRECT_EMAIL_PASSWORD_OR_COMPANY",
  INCORRECT_REFRESH_TOKEN: "INCORRECT_REFRESH_TOKEN",
  INCORRECT_RESET_TOKEN: "INCORRECT_RESET_TOKEN",
  USER_NOT_EXISTS: "USER_NOT_EXISTS",
  COMPANY_CONTRACT_EXPIRED: "COMPANY_CONTRACT_EXPIRED",
  FAILED_TO_SEND_EMAIL: "FAILED_TO_SEND_EMAIL",
  FAILED_TO_DELETE_COGNITO_USER: "FAILED_TO_DELETE_COGNITO_USER",
  INCORRECT_INPUT: "INCORRECT_INPUT",
  FAILED_TO_GET_COGNITO_USER: "FAILED_TO_GET_COGNITO_USER",
  INFORMATION_NOT_EXISTS: "INFORMATION_NOT_EXISTS",
  COMPANY_NOT_EXISTS: "COMPANY_NOT_EXISTS",
  COMPANY_BRANCH_NOT_EXISTS: "COMPANY_BRANCH_NOT_EXISTS",
  CAN_NOT_DELETE_COMPANY_HEADQUARTERS: "CAN_NOT_DELETE_COMPANY_HEADQUARTERS",
  FILE_NOT_EXISTS: "FILE_NOT_EXISTS",
  EMPLOYEE_NOT_EXISTS: "EMPLOYEE_NOT_EXISTS",
  DOCUMENT_NOT_EXISTS: "DOCUMENT_NOT_EXISTS",
  INCORRECT_DOCUMENT_STEP: "INCORRECT_DOCUMENT_STEP",
  DOCUMENT_STEP_NOT_COMPLETE: "DOCUMENT_STEP_NOT_COMPLETE",
  ACCEPTANCE_ACTIVITY_DOCUMENT_NOT_EXISTS: "ACCEPTANCE_ACTIVITY_DOCUMENT_NOT_EXISTS",
  ACCEPTANCE_ACTIVITY_FILE_NOT_EXISTS: "ACCEPTANCE_ACTIVITY_FILE_NOT_EXISTS",
  FILE_LIST_EMPTY: "FILE_LIST_EMPTY",
  LIFE_SUPPORT_DOCUMENT_NOT_EXISTS: "LIFE_SUPPORT_DOCUMENT_NOT_EXISTS",
  ADMIN_MEMBER_NOT_EXISTS: "ADMIN_MEMBER_NOT_EXISTS",
};
