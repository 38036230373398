import { Key } from "react";

/**
 * ex: /company/:id => /company/1
 * @param {string} pathname - pathname is define in AppRoutes
 * @param {number} params - Object to replace params in dynamic route
 * @returns {string} - DynamicRoute.
 *
 */
export const getDynamicRoute = (pathname: string, params: Record<string, Key | undefined>): string => {
  return Object.entries(params).reduce((cur, [key, value]) => (value ? cur.replace(`:${key}`, value.toString()) : cur), pathname);
};

/**
 * ex: /company/:id => /company/1
 * @param {string} url - origin url
 * @param {number} params - Object to create search param
 * @returns {string} - url with search param.
 *
 */
export const createUrlWithParams = <D = object>(url: string, data: D): string => {
  if (!data) {
    return url;
  }

  const dataAry = Object.entries<number | string | boolean | string[] | number[]>(data).filter(([_, value]) => {
    return !(value === null || value === undefined);
  });
  if (!dataAry.length) {
    return url;
  }

  const params = dataAry.map(([key, value]) => {
    // For arrays use the same key and create a key=value string for each value
    if (Array.isArray(value)) {
      return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join("&");
    }

    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  return [url, params.join("&")].join("?");
};

/**
 * @param {string} url - url to verify
 * @returns {boolean} - true isUrl
 */
export const isUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @returns {string} - return tenant domain
 */

export const getTenantDomain = (): string => {
  const url = window.location.hostname;
  return url.replace("admin.", "");
};

/**
 * @param {string} url - url to verify
 * @returns {boolean} - true isInternalTenant
 */
export const isInternalTenantLink = (url: string): boolean => {
  try {
    const linkUrl = new URL(url);
    return linkUrl.hostname.includes(getTenantDomain());
  } catch (error) {
    return false;
  }
};

/**
 * Extracts query parameters from a given URL and returns them as an object.
 *
 * @template T - Type of the query parameters. Should be a record where keys are strings and values are strings or arrays of strings or undefined.
 * @param {string} url - The URL from which to extract query parameters.
 * @returns {T | null} - An object containing the query parameters, where keys are parameter names and values are their corresponding values. Returns `null` if an error occurs.
 */
export const getQuery = <T extends Record<string, string | string[] | undefined>>(url: string): T | null => {
  try {
    const { searchParams } = new URL(url); // Create a URL object and extract searchParams
    const initData: Record<string, string | string[] | undefined> = {}; // Initialize an empty object to store query parameters

    return Array.from(searchParams).reduce((cur, [key, value]) => {
      const curValue = cur[key];
      if (curValue) {
        // If the key already exists, merge the new value with the existing value(s)
        cur[key] = [curValue, value].flat();
        return cur;
      }
      cur[key] = value; // Otherwise, set the key with the new value
      return cur;
    }, initData) as T; // Cast the result to the specified type T
  } catch {
    return null; // Return null if an error occurs during URL parsing or query extraction
  }
};

/**
 * Extracts query parameters from the current URL.
 *
 * @template T - Type of the query parameters. Should be a record where keys are strings and values are strings or arrays of strings or undefined.
 * @returns {T | null} - An object containing the query parameters, or `null` if no parameters are found.
 */
export const getQueryFromURL = <T extends Record<string, string | string[] | undefined>>(): T | null => {
  return getQuery(window.location.toString());
};

/**
 * Extracts dynamic route parameters from a given origin and pathname.
 *
 * @param {string} origin - The origin part of the URL, typically containing dynamic segments (e.g., "/users/:userId/posts/:postId").
 * @param {string} pathname - The actual path of the URL (e.g., "/users/123/posts/456").
 * @returns {Record<string, string>} - An object mapping dynamic segment names to their values (e.g., { userId: "123", postId: "456" }).
 */
export const getParams = (origin: string, pathname: string): Record<string, string> => {
  const originSplit = origin.split("/"); // Split the origin into segments based on "/"
  const pathnameSplit = pathname.split("/"); // Split the pathname into segments based on "/"
  const params: Record<string, string> = {}; // Object to store the extracted parameters

  originSplit.forEach((item, index) => {
    if (item.startsWith(":")) {
      const key = item.replace(":", ""); // Remove ":" to get the parameter key
      const value = pathnameSplit[index]; // Get the corresponding value from the pathname
      if (key && value) {
        params[key] = value; // Assign the value to the parameter key
      }
    }
  });

  return params; // Return the object containing the extracted parameters
};
