import { background, state } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { LinearProgress, LinearProgressProps } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

export const Progress: FC<LinearProgressProps> = (props) => {
  return <BorderLinearProgress variant="determinate" {...props} />;
};

const BorderLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 8px;
    border-radius: ${rounded.xs};
    background-color: ${background.disable};
    .MuiLinearProgress-bar {
      background-color: ${state.success_1};
    }
  }
`;
